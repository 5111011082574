import React, { useEffect, useState } from "react";
import { Button, Container, Row, Dropdown } from 'react-bootstrap';
import { Link, NavLink } from "react-router-dom";
import edafacelogo from "../Assets/edaface-logo1.png";
import edafacelogos from "../Assets/dex_logo.png";
import docss from "../Assets/navicon/docss.png";
import square from "../Assets/navicon/square.png";
import Innerheadermobile from "./innerheadermobile";
import Connectbutton from "./connectbutton";

export default function Innerheader() {

    const [innerheadermobile, setInnerheadermobile] = useState(false);

    return (

        <>
            {innerheadermobile && <Innerheadermobile onDismiss={() => setInnerheadermobile(false)} />}

            <div>

                <header className="header inner_header py-2 sticky-bar">
                    <div className="container">
                        <div className="main-header">
                            <div className="header-left">
                                <div className="header-logo">
                                    <Link className="d-flex" to="https://edaface.com/"><img src={edafacelogo} className="logos" alt="logos" /></Link>
                                </div>
                                <div className="header-logo ms-3">
                                    <Link className="d-flex" to="/"><img src={edafacelogos} className="logos me-1" alt="logos" /></Link>
                                </div>
                                <div className="header-nav">
                                    <nav className="nav-main-menu d-none d-xl-block">
                                        <div className="ruby-menu-demo-header innerdemo">
                                            <div className="ruby-wrapper innerwrapper">
                                                <ul className="ruby-menu innermenu">
                                                    <li>
                                                        <Link to="#">Trade</Link>
                                                        <ul className="">
                                                            {/* <li><Link to="/swap"><img src={docss} className="img-fluid navimg me-1" alt="images" /> Swap</Link></li> */}
                                                            <li><Link to="/spot"><img src={docss} className="img-fluid navimg me-1" alt="images" /> Spot</Link></li>
                                                            <li><Link to="/option"><img src={square} className="img-fluid navimg me-1" alt="images" /> Options</Link></li>
                                                            <li><Link to="/future"><img src={square} className="img-fluid navimg me-1" alt="images" /> Futures</Link></li>
                                                            {/* s<li><Link to="/"><img src={square} className="img-fluid navimg me-1" alt="images" /> Synthetics</Link></li> */}
                                                            <li><Link to="https://p2pmarket.edaface.com/" target="_blank"><img src={square} className="img-fluid navimg me-1" alt="images" /> P2P Trading</Link></li>
                                                        </ul>
                                                        <span className="ruby-dropdown-toggle"></span>
                                                    </li>
                                                    <li>
                                                        <Link to="#">NFTs</Link>
                                                        <ul className="">
                                                            <li><Link to="/"  target='_blank'><img src={docss} className="img-fluid navimg me-1" alt="images" /> NFT Marketplace</Link></li>
                                                            <li><Link to="/"  target='_blank'><img src={square} className="img-fluid navimg me-1" alt="images" /> NFT Staking</Link></li>
                                                        </ul>
                                                        <span className="ruby-dropdown-toggle"></span>
                                                    </li>
                                                    <li className="lastarrow">
                                                        <Link to="#">Earn</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="#">Ecosystem</Link>
                                                        <ul className="">
                                                            <li><Link to="/"  target='_blank'><img src={docss} className="img-fluid navimg me-1" alt="images" /> Bridges</Link></li>
                                                            <li><Link to="/"  target='_blank'><img src={square} className="img-fluid navimg me-1" alt="images" /> Chat Forum</Link></li>
                                                            <li><Link to="/"  target='_blank'><img src={square} className="img-fluid navimg me-1" alt="images" /> Developers</Link></li>
                                                            <li><Link to="/"  target='_blank'><img src={square} className="img-fluid navimg me-1" alt="images" /> Launchpad</Link></li>
                                                        </ul>
                                                    </li>
                                                    <li>
                                                        <Link to="#">Staking</Link>
                                                        <ul className="">
                                                            <li><Link to="/pools"><img src={docss} className="img-fluid navimg me-1" alt="images" /> MIM Pools</Link></li>
                                                            <li><Link to="/farms"><img src={square} className="img-fluid navimg me-1" alt="images" /> Farms</Link></li>
                                                            <li><Link to="/pond"><img src={square} className="img-fluid navimg me-1" alt="images" /> Ponds</Link></li>
                                                        </ul>
                                                        <span className="ruby-dropdown-toggle"></span>
                                                    </li>
                                                    <li className="maxcont">
                                                        <Link to="#">More</Link>
                                                        <ul className="">
                                                            <li><Link to="/"  target='_blank'><img src={docss} className="img-fluid navimg me-1" alt="images" /> Docs</Link></li>
                                                            <li><Link to="/"  target='_blank'><img src={square} className="img-fluid navimg me-1" alt="images" /> Resources</Link></li>
                                                            {/* <li><Link to="/cointoken"><img src={square} className="img-fluid navimg me-1" alt="images" /> Token Request</Link></li> */}
                                                        </ul>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </nav>
                                </div>
                                <div className="header-right">
                                    <div className="d-none d-md-block"><Connectbutton /></div>
                                    <div className="burger-icon burger-icon-white" onClick={() => { setInnerheadermobile(true) }}><span className="burger-icon-top"></span><span className="burger-icon-mid"></span><span className="burger-icon-bottom"></span></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>

            </div>

        </>

    );
}