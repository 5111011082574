import React, { useState, useEffect } from 'react';
import { Modal, Col, Container, Dropdown, Row, DropdownToggle, DropdownMenu, DropdownItem } from "react-bootstrap";
import Images from "../Components/Images";
import { IoClose } from "react-icons/io5";


function Banklist(props) {

    const[bankmodal,setBankmodal] = useState(true);
    const[banklist,setBanklist] = useState([
        {name:"Bank 1"},{name:"Bank 2"},{name:"Bank 3"},{name:"Bank 4"},
    ])

    return (
        <>
            <div className='walletmodal'>
                <Modal show={bankmodal} size="md" aria-labelledby="contained-modal-title-vcenter" centered >
                    <Modal.Body className="py-4 px-3">
                        <div className="text-end mb-2"><button className='btn close me-0' onClick={() => props.onDismiss()}><IoClose /></button></div>
                        <h5 className="themeclr fw-600 mb-4">Select Payment Method</h5>

                        <ul className='bank_list'>
                            {banklist.map((e,i)=>
                            <li onClick={() => {props.setBankshow(e);props.setInitialbank(e); props.onDismiss() }}>{e.name}</li>)}
                        </ul>

                    </Modal.Body>
                </Modal>
            </div>
        </>
    )
}

export default Banklist;