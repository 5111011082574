import React, { useState, useEffect } from "react";
import Innerheader from "../Layouts/innerheader";
import Footer from "../Layouts/footer";
import { Col, Table, Container, Dropdown, DropdownMenu, DropdownToggle, Row, DropdownItem } from "react-bootstrap";
import "../Karthick.css";
import "../inner.css";
import { Link } from "react-router-dom";
import Images from "../Components/Images";
import { FaSort } from "react-icons/fa";
import { BsThreeDots } from "react-icons/bs";
import Switch from "react-switch";
import Withdrawmodals from "../Components/Modals/withdrawmodal";

function Supplytable() {

    const [withdrawmodal, setWithdrawmodal] = useState(false);
    const [checked, setChecked] = useState(true);

    const handleChange = nextChecked => {
        setChecked(nextChecked);
    };
    const [coinlists, setCoinlists] = useState([
        {
            id: 1, image: Images.eth, names: "ETH", balance: "1.0", apy: "0.01%", tick: Images.tick,
        },
        {
            id: 2, image: Images.dai, names: "DAI", balance: "1.3", apy: "0.01%", tick: Images.tick,
        },
    ]);
    return (
        <>
 {withdrawmodal && <Withdrawmodals onDismiss={()=>setWithdrawmodal(false)}/>}
        <div className="supplytable mt-3">
            <div className="d-flex gap-3 pt-2">
                <p className="themeclr stab">Balance <span className="fw-600">$1.03</span></p>
                <p className="themeclr stab">APY <span className="fw-600">$1.03</span></p>
                <p className="themeclr stab">Collateral <span className="fw-600">$1.03</span></p>
            </div>
            <Table responsive>
                <thead>
                    <tr>
                        <th>Assets <FaSort /></th>
                        <th>Balance <FaSort /></th>
                        <th>APY <FaSort /></th>
                        <th>Collateral <FaSort /></th>
                        <th></th>
                    </tr>
                </thead>
                <tbody className="t_body">
                    {coinlists.map((e, i) =>
                        <tr>
                            <td className="pos"><img src={e.image} className="img-fluid wbox" /><span className="themeclr ms-2">{e.names}</span></td>
                            <td><p className="themeclr mb-0">{e.balance}</p></td>
                            <td><p className="themeclr mb-0">{e.apy}</p></td>
                            <td>
                            <Switch
                                    onChange={handleChange}
                                    checked={checked}
                                    offColor="#162019"
                                    onColor="#1888b7"
                                    offHandleColor="#ffff"
                                    onHandleColor="#00e7fa"
                                    height={20}
                                    width={36}
                                    handleDiameter={18}
                                    uncheckedIcon={false}
                                    checkedIcon={false}
                                    className="react-switch"
                                    id="material-switch"
                                />
                                
                            </td>
                            <td>
                                <div className="d-flex align-items-center">
                                    <button className="btn btn-brand-1 sitebtn hover-up me-2" onClick={()=>setWithdrawmodal(true)}>Withdraw</button>
                                    <button className="supplybtn">Swap</button>
                                </div>
                            </td>
                        </tr>
                    )}
                </tbody>
            </Table>
        </div>
        </>
    )
}

export default Supplytable