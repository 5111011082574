import React, { useEffect, useState } from 'react';
import { Button, Container, Modal, Dropdown, DropdownToggle } from 'react-bootstrap';
import { IoClose } from "react-icons/io5";
import { Link } from "react-router-dom";
import Images from '../Images';
import { AiOutlinePlus } from "react-icons/ai";
import { MdOutlineKeyboardArrowDown, MdSwapHoriz } from "react-icons/md";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FaRegCopy } from "react-icons/fa";
import { SlCalculator } from "react-icons/sl";
import { IoMdSettings } from "react-icons/io";
import { LuMinusCircle } from "react-icons/lu";
import { GoPlusCircle } from "react-icons/go";
import Tokenmodallists from './tokenmodallist';
import Roicalculator from './roicalculator';
import Settingsmodal from './settingsmodal';

function Liquidtytable(props) {
    const [liquimodal, setLiquimodal] = useState(true);
    const [morelist, setMorelist] = useState(false);
    const [tokenmodallist, setTokenmodallist] = useState(false);
    const [count, setCount] = useState(0); // useState returns a pair. 'count' is the current state. 'setCount' is a function we can use to update the state.
    const [count1, setCount1] = useState(0);
    function increment() {
        //setCount(prevCount => prevCount+=1);
        setCount(function (prevCount) {
            return (prevCount += 1);
        });
    }
    function increment1() {
        //setCount(prevCount => prevCount+=1);
        setCount1(function (prevCount) {
            return (prevCount += 1);
        });
    }
    function decrement() {
        setCount(function (prevCount) {
            if (prevCount > 0) {
                return (prevCount -= 1);
            } else {
                return (prevCount = 0);
            }
        });
    }
    function decrement1() {
        setCount1(function (prevCount) {
            if (prevCount > 0) {
                return (prevCount -= 1);
            } else {
                return (prevCount = 0);
            }
        });
    };
    const [settingmodals, setSettingmodals] = useState(false);
    const[roical,setRoical] = useState(false);
    const[types, setTypes] = useState(1)
    const [changefirst, setChangefirst] = useState({
        id: 1, image: Images.eth, coinname: "ETH"
    });
    const [changesecond, setChangesecond] = useState({
        id: 1, image: Images.usdc, coinname: "USDC"
    })
    const onswap = () => {
        var changea = changefirst
        var changeb = changesecond
        setChangefirst(changeb)
        setChangesecond(changea)
    };

    return (
        <>
        {settingmodals && <Settingsmodal onDismiss={()=>setSettingmodals(false)}/> }
        {roical && <Roicalculator onDismiss={()=>setRoical(false)} changefirst={changefirst}  changesecond={changesecond} onswap={onswap}/> }
        {tokenmodallist && <Tokenmodallists onDismiss={()=>setTokenmodallist(false)} types={types} setChangefirst={(e)=>setChangefirst(e)} setChangesecond={(e)=>setChangesecond(e)} changefirst={changefirst}/>}
            <div className='walletmodal'>
                <Modal show={liquimodal} size="lg" aria-labelledby="contained-modal-title-vcenter walletlist" centered >
                    <Modal.Body className="py-4 px-3">
                        <div className="text-end mb-2"><button className='btn close me-0' onClick={() => props.onDismiss()}><IoClose /></button></div>

                        <div className='d-flex justify-content-between mt-3 mb-4'>
                            <h5 className="themeclr fw-600">Add Liquidity</h5>
                            <div className='d-flex align-items-center'>
                                <p className='mb-0 themeclr fw-500 cursor me-3' onClick={()=>setRoical(true)}>2,800.91 <SlCalculator /></p>
                                <IoMdSettings className='themeclr set_icon cursor' onClick={()=>setSettingmodals(true)}/>
                            </div>
                        </div>


                        <div className='row'>
                            <div className='col-lg-6'>
                                <p className='themeclr  fw-500'>CHOOSE TOKEN PAIR</p>
                                <div className='d-flex align-items-center gap-2'>
                                    <Dropdown className='no_drop'>
                                        <DropdownToggle className='rounded-3' onClick={()=>{setTypes(1);setTokenmodallist(true)}}><img src={changefirst.image} className='img-fluid ethimg' /> {changefirst.coinname}</DropdownToggle>
                                    </Dropdown>
                                    <AiOutlinePlus />
                                    <Dropdown className='no_drop'>
                                        <DropdownToggle className='rounded-3' onClick={()=>{setTypes(2);setTokenmodallist(true)}}><img src={changesecond.image} className='img-fluid ethimg' /> {changesecond.coinname}</DropdownToggle>
                                    </Dropdown>
                                </div>
                                <div className='swap__blueBox rounded-3 py-3 px-2 mt-3'>
                                    <div className='d-flex align-items-center justify-content-between'>
                                        <p className='mb-0'>V3 LP - 0.01% fee tier</p>
                                        {morelist == false ?
                                            <p className='themeclr mb-0 fw-500' style={{ cursor: "pointer" }} onClick={() => setMorelist(true)}>More <MdOutlineKeyboardArrowDown /></p> :
                                            <p className='themeclr mb-0 fw-500' style={{ cursor: "pointer" }} onClick={() => setMorelist(false)}>Hide</p>}
                                    </div>
                                    {morelist ?
                                        <div className='d-flex defaultall gap-2 mt-3'>
                                            <button className='btn tbtn rounded-2 px-3'>0.01%</button>
                                            <button className='btn tbtn rounded-2 px-3'>0.05%</button>
                                            <button className='btn tbtn rounded-2 px-3'>0.25%</button>
                                            <button className='btn tbtn rounded-2 px-3'>1.00%</button>
                                        </div> : <></>}
                                </div>
                                <p className='themeclr fw-500 mt-4'>DEPOSIT AMOUNT</p>

                                <div className='d-flex justify-content-between'>
                                    <div className=''>
                                        <p className='mb-1 fw-600'><img src={changefirst.image} className='img-fluid ethimg' /> {changefirst.coinname} <CopyToClipboard text="Hello!">
                                            <FaRegCopy className='grnclr' />
                                        </CopyToClipboard> <img src={Images.meta} className='img-fluid ethimg' /></p>
                                    </div>
                                    <p className='mb-0 themeclr f-15'>Balance 0</p>
                                </div>
                                <div className='swap__blueBox rounded-3 py-3 px-2 mt-2'>
                                    <input type="text" className="form-control swap_input w-100 text-end"  placeholder='6' />
                                    <p className='themeclr f-14 mt-1 text-end mb-0'>-6.00 USD</p>
                                </div>
                                <div className='d-flex justify-content-between mt-3'>
                                    <div className=''>
                                        <p className='mb-1 fw-600'><img src={changesecond.image} className='img-fluid ethimg' /> {changesecond.coinname}</p>
                                    </div>
                                    <p className='mb-0 themeclr f-15'>Balance 0</p>
                                </div>
                                <div className='swap__blueBox rounded-3 py-3 px-2 mt-1'>
                                    <input type="text" className="form-control swap_input w-100 text-end"  placeholder='6'/>
                                    <p className='themeclr f-14 mt-1 text-end mb-0'>-6.00 USD</p>
                                </div>


                            </div>
                            <div className='col-lg-6 mt-4 mt-lg-0'>
                                <p className='orgclr fw-600'>View Price in  <button className='btn tbtn rounded-2 px-2 ms-2' onClick={() => onswap()}><MdSwapHoriz className='set_icon' /> {changefirst.coinname}</button></p>
                                <p className='themeclr fw-500'>SET PRICE RANGE</p>
                                <div className='row'>
                                    <div className='col-lg-6'>
                                        <div className='option_border rounded-4 text-center py-3 px-2'>
                                            <p className='mb-2 fw-500'>Min Price</p>
                                            <p className='themeclr fw-600 mb-2'><LuMinusCircle style={{ cursor: "pointer" }} onClick={decrement} /><span className='mx-3'>{count}</span><GoPlusCircle style={{ cursor: "pointer" }} onClick={increment} /></p>
                                            <p className='mb-0 fw-500'>{changefirst.coinname} per {changesecond.coinname}</p>
                                        </div>
                                    </div>
                                    <div className='col-lg-6'>
                                        <div className='option_border rounded-4 text-center py-3 px-2'>
                                            <p className='mb-2 fw-500'>Max Price</p>
                                            <p className='themeclr fw-600 mb-2'><LuMinusCircle style={{ cursor: "pointer" }} onClick={decrement1} /><span className='mx-3'>{count1}</span><GoPlusCircle style={{ cursor: "pointer" }} onClick={increment1} /></p>
                                            <p className='mb-0 fw-500'>{changefirst.coinname} per {changesecond.coinname}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='d-flex gap-2 mt-3'>
                                    <button className='btn tbtn rounded-2 px-2 px-sm-3'>5%</button>
                                    <button className='btn tbtn rounded-2 px-2 px-sm-3'>10%</button>
                                    <button className='btn tbtn rounded-2 px-2 px-sm-3'>20%</button>
                                    <button className='btn tbtn rounded-2 px-2 px-sm-3'>Full Range</button>
                                </div>
                                <button className='btn btn-brand-1 sitebtn hover-up mt-4 w-100' >Enter an Amount</button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        </>
    )
}

export default Liquidtytable;