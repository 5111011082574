import React, { useState, useEffect } from "react";
import Innerheader from "../Layouts/innerheader";
import Footer from "../Layouts/footer";
import {
  Container,
  Dropdown,
  InputGroup,
  Form,
  Table,
  Row,
  Col,
} from "react-bootstrap";
import "../Karthick.css";
import "../inner.css";
import Images from "../Components/Images";
import { MdKeyboardArrowRight } from "react-icons/md";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { IoSearchOutline } from "react-icons/io5";
import Select from "react-select";
import { FaFilter } from "react-icons/fa";
import { FaThumbsUp } from "react-icons/fa6";
import { MdOutlineTimer } from "react-icons/md";
import { FiRefreshCw } from "react-icons/fi";
import { FaGreaterThan } from "react-icons/fa6";
import Banklist from "../Components/banklist";
import { Link } from "react-router-dom";

const P2pTrade = () => {

  const [modaloption, setModaloption] = useState(true);
  const [value, setValue] = useState(0);
  const[bankmodal,setBankmodal] = useState(false);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const colourStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      // const color = chroma(data.color);
      console.log({ data, isDisabled, isFocused, isSelected });
      return {
        ...styles,
        backgroundColor: isFocused ? "rgb(151 243 255 / 49%)" : null,
        color: "#2D699B",
        fontWeight: "500"
      };
    }
  };

  const [buysell, setBuysell] = useState("buy");
  const coins = [
    "USDT",
    "BNB",
    "BTC",
    "FIAT",
    "USDC",
    "TUSD",
    "FDUSD",
    "ALTS",
    "ETH",
    "DAI",
    "XRP",
  ];
  const [coinsimg] = useState([
    {
      img: Images.btc,
      cname: "BTC",
    },
    {
      img: Images.usdc,
      cname: "usdc",
    },
    {
      img: Images.dai,
      cname: "dai",
    },
    {
      img: Images.bnb,
      cname: "bnb",
    },
    {
      img: Images.btc,
      cname: "BTC",
    },
    {
      img: Images.usdc,
      cname: "usdc",
    },
    {
      img: Images.dai,
      cname: "dai",
    },
    {
      img: Images.bnb,
      cname: "bnb",
    },
  ]);

  const [dropcoins, setDropcoins] = useState("");
  const [selectbox, setSelectbox] = useState([
    {
      value: "Upi",
      label: "UPI",
    },
    {
      value: "paytm",
      label: "Paytm",
    },
    {
      value: "Google pay",
      label: "Google pay (GPay)",
    },
  ]);
  const [selectboxtwo, setSelectboxtwo] = useState([
    {
      value: "All Regions",
      label: "All Regions",
    },
    {
      value: "india",
      label: "India",
    },
    {
      value: "australia",
      label: "Australia",
    },
    {
      value: "malaysia",
      label: "Malaysia",
    },
  ]);

  const [selectboxthree, setSelectboxthree] = useState([
    {
      value: "Every 5s",
      label: "Every 5s",
    },
    {
      value: "Every 10s",
      label: "Every 10s",
    },
    {
      value: "Every 20s",
      label: "Every 20s",
    },
  ]);

  const p2ptablehead = [
    "Advertisers",
    "Price",
    "Available/Limit",
    "Payment",
    "Trade",
  ];
  const [p2ptable, setP2ptable] = useState([
    {
      ad: {
        img: Images.eimg,
        img1: Images.tickimg,
        cname: "Lorem_name",
        orders: "35",
        completion: "89.80%",
        likes: "97.97%",
        time: "15",
      },
      price: "90.05",
      avail: {
        num: "8,000.00",
        cost: "$500,000.00 - $724,000.00",
      },
      payment: {
        upi: "UPI",
        bank: "Bank Transfer (India)",
      },
      avgrelease: "1.33",
      available: "175.55",
    },
    {
      ad: {
        img: Images.eimg,
        cname: "Lorem_name",
        orders: "35",
        completion: "89.80%",
        likes: "97.97%",
        time: "15",
      },
      price: "90.05",
      avail: {
        num: "8,000.00",
        cost: "$500,000.00 - $724,000.00",
      },
      payment: {
        upi: "UPI",
        bank: "Bank Transfer (India)",
      },
      avgrelease: "1.33",
      available: "175.55",
    },
    {
      ad: {
        img: Images.eimg,
        img1: Images.tickimg,
        cname: "Lorem_name",
        orders: "35",
        completion: "89.80%",
        likes: "97.97%",
        time: "15",
      },
      price: "90.05",
      avail: {
        num: "8,000.00",
        cost: "$500,000.00 - $724,000.00",
      },
      payment: {
        upi: "UPI",
        bank: "Bank Transfer (India)",
      },
      avgrelease: "1.33",
      available: "175.55",
    },
    {
      ad: {
        img: Images.eimg,
        img1: Images.tickimg,
        cname: "Lorem_name",
        orders: "35",
        completion: "89.80%",
        likes: "97.97%",
        time: "15",
      },
      price: "90.05",
      avail: {
        num: "8,000.00",
        cost: "$500,000.00 - $724,000.00",
      },
      payment: {
        upi: "UPI",
        bank: "Bank Transfer (India)",
      },
      avgrelease: "1.33",
      available: "175.55",
    },
    {
      ad: {
        img: Images.eimg,
        cname: "Lorem_name",
        orders: "35",
        completion: "89.80%",
        likes: "97.97%",
        time: "15",
      },
      price: "90.05",
      avail: {
        num: "8,000.00",
        cost: "$500,000.00 - $724,000.00",
      },
      payment: {
        upi: "UPI",
        bank: "Bank Transfer (India)",
      },
      avgrelease: "1.33",
      available: "175.55",
    },
  ]);

  const [coinnum, setCoinnum] = useState("usdt");
  const [show, setShow] = useState();
  const [show1, setShow1] = useState();

  const [coinsimgtwo] = useState([
    {
      img: Images.btc,
      cname: "BTC",
    },
    {
      img: Images.usdc,
      cname: "usdc",
    },
    {
      img: Images.dai,
      cname: "dai",
    },
    {
      img: Images.bnb,
      cname: "bnb",
    }
  ]);
  const [dropcoinstwo, setDropcoinstwo] = useState("");

  const [bankshow, setBankshow] = useState({});
  const [initialbank, setInitialbank] = useState(
      {
          name: "Bank 1",
      }
  );
  useEffect(() => {
    window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
    });
}, []);

  return (
    <>
      {bankmodal && <Banklist onDismiss={()=>setBankmodal(false)} setInitialbank={(e) => setInitialbank(e)} setBankshow={(e) => setBankshow(e)}/>}
      <Innerheader />

      <Container fluid className="innerpage pools_sec assetpage px-0 pos">
        <Container className="pt-1 container_max px-xxl-3">
          <img src={Images.backlogo} className="backlogo farms__backlogo" />
          <div className="p2ptrade pt-2 pt-sm-4">
            <div className="coinpairprice">

              <div className="text-end">
              <Link to="/p2pcreate"><button class="btn btn-brand-1 sitebtn hover-up mt-3 mb-3">Create</button></Link>
              </div>
              <div className="flexbox justify-content-center justify-content-sm-start">
                <div className="buysellbox">
                  <ul className="table_tabs overauto mb-0 pb-0">
                    <li
                      className={buysell == "buy" ? "active ms-0" : "ms-0"}
                      onClick={() => setBuysell("buy")}>
                      Buy
                    </li>
                    <li
                      className={buysell == "sell" ? "active" : ""}
                      onClick={() => setBuysell("sell")}>
                      Sell
                    </li>
                  </ul>
                </div>
                <div className="tradetab">
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                  >
                    {coins.map((co) => (
                      <Tab label={co} onClick={() => setCoinnum(co)} />
                    ))}
                  </Tabs>
                </div>
              </div>
              <div className="flexbox secondbox mt-3">
                <div>
                  <InputGroup className="inputall pos">
                    <Form.Control
                      className="form-control input_spot text-end"
                      aria-label="Text input with dropdown button"
                      placeholder="Enter Amount"
                    />
                    <Dropdown>
                      <Dropdown.Toggle variant="success" id="dropdown-basic">
                        {dropcoins == "" ? (
                          <>
                            <img src={coinsimg[0].img} />
                            <span>{coinsimg[0].cname}</span>
                          </>
                        ) : (
                          <>
                            <img src={dropcoins.img} />
                            <span>{dropcoins.cname}</span>
                          </>
                        )}
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <InputGroup>
                          <InputGroup.Text>
                            <IoSearchOutline />
                          </InputGroup.Text>
                          <Form.Control type="text"></Form.Control>
                        </InputGroup>
                        <div className="coinslist">
                          {coinsimg.map((ci) => (
                            <Dropdown.Item onClick={() => setDropcoins(ci)}>
                              <div className="coins">
                                <img src={ci.img} alt={ci.cname} />
                                <span>{ci.cname}</span>
                              </div>
                            </Dropdown.Item>
                          ))}
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
                  </InputGroup>
                </div>
                <div>
                  <div className="multiselectbox">
                    <Select
                      isMulti
                      options={selectbox}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      placeholder="All Payments"
                      styles={colourStyles}
                    />
                  </div>
                </div>
                <div>
                  <div className="multiselectbox">
                    <Select
                      options={selectboxtwo}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      placeholder="All Regions"
                      styles={colourStyles}
                    />
                  </div>
                </div>
                <div>
                  <Dropdown className="filter">
                    <Dropdown.Toggle>
                      <div className="filterbox">
                        <FaFilter />
                      </div>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>No data</Dropdown.Menu>
                  </Dropdown>
                </div>
                <div>
                  <div className="multiselectbox">
                    <Select
                      options={selectboxthree}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      placeholder="Manual"
                      styles={colourStyles}
                    />
                  </div>
                </div>
              </div>
              <div className="p2ptable">
                {buysell == "buy" && (
                  <Table responsive>
                    <thead>
                      <tr>
                        {p2ptablehead.map((pt) => (
                          <th>{pt}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {p2ptable.map((td, i) => (
                        <>
                          {show == i ? (
                            <tr className="tabledescdata">
                              <td colSpan={p2ptablehead.length}>
                                <Row>
                                  <Col xs={12} sm={12} md={12} lg={7}>
                                    <div className="leftdescbg">
                                      <div className="flexdata">
                                        <div>
                                          <img
                                            src={td.ad.img}
                                            alt={td.ad.cname}
                                          />                                 
                                        </div>
                                        <div>
                                          <Link to="/advertisedetail">
                                          <span className="coinname">
                                            {td.ad.cname}
                                            <span>
                                              <FaThumbsUp />
                                              <span className="ps-1">
                                                {td.ad.likes}
                                              </span>
                                            </span>
                                          </span></Link>
                                          <div className="flex my-1">
                                            <span>{td.ad.orders} orders</span>
                                            <span className="opacity-0">|</span>
                                            <span>{td.ad.completion} completion</span>
                                          </div>
                                          <div className="flex coinbalance my-4">
                                            <div>
                                              <p className="coinbaldata">{td.ad.time} min</p>
                                              <span>Payment Time Limit</span>
                                            </div>
                                            <div>
                                              <p className="coinbaldata">{td.avgrelease} Minutes</p>
                                              <span>Avg. Release Time</span>
                                            </div>
                                            <div>
                                              <p className="coinbaldata">{td.available} <small className="text-uppercase">{coinnum}</small></p>
                                              <span>Available</span>
                                            </div>
                                          </div>
                                          <div className="adterms">
                                            <p className="adtermshead">
                                              Advertiser's Terms (Please read carefully)
                                            </p>
                                            <p className="content">
                                              This advertiser did not set any terms.
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </Col>
                                  <Col xs={12} sm={12} md={12} lg={5}>
                                    <div className="rightdescbg">
                                      <p className="price">
                                        Price
                                        <span className="t-green px-2">
                                          <span className="pe-1">
                                            {td.price}
                                          </span>
                                          {dropcoins == ""
                                            ? coinsimg[0].cname
                                            : dropcoins.cname}
                                        </span>
                                        <FiRefreshCw />
                                      </p>
                                      <div className="swap__blueBox p-3 rounded-4">
                                        <div className="d-block d-sm-flex align-items-center justify-content-between">
                                          <div className="d-flex mb-2 mb-sm-0">
                                            <p className="m-0 poppins_font blkclr swap__fileValue fw-600">You pay</p>
                                          </div>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between mt-2">
                                          <Form.Control type="text" className="swap_input" placeholder="9,000.00 - 170000.00"></Form.Control>
                                          <div className="d-flex align-items-center">
                                            <p className="orgclr mb-0 fw-600 cursor">Max</p>
                                           <p className="mb-0 ms-3 themeclr fw-500"><img src={Images.bnb} className="img-fluid ethimg"/> BNB</p>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="swap__blueBox p-3 rounded-4 mt-4">
                                        <div className="d-block d-sm-flex align-items-center justify-content-between">
                                          <div className="d-flex mb-2 mb-sm-0">
                                            <p className="m-0 poppins_font swap__fileValue blkclr fw-600">You Recieve</p>
                                          </div>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between mt-2">
                                          <Form.Control type="text" className="swap_input" placeholder="0.00"></Form.Control>
                                          <p className="mb-0 ms-3 themeclr fw-500"><img src={Images.usdc} className="img-fluid ethimg"/> USDC</p>

                                        </div>
                                      </div>
                                      <div className="swap__blueBox p-3 rounded-4 mt-4 cursor" onClick={()=>setBankmodal(true)}>
                                        <div className="d-flex align-items-center justify-content-between">
                                          <div>
                                            <span>{initialbank.name}</span>
                                          </div>
                                          <div>
                                            <span className="greaterthan"><MdKeyboardArrowRight className="themeclr set_icon"/></span>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="text-center mt-4 mt-sm-5">
                                        <button type="button" class="btn btn-brand-1 sitebtn hover-up me-3" onClick={() => setShow()}>Cancel</button>
                                        <Link to="/chartpage"><button type="button" class="btn btn-brand-1 sitebtn hover-up">
                                          Buy <span className="text-uppercase">{coinnum}</span>
                                        </button></Link>
                                      </div>
                                    </div>
                                  </Col>
                                </Row>
                              </td>
                            </tr>
                          ) : (
                            <tr>
                              <td>
                                <div className="flexdata">
                                  <div>
                                    <img src={td.ad.img} alt={td.ad.cname} />
                                  </div>
                                  <div>
                                  <Link to="/advertisedetail">
                                    <span className="coinname">
                                      {td.ad.cname}
                                      {td.ad.img1?
                                      <img src={td.ad.img1} className="img-fluid imgtick ms-2" alt="dex"/>:<></>}
                                    </span></Link>
                                    <div className="flex my-1">
                                      <span>{td.ad.orders} orders</span>
                                      <span className="opacity">|</span>
                                      <span>{td.ad.completion} completion</span>
                                    </div>
                                    <div className="flex">
                                      <span>
                                        <FaThumbsUp />
                                        <span className="ps-1">
                                          {td.ad.likes}
                                        </span>
                                      </span>
                                      <span className="opacity">|</span>
                                      <span>
                                        <MdOutlineTimer /> {td.ad.time} min
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <span className="price">
                                  {td.price}
                                  <span className="ms-1">
                                    {dropcoins == ""
                                      ? coinsimg[0].cname
                                      : dropcoins.cname}
                                  </span>
                                </span>
                              </td>
                              <td>
                                <div className="availimit">
                                  <span className="mb-2">
                                    {td.avail.num}
                                    <small className="text-uppercase ps-1">
                                      {coinnum}
                                    </small>
                                  </span>
                                  <span>{td.avail.cost}</span>
                                </div>
                              </td>
                              <td>
                                <div className="availimit">
                                  <span className="mb-2">{td.payment.upi}</span>
                                  <span>{td.payment.bank}</span>
                                </div>
                              </td>
                              <td>
                                <button
                                  type="button"
                                  className="btn btn-brand-1 sitebtn hover-up"
                                  onClick={() => setShow(i)}
                                >
                                  Buy
                                  <span className="text-uppercase ps-1">
                                    {coinnum}
                                  </span>
                                </button>
                              </td>
                            </tr>
                          )}
                        </>
                      ))}
                    </tbody>
                  </Table>
                )}
                {buysell == "sell" && (
                  <Table responsive>
                    <thead>
                      <tr>
                        {p2ptablehead.map((pt) => (
                          <th>{pt}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {p2ptable.map((td,i) => (
                        <>
                          {show1 == i ? (
                            <tr className="tabledescdata">
                              <td colSpan={p2ptablehead.length}>
                                <Row>
                                  <Col xs={12} sm={12} md={12} lg={7}>
                                    <div className="leftdescbg">
                                      <div className="flexdata">
                                        <div>
                                          <img
                                            src={td.ad.img}
                                            alt={td.ad.cname}
                                          />
                                        </div>
                                        <div>
                                        <Link to="/advertisedetail">
                                          <span className="coinname">
                                            {td.ad.cname}
                                            <span>
                                              <FaThumbsUp />
                                              <span className="ps-1">
                                                {td.ad.likes}
                                              </span>
                                            </span>
                                          </span></Link>
                                          <div className="flex my-1">
                                            <span>{td.ad.orders} orders</span>
                                            <span className="opacity-0">|</span>
                                            <span>{td.ad.completion} completion</span>
                                          </div>
                                          <div className="flex coinbalance my-4">
                                            <div>
                                              <p className="coinbaldata">{td.ad.time} min</p>
                                              <span>Payment Time Limit</span>
                                            </div>
                                            <div>
                                              <p className="coinbaldata">{td.avgrelease} Minutes</p>
                                              <span>Avg. Release Time</span>
                                            </div>
                                            <div>
                                              <p className="coinbaldata">{td.available} <small className="text-uppercase">{coinnum}</small></p>
                                              <span>Available</span>
                                            </div>
                                          </div>
                                          <div className="adterms">
                                            <p className="adtermshead">
                                              Advertiser's Terms (Please read carefully)
                                            </p>
                                            <p className="content">
                                              This advertiser did not set any terms.
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </Col>
                                  <Col xs={12} sm={12} md={12} lg={5}>
                                    <div className="rightdescbg">
                                      <p className="price">
                                        Price
                                        <span className="redclr px-2">
                                          <span className="pe-1 redclr">
                                            {td.price}
                                          </span>
                                          {dropcoins == ""
                                            ? coinsimg[0].cname
                                            : dropcoins.cname}
                                        </span>
                                        <FiRefreshCw />
                                      </p>
                                      <div className="swap__blueBox p-3 rounded-4">
                                        <div className="d-block d-sm-flex align-items-center justify-content-between">
                                          <div className="d-flex mb-2 mb-sm-0">
                                            <p className="m-0 poppins_font blkclr swap__fileValue fw-600">You pay</p>
                                          </div>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between mt-2">
                                          <Form.Control type="text" className="swap_input" placeholder="9,000.00 - 170000.00"></Form.Control>
                                          <div className="d-flex align-items-center">
                                            <p className="orgclr mb-0 fw-600 cursor">Max</p>
                                           <p className="mb-0 ms-3 themeclr fw-500"><img src={Images.bnb} className="img-fluid ethimg"/> BNB</p>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="swap__blueBox p-3 rounded-4 mt-4">
                                        <div className="d-block d-sm-flex align-items-center justify-content-between">
                                          <div className="d-flex mb-2 mb-sm-0">
                                            <p className="m-0 poppins_font blkclr swap__fileValue fw-600">You Recieve</p>
                                          </div>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between mt-2">
                                          <Form.Control type="text" className="swap_input" placeholder="0.00"></Form.Control>
                                          <p className="mb-0 ms-3 themeclr fw-500"><img src={Images.usdc} className="img-fluid ethimg"/> USDC</p>

                                        </div>
                                      </div>
                                      <div className="swap__blueBox p-3 rounded-4 mt-4">
                                        <div className="d-flex align-items-center justify-content-between cursor" onClick={()=>setBankmodal(true)}>
                                          <div>
                                            <span>{td.payment.upi}</span>
                                          </div>
                                          <div>
                                            <span className="greaterthan"><MdKeyboardArrowRight className="themeclr set_icon"/></span>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="text-center mt-4 mt-sm-5">
                                        <button type="button" class="btn btn-brand-1 sitebtn hover-up me-3" onClick={() => setShow1()}>Cancel</button>
                                        <Link to="/chartpage"><button type="button" class="btn btn-brand-1 sitebtn hover-up">
                                          Sell <span className="text-uppercase">{coinnum}</span>
                                        </button></Link>
                                      </div>
                                    </div>
                                  </Col>
                                </Row>
                              </td>
                            </tr>) :
                            (<tr>
                              <td>
                                <div className="d-flex gap-2">
                                  <div>
                                    <img src={td.ad.img} alt={td.ad.cname} />
                                  </div>
                                  <div>
                                  <Link to="/advertisedetail">
                                    <span className="coinname">{td.ad.cname}
                                    {td.ad.img1?
                                      <img src={td.ad.img1} className="img-fluid imgtick ms-2" alt="dex"/>:<></>}
                                    </span></Link>
                                    <div className="flex my-1">
                                      <span>{td.ad.orders} orders</span>
                                      <span className="opacity">|</span>
                                      <span>{td.ad.completion} completion</span>
                                    </div>
                                    <div className="flex">
                                      <span>
                                        <FaThumbsUp />{" "}
                                        <span className="ps-1">{td.ad.likes}</span>
                                      </span>
                                      <span className="opacity">|</span>
                                      <span>
                                        <MdOutlineTimer /> {td.ad.time} min
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <span className="price">
                                  {td.price}
                                  <span className="ms-1">
                                    {dropcoins == ""
                                      ? coinsimg[0].cname
                                      : dropcoins.cname}
                                  </span>
                                </span>
                              </td>
                              <td>
                                <div className="availimit">
                                  <span className="mb-2">
                                    {td.avail.num} {coinnum}
                                  </span>
                                  <span>{td.avail.cost}</span>
                                </div>
                              </td>
                              <td>
                                <div className="availimit">
                                  <span className="mb-2">{td.payment.upi}</span>
                                  <span>{td.payment.bank}</span>
                                </div>
                              </td>
                              <td>
                                <button
                                  type="button"
                                  className="btn btn-brand-1 sitebtn hover-up sellbtn"
                                  onClick={() => setShow1(i)}
                                >
                                  Sell{" "}
                                  <span className="text-uppercase">{coinnum}</span>
                                </button>
                              </td>
                            </tr>)}
                        </>
                      ))}
                    </tbody>
                  </Table>
                )}
              </div>
            </div>
          </div>
        </Container>
      </Container>

      <Footer />
    </>
  );
};

export default P2pTrade;
