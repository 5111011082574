import React, { useState, useEffect } from 'react';
import Images from "../../Components/Images";
import { Table } from "react-bootstrap";
import { MdOutlineDeleteOutline } from "react-icons/md";
import { FaSort } from 'react-icons/fa6';

function Funds() {

    return (
        <div className='text-center'>
         
            <div className="price_table w-100 overauto">
                <Table responsive>
                    <thead>
                        <tr>
                            <th>Coin <FaSort/></th>
                            <th>Total Balance <FaSort/></th>
                            <th>Available Balance <FaSort/></th>
                            <th>In order <FaSort/></th>
                            <th>Debt <FaSort/></th>
                            <th>Max tranfer out amount</th>
                            <th>Equidity/Equidity value(BTC) <FaSort/></th>
                            <th>Action</th>
                           
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>--</td>
                            <td>--</td>
                            <td>--</td>
                            <td>--</td>
                            <td>--</td>
                            <td>--</td>
                            <td>--</td>                           
                            <td>--</td>
                        </tr>
                    </tbody>
                </Table>
            </div>
        </div>
    )
}

export default Funds;