import React, { useState, useEffect } from 'react';
import { Button, Container } from 'react-bootstrap';
import Header from "../../Layouts/header";
import Footer from "../../Layouts/footer";
import Marquee from "react-fast-marquee";
import Images from '../Images';
import { Link, NavLink } from "react-router-dom";
import ReactPlayer from 'react-player';
import derivative from "../../Assets/derivative.png";
import fiat from "../../Assets/fiat.png";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from "swiper/modules";
import 'swiper/css';
import partner2 from "../../Assets/partner2.svg";
import partner3 from "../../Assets/partner3.svg";
import AOS from "aos";
import "aos/dist/aos.css";
import CountUp from 'react-countup';
import Subheader from './subheader';
import Announcement from '../announcement';

const Landingpage = () => {

    const [parnetcard, setpartnercard] = useState([
        { "id": 1 }, { "id": 2 }, { "id": 3 }, { "id": 4 }, { "id": 5 }, { "id": 6 }, { "id": 7 }
    ]);

    const [initialimg, setinitialimg] = useState(true);
    const [initialimg1, setinitialimg1] = useState(true);

    useEffect(() => {
        AOS.init({
            disable: "phone",
            easing: "ease-out-cubic",
        });
    }, []);



    return (
        <>

            <div>
                <Header />

                <div className='allpage'>


                    <div className='first_section'>



                        <div className='backgif'>
                            <div className='container'>
                                <div className='row'>
                                    <div className='col-md-11 mx-auto'>
                                    
                                 
                                 <Subheader/>

                                    
                                    </div>
                                </div>
                                {/* <div className='silderss slidepad mb-2 mb-sm-4'>
                                    <Marquee speed={80} direction='left'>
                                        <div className="partnermarquee">
                                            {parnetcard.map((e, i) =>
                                                <div className="partnerwidth">
                                                    <p className='mb-0'>Lorem Ipsum</p>
                                                </div>
                                            )}
                                        </div>
                                    </Marquee>
                                    <img src={Images.backlogo} className='backlogo' />
                                    <img src={Images.elogo} className='elogo' />
                                </div> */}
                                <Announcement/>

                                <div className='text-center pt-3 pt-xxl-5' data-aos="fade-up" data-aos-duration="1200">
                                    <h1 className='heading1'>Bringing New Insight For Your</h1>
                                    <h1 className='heading2 mb-3'>Decentralized Finance</h1>
                                    <p className='subheading mt-xxl-4'>EdaFace is a user interface aggregator that brings all the various functionalities of the crypto industry onto a single platform!</p>
                                </div>

                                <div className='text-center mt-3 mt-xxl-5'>
                                    <Link className="btn btn-brand-1 sitebtn hover-up" to="/">Launch DApp</Link>
                                    <img src={Images.backlogo} className='d-block img-fluid downlogo' />
                                </div>
                            </div>
                        </div>

                        <div className='container'>

                            {/* <div className='silderss mb-2 mb-sm-4'>

                            </div> */}




                            <div className='row trendlist pt-3 pt-sm-5 pb-5'>
                                <img src={Images.e2logo} className='e2logo' />
                                <img src={Images.elogo} className='trendlogo' />
                                <img src={Images.bluellipse} className='bluellipse' />
                                <div className='col-11 col-sm-10 col-md-12 col-lg-11 col-xl-10 col-xxl-9 mx-auto'>
                                    <div className='row'>
                                        <div className='col-sm-6 col-md-3 mt-3' data-aos="zoom-in" data-aos-duration="1200">
                                            <div className='boxborder'>
                                                <CountUp end={533} duration={3} />
                                                {/* <h4>533</h4> */}
                                                <p className='mb-0'>EdaFace Dex</p>
                                            </div>
                                        </div>
                                        <div className='col-sm-6 col-md-3 mt-3' data-aos="zoom-in" data-aos-duration="1200">
                                            <div className='boxborder'>

                                                <h4>$<CountUp end={376} duration={3} />B+</h4>
                                                <p className='mb-0'>TVL</p>
                                            </div>
                                        </div>
                                        <div className='col-sm-6 col-md-3 mt-3' data-aos="zoom-in" data-aos-duration="1200">
                                            <div className='boxborder'>
                                                <h4><CountUp end={15} duration={3} />+M</h4>
                                                <p className='mb-0'>total trades</p>
                                            </div>
                                        </div>
                                        <div className='col-sm-6 col-md-3 mt-3' data-aos="zoom-in" data-aos-duration="1200">
                                            <div className='boxborder'>
                                                <h4><CountUp end={88.2} duration={3} />M+</h4>
                                                <p className='mb-0'>total users</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='row trendlist pt-3 pt-sm-5 pb-4'>
                                <img src={Images.bluellipse} className='topblu' />
                                <img src={Images.bluellipse} className='downblu' />
                                <img src={Images.elogo} className='downelogo' />
                                <div className='col-md-6 mt-3' data-aos="fade-up" data-aos-duration="1700">
                                    <div className='videoborder'>
                                        <ReactPlayer controls url='https://www.youtube.com/watch?v=4RSre4Z7kYM' />
                                        {initialimg &&
                                            <div className='border' onClick={() => setinitialimg(false)}>
                                                <img src={Images.edafacelogo} className='img-fluid' />
                                                <h4 className='head mt-3'>We,the  Edaface Community</h4>
                                                <p className='head mb-0'>Lorem ipsum dolor sit amet consectetur. Cursus non egestas ornare malesuada feugiat arcu.</p>
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className='col-md-6 mt-3' data-aos="fade-up" data-aos-duration="1700">
                                    <div className='videoborder'>
                                        <ReactPlayer controls url='https://www.youtube.com/watch?v=C84AD1pDi_Q' />
                                        {initialimg1 &&
                                            <div className='border border2' onClick={() => setinitialimg1(false)}>
                                                <img src={Images.edafacelogo3} className='img-fluid' />
                                                <h4 className='headclr fw-600 mt-3'>We,the  Edaface Community</h4>
                                                <p className='headclr mb-0'>Lorem ipsum dolor sit amet consectetur. Cursus non egestas ornare malesuada feugiat arcu.</p>
                                            </div>
                                        }
                                    </div>
                                </div>

                            </div>

                            <div className='row trendlist pt-5 pb-5'>
                                <img src={Images.e2logo} className='leftelogo' />
                                <img src={Images.backlogo} className='backleftlogo' />
                                <img src={Images.backlogo} className='backrightlogo' />
                                <div className='col-md-9 col-lg-8 col-xl-8 col-xxl-7 mx-auto' data-aos="fade-up" data-aos-duration="1800">
                                    <div className='colorborder'>
                                        <div className='whiteborder'>
                                            <h5 className='fw-400'>Edaface</h5>
                                            <h4>MIM Pools</h4>
                                            <p>EdaFace Lending MIM Pool is a liquidity pool built by smart contract. You can view this liquidity pool as a central account where lenders can deposit their cryptocurrencies and borrowers can then withdraw some of this deposited crypto as loans.</p>
                                            <Link className="btn btn-brand-1 sitebtn hover-up" to="/pools">Click Here</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='row tradingcont align-items-center pt-4 pb-4'>
                                <div className='col-md-3 col-lg-3 col-xl-3 col-xxl-4 mt-3'>
                                    <h5 className='fw-400'>Edaface</h5>
                                    <h5 className='fw-600'>Yield Farms</h5>
                                    <p className='f-15 mt-2'>A Liquidity Pool is a smart contract containing portions of digital assets, which are locked up and ready to provide essential liquidity for decentralized trading. EdaFace Dex liquidity pools are MIM Pools.</p>
                                </div>
                                <div className='col-md-9 col-lg-9 col-xl-9 col-xxl-8 mt-3 position-relative'>
                                    <img src={Images.bluellipse} className='topellipse' />
                                    <img src={Images.bluellipse} className='bottomellipse' />
                                    <img src={Images.backlogo} className='botlogo' />
                                    <img src={Images.elogo} className='botelogo' />
                                    <Swiper
                                        breakpoints={{
                                            0: {
                                                slidesPerView: 1.2,
                                            },
                                            576: {
                                                slidesPerView: 1.5,
                                            },
                                            768: {
                                                slidesPerView: 1.8,
                                            },
                                            992: {
                                                slidesPerView: 2.3
                                            },

                                        }}
                                        spaceBetween={20}
                                        slidesPerView={2.3}
                                        autoplay
                                        onSlideChange={() => console.log('slide change')}
                                        onSwiper={(swiper) => console.log(swiper)}
                                    >
                                        <SwiperSlide>
                                            <div className='cardborder'>
                                                <h4>Collect and Stake NFTs</h4>
                                                <img src={Images.safebox} className='img-fluid w-25 mt-2 mb-2' />
                                                <p className='f-14 mb-2 mt-xxl-3 mb-xxl-3'>EdaFace Plantation will offer active trading and staking of NFTs</p>
                                                <Link className="btn outlines_btn mt-2" to="/">NFT Marketplace</Link> <Link className="btn btn-brand-1 sitebtn ms-2 mt-2 hover-up" to="/">NFT Staking</Link>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className='cardborder'>
                                                <h4>Spot trading</h4>
                                                <img src={Images.profit} className='img-fluid w-25 mt-2 mb-2' />
                                                <p className='f-14 mb-2 mt-xxl-3 mb-xxl-3'>EdaFace Plantation will offer active trading and staking of NFTs</p>
                                                <Link className="btn btn-brand-1 sitebtn ms-2 mt-2 hover-up" to="/">Spot Trading</Link>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className='cardborder'>
                                                <h4>Spot trading</h4>
                                                <img src={Images.profit} className='img-fluid w-25 mt-2 mb-2' />
                                                <p className='f-14 mb-2 mt-xxl-3 mb-xxl-3'>EdaFace Plantation will offer active trading and staking of NFTs</p>
                                                <Link className="btn outlines_btn mt-2" to="/">NFT Marketplace</Link> <Link className="btn btn-brand-1 sitebtn ms-2 mt-2 hover-up" to="/">NFT Staking</Link>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className='cardborder'>
                                                <h4>Spot trading</h4>
                                                <img src={Images.profit} className='img-fluid w-25 mt-2 mb-2' />
                                                <p className='f-14 mb-2 mt-xxl-3 mb-xxl-3'>EdaFace Plantation will offer active trading and staking of NFTs</p>
                                                <Link className="btn outlines_btn mt-2" to="/">NFT Marketplace</Link> <Link className="btn btn-brand-1 sitebtn ms-2 mt-2 hover-up" to="/">NFT Staking</Link>
                                            </div>
                                        </SwiperSlide>
                                    </Swiper>
                                </div>
                            </div>

                            <div className='row tradingcont trendlist pt-4 pb-5'>
                                <img src={Images.elogo} className='logoe' />
                                <div className='col-md-6 mt-4 mt-md-3' data-aos="fade-up" data-aos-duration="1800">
                                    <h5>Derivative Trading</h5>
                                    <img src={derivative} className='img-fluid w-50 w-65' />
                                    <p>A Liquidity Pool is a smart contract containing portions of digital assets, which are locked up and ready to provide essential liquidity for decentralized trading. </p>
                                    <Link className="btn btn-brand-1 sitebtn hover-up" to="/future">Derivative Trading</Link>
                                </div>
                                <div className='col-md-6 mt-5 mt-md-3' data-aos="fade-up" data-aos-duration="1800">
                                    <h5>Fiat Crypto Exchange</h5>
                                    <img src={fiat} className='img-fluid w-100' />
                                    <p>Our forums helps you to create your questions on different subjects and communicate with other forum users. Our users will help you to get the best answer! </p>
                                    <Link className="btn outlines_btn mt-2" to="/">NFT Marketplace</Link> <Link className="btn btn-brand-1 sitebtn ms-0 ms-sm-4 ms-md-0 ms-xl-4 mt-2 hover-up" to="/future">Derivative Trading</Link>
                                </div>
                            </div>

                            <div className='row trendlist text-center pt-5 pb-3'>
                                <img src={Images.elogo} className='launchlogo' />
                                <div className='col-md-8 mx-auto'>
                                    <h4 className='fw-700'>List and Launch your projects</h4>
                                    <p>We have a wide range of ICO, IEO, IDO, etc. that are Ongoing, Upcoming and Past; you can discover them from here.</p>
                                    <Link className="btn outlines_btn mb-3" to="/">EdaFace Listing</Link> <Link className="btn btn-brand-1 sitebtn ms-0 ms-sm-4 mb-3 hover-up" to="/">EdaFace Launchpad</Link>
                                    <hr></hr>
                                </div>
                            </div>

                            <div className='row text-center pt-2 pb-2'>
                                <div className='col-md-8 mx-auto'>
                                    <h4 className='fw-700'>Lending and Borrowing</h4>
                                    <p>Many people make good money by holding onto their cryptocurrencies and wait for their prices to go up before cashing.....</p>
                                    <Link className="btn outlines_btn mb-3" to="/lendandborrow">EdaFace Lending</Link> <Link className="btn btn-brand-1 sitebtn ms-0 ms-sm-4 hover-up mb-3" to="/lendandborrow">EdaFace Borrowing</Link>
                                    <hr></hr>
                                </div>
                            </div>

                            <div className='row trendlist text-center pt-2 pb-3'>
                                <img src={Images.backlogo} className='Joinlogo' />
                                <div className='col-md-8 mx-auto'>
                                    <h4 className='fw-400'>Lorem ipsum</h4>
                                    <h4 className='fw-700'>Join Us Lorem Ipsum</h4>
                                    <p>Lorem ipsum dolor sit amet consectetur. Diam enim commodo aliquet dictum tempus.</p>
                                    <div className='row'>
                                        <div className='col-4 col-md-4 position-relative mt-2' data-aos="zoom-out" data-aos-duration="1800">
                                            <a className='ataglist' target='_blank' href='https://www.facebook.com/EdaFace.Office1'>
                                                <img src={Images.fb} className='img-fluid w-30 mb-2' />
                                                {/* <img src={ellipse} className='ellipsee' /> */}
                                                <p>Facebook</p></a>
                                        </div>
                                        <div className='col-4 col-md-4 position-relative mt-2' data-aos="zoom-out" data-aos-duration="1800">
                                            <a className='ataglist' target='_blank' href='https://www.reddit.com/'>
                                                <img src={Images.gits} className='img-fluid w-30 mb-2' />
                                                {/* <img src={ellipse} className='ellipsee' /> */}
                                                <p>Reddit</p></a>
                                        </div>
                                        <div className='col-4 col-md-4 position-relative mt-2' data-aos="zoom-out" data-aos-duration="1800">
                                            <a className='ataglist' target='_blank' href='https://t.me/+8O9tfHSRdEZkY2E0'>
                                                <img src={Images.tele} className='img-fluid w-30 mb-2' />
                                                {/* <img src={ellipse} className='ellipsee' /> */}
                                                <p>Telegram</p></a>
                                        </div>

                                    </div>
                                    <hr></hr>
                                </div>
                            </div>

                            <div className='row text-center pt-0 pb-5'>
                                <div className='col-md-12 mx-auto'>
                                    <h4 className='fw-400'>Lorem ipsum</h4>
                                    <h4 className='fw-700'>Partners and stakeholders</h4>
                                    <div className='partnerborder position-relative mt-4'>
                                        <img src={Images.backlogo} className='bottomlogo' />
                                        <div className='row row-cols-lg-5 row-cols-md-3 row-cols-2 align-items-center'>
                                            <div className='col'>
                                                <img src={Images.partner1} className='img-fluid w-70 mb-2' />
                                            </div>
                                            <div className='col'>
                                                <img src={partner2} className='img-fluid w-70 mb-2' />
                                            </div>
                                            <div className='col'>
                                                <img src={partner3} className='img-fluid w-70 mb-2' />
                                            </div>
                                            <div className='col'>
                                                <img src={Images.partner4} className='img-fluid w-70 mb-2' />
                                            </div>
                                            <div className='col'>
                                                <img src={Images.partner5} className='img-fluid w-70 mb-2' />
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>



                        </div>

                    </div>


                </div>


                <Footer />

            </div>



        </>
    )
}

export default Landingpage;

