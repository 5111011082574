import React, { useState, useEffect } from "react";
import { Modal, Col, Container, Dropdown, Row, DropdownToggle, DropdownMenu, DropdownItem } from "react-bootstrap";
import Images from "../Images";
import { IoClose } from "react-icons/io5";
import { BsQuestionCircle } from "react-icons/bs";

function Tokenmodallists(props) {

    const [tokenmodallist, setTokenmodallist] = useState(true);
    const [coinlists, setCoinlists] = useState([
        {
            id: 1, image: Images.eth, coinname: "WETH"
        },
        {
            id: 2, image: Images.dai, coinname: "DAI"
        },
        {
            id: 3, image: Images.usdc, coinname: "USDC"
        },
        {
            id: 4, image: Images.bal, coinname: "BAL"
        },
        {
            id: 5, image: Images.snx, coinname: "SNX"
        },
    ]);
    return (
        <>
            <div className='walletmodal'>

                <Modal show={tokenmodallist} size="md" aria-labelledby="contained-modal-title-vcenter" centered >
                    <Modal.Body className="py-4 px-3">
                        <div className="text-end mb-4"><button className='btn close me-0' onClick={() => props.onDismiss()}><IoClose /></button></div>
                        <h5 className="themeclr fw-600 mb-4">Select a Token</h5>
                        <div className="pos mb-3">
                            <input type="search" className="form-control token_input" placeholder="Search name or paste address" />
                            <img src={Images.searchicon} className="img-fluid search" />
                        </div>
                        <p className="fw-500">Common Bases  <BsQuestionCircle /></p>
                        <div className="d-flex justify-content-center gap-2 mb-3">
                        {props.types == 1 ?
                            coinlists.slice(0,4).map((e, i) =>
                                    <button className="btn bbtn rounded-3"><img src={e.image} className="img-fluid ethimg" /> {e.coinname}</button>) :
                            coinlists.slice(0,4).filter(s => s.coinname != props.changefirst.coinname).map((e, i) =>
                                    <button className="btn bbtn rounded-3"><img src={e.image} className="img-fluid ethimg" /> {e.coinname}</button>)
                        }</div>

                        {props.types == 1 ?
                            coinlists.map((e, i) =>
                                <div className="d-flex justify-content-between py-2 px-2 nethover" onClick={() => { props.setChangefirst(e); props.onDismiss() }}>
                                    <div className="d-flex">
                                        <img src={e.image} className="imf-fluid ethimg me-2" />
                                        <div><h6 className="themeclr fw-600 mb-1">{e.coinname}</h6><p className="themeclr mb-0">Etherum</p></div></div>
                                    <p className="themeclr mb-0">0.00</p>
                                </div>) :
                            coinlists.filter(s => s.coinname != props.changefirst.coinname).map((e, i) =>
                                <div className="d-flex justify-content-between py-2 px-2 nethover" onClick={() => { props.setChangesecond(e); props.onDismiss() }}>
                                    <div className="d-flex">
                                        <img src={e.image} className="imf-fluid ethimg me-2" />
                                        <div><h6 className="themeclr fw-600 mb-1">{e.coinname}</h6><p className="themeclr mb-0">Etherum</p></div></div>
                                    <p className="themeclr mb-0">0.00</p>
                                </div>)
                        }
                    </Modal.Body>
                </Modal>
            </div>
        </>
    )
}

export default Tokenmodallists