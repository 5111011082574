import React, { useState, useEffect } from "react";
import { Modal, Col, Container, Dropdown, Row, DropdownToggle, DropdownMenu, DropdownItem } from "react-bootstrap";
import Images from "../Images";
import { IoClose } from "react-icons/io5";
import { BsQuestionCircle } from "react-icons/bs";
import Switch from "react-switch";
import { BsFillInfoCircleFill } from "react-icons/bs";
import { IoWarningOutline } from "react-icons/io5";

function Settingsmodal(props) {

    const [settingmodals, setSettingmodals] = useState(true);
    const [checked, setChecked] = useState(false);
    const handleChange = nextChecked => {

        setChecked(nextChecked);
    };
    { console.log("ghgh", checked); }
    const [checked1, setChecked1] = useState(false);
    const handleChange1 = nextChecked => {
        setChecked1(nextChecked);
    };
    return (
        <>
            <div className='walletmodal'>
                <Modal show={settingmodals} size="md" aria-labelledby="contained-modal-title-vcenter" centered >
                    <Modal.Body className="py-4 px-3">
                        <div className="text-end mb-2"><button className='btn close me-0' onClick={() => props.onDismiss()}><IoClose /></button></div>

                        {checked == false ?
                            <>
                                <h5 className="themeclr fw-600 mb-4">Settings</h5>
                                <p className="orgclr fw-500">SWAPS & LIQUIDITY</p>

                                <p className="blkclr fw-500">Default Transaction Speed </p>
                                <div className="d-flex align-items-center defaultall gap-3 mb-4">
                                    <button className="btn bbtn rounded-3 px-2">Default</button>
                                    <button className="btn bbtn rounded-3 px-2">Standard (1)</button>
                                    <button className="btn bbtn rounded-3 px-2">Fast (4)</button>
                                    <button className="btn bbtn rounded-3 px-2">Instant (2)</button>
                                </div>


                                <p className="fw-500">Slippage Tolerance  <BsQuestionCircle /></p>
                                <div className="d-flex align-items-center gap-2 mb-4">
                                    <button className="btn bbtn rounded-3 px-2 px-sm-3">0.1 %</button>
                                    <button className="btn bbtn rounded-3 px-2 px-sm-3">0.5 %</button>
                                    <button className="btn bbtn rounded-3 px-2 px-sm-3">1.0 %</button>
                                    <input type="text" className="form-control input_spot small_input rounded-3" placeholder="0.01" />%
                                </div>

                                <div className="d-flex justify-content-between align-items-center mb-3"><p className="themeclr fw-500 mb-0">Tx deadline (mins) <BsQuestionCircle /></p><input type="text" className="form-control input_spot small_input rounded-3" placeholder="1" /></div>
                                <div className="d-flex justify-content-between align-items-center mb-3"><p className="themeclr fw-500 mb-0">Expert Mode <BsQuestionCircle /></p>                            <Switch
                                    onChange={handleChange}
                                    checked={checked}
                                    offColor="#162019"
                                    onColor="#1888b7"
                                    offHandleColor="#ffff"
                                    onHandleColor="#00e7fa"
                                    height={20}
                                    width={36}
                                    handleDiameter={18}
                                    uncheckedIcon={false}
                                    checkedIcon={false}
                                    className="react-switch"
                                    id="material-switch"
                                /></div>
                                <div className="d-flex justify-content-between align-items-center"><p className="themeclr fw-500 mb-0">Flippy sounds
                                    <BsQuestionCircle /></p><Switch
                                        onChange={handleChange1}
                                        checked={checked1}
                                        offColor="#162019"
                                        onColor="#1888b7"
                                        offHandleColor="#ffff"
                                        onHandleColor="#00e7fa"
                                        height={20}
                                        width={36}
                                        handleDiameter={18}
                                        uncheckedIcon={false}
                                        checkedIcon={false}
                                        className="react-switch"
                                        id="material-switch"
                                    /></div>
                                <button className="btn btn-brand-1 sitebtn hover-up w-100 mt-4">Save</button>
                            </> :
                            <div>
                                <button className="btn btn-brand-1 sitebtn hover-up mb-3" onClick={() => setChecked(false)}>Back</button>
                                <h5 className="themeclr fw-600 mb-4">Expert Mode</h5>
                                <div className="marketbor rounded-4 w-100 py-3 px-3 mt-3">
                                    <p className="themeclr mb-0"><IoWarningOutline className="orgclr" /> Expert mode turns off the 'Confirm' transaction prompt, and allows high slippage trades that often result in bad rates and lost funds.</p>
                                </div>
                                <p className="themeclr fw-600 mt-3">Only use this mode if you know what you’re doing.</p>
                                <div class="form-check mb-2">
                                    <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                    <label class="form-check-label orgclr" for="flexCheckDefault">
                                        Dont show this again
                                    </label>
                                </div>

                                <button className="btn btn-brand-1 sitebtn hover-up rounded-3 w-100">Turn on expert mode</button>
                                <button className="btn btn-brand-1 sitebtn hover-up rounded-3 w-100 mt-3" onClick={() => setChecked(false)}>Cancel</button>
                            </div>
                        }


                    </Modal.Body>
                </Modal>
            </div>
        </>

    )
}

export default Settingsmodal;