import React, { useState } from "react";
import Innerheader from "../Layouts/innerheader";
import Footer from "../Layouts/footer";
import { Container, Form, InputGroup } from "react-bootstrap";
import "../Karthick.css";
import "../inner.css";
import Images from "../Components/Images";
import { GoMail } from "react-icons/go";
import { MdOutlineLock } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";

const SubmitRequest = () => {

    const [submitstep, setSubmitstep] = useState(1);
    const [btndisable, setBtndisable] = useState(true);

    const navigate = useNavigate();

    const nextpagefunc = () => {
        navigate("/submission-form");
    }

  return (
    <>
        <Innerheader />
            <Container fluid className="innerpage pools_sec assetpage px-0 pos">
                <Container className="pt-5">
                    <img src={Images.backlogo} className='backlogo farms__backlogo' />
                    <div className="cointoken submitrequest">
                        { submitstep == 1 && 
                            <div className="formbox formboxcenter">
                                <div className="text-center">
                                    <h6 class="formhead">Submit a request</h6>
                                    <p className="content">
                                        Please provide the following details
                                    </p>
                                </div>
                                <Form>
                                    <InputGroup className="mb-4">
                                        <InputGroup.Text><GoMail /></InputGroup.Text>
                                        <Form.Control type="text" placeholder="Company email address"></Form.Control>
                                    </InputGroup>
                                    <InputGroup className="mb-4">
                                        <InputGroup.Text><GoMail /></InputGroup.Text>
                                        <Form.Control type="text" placeholder="Domain name"></Form.Control>
                                    </InputGroup>
                                    <InputGroup className="mb-4">
                                        <InputGroup.Text><MdOutlineLock /></InputGroup.Text>
                                        <Form.Control type="text" placeholder="Password"></Form.Control>
                                    </InputGroup>
                                    <div className="flexbox justify-content-between mb-4">
                                        <div>
                                            <div className="d-flex align-items-center">
                                                <Form.Check type="checkbox"></Form.Check>
                                                <Form.Label>
                                                    <span className="content noteblue d-block">
                                                        <b>Submit New Project</b>
                                                    </span>
                                                    (Coin or Token)
                                                </Form.Label>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="d-flex align-items-center">
                                                <Form.Check type="checkbox"></Form.Check>
                                                <Form.Label>
                                                    <span className="content noteblue d-block">
                                                        <b>Upgrade Listed Project</b>
                                                    </span>
                                                    (Coin or Token)
                                                </Form.Label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-5 text-center">
                                        <button type="button" className="btn btn-brand-1 sitebtn hover-up formbtn w-100" onClick={() => setSubmitstep(2)}>Next</button>
                                    </div> 
                                </Form>
                            </div>
                        }           
                        { submitstep == 2 && 
                            <div className="formbox formboxcenter">
                                <div className="text-center">
                                    <h6 class="formhead">Accepts Legal Requirements</h6>
                                    <p className="content">
                                        Please, review all the legal requirements before you proceed further
                                    </p>
                                </div>
                                <Form>
                                    <div>
                                        <div className="d-flex align-items-start mt-5">
                                            <Form.Check type="checkbox" onClick={() => setBtndisable(!btndisable)}></Form.Check>
                                            <Form.Label>
                                                <span className="content d-block">
                                                    I have read and I agree to all the <Link to="/" className="content noteblue">Terms of Use, Disclaimers, Listing Terms and Conditions, Privacy Policy</Link>, and all legal requirements of EDA
                                                </span>
                                            </Form.Label>
                                        </div>
                                    </div>
                                    <div className="mt-5 text-center">
                                        <button type="button" className="btn btn-brand-1 sitebtn hover-up formbtn w-100" disabled={btndisable} onClick={nextpagefunc}>Next</button>
                                    </div> 
                                </Form>
                            </div>
                        }             
                    </div>
                </Container>
            </Container>
        <Footer />
    </>
  )
}

export default SubmitRequest;