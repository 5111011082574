import React, { useState, useEffect } from "react";
import Innerheader from "../Layouts/innerheader";
import Footer from "../Layouts/footer";
import { Accordion, Col, Container, Dropdown, Row } from "react-bootstrap";
import "../Karthick.css";
import "../inner.css";
import Images from "../Components/Images";
import Hetrotype from "../Components/hetrotype";
import { isEmpty } from '../Lib/common';

function Pooltypepage() {

    const [typeactive, setTypeactive] = useState("hetro");
    const handletype = (tab) => {
        setTypeactive(tab)
    };
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    }, []);
    return (

        <div>
            <Innerheader />
            <Container fluid className="innerpage pools_sec assetpage px-0 pos">
                <Container className="pt-5">
                    <img src={Images.backlogo} className='backlogo farms__backlogo' />

                    <h3 className="blkclr mb-4">Select MIM Pool type</h3>
                    <div className="d-block text-center d-sm-flex">
                        <div className={typeactive === "hetro" ? "mimtype active text-center py-3 px-3 me-sm-3 mt-3 mt-sm-0" : "mimtype text-center py-3 px-3 me-sm-3 mt-3 mt-sm-0"} onClick={()=> setTypeactive("hetro")}>
                            <img src={Images.ticksss} className='ticksss t1 img-fluid' alt="image" />
                            <img src={Images.ticksss1} className='ticksss t2 img-fluid' alt="image" />
                            <h6 className="themeclr mt-3">Heterogenized MIM Pool</h6>
                        </div>
                        <div className={typeactive === "homo" ? "mimtype active text-center py-3 px-3 me-sm-3 mt-3 mt-sm-0" : "mimtype text-center py-3 px-3 me-sm-3 mt-3 mt-sm-0"} onClick={()=> setTypeactive("homo")}>
                            <img src={Images.ticksss} className='ticksss t1 img-fluid' alt="image" />
                            <img src={Images.ticksss1} className='ticksss t2 img-fluid' alt="image" />
                            <h6 className="themeclr mt-3">Homogenized MIM Pool</h6>
                        </div>
                    </div>

                    <div>
                        {typeactive === "hetro" &&
                            <>
                                <Hetrotype />
                            </>
                        }
                        {typeactive === "homo" &&
                            <>
                                <Hetrotype />
                            </>
                        }
                    </div>


                </Container>
            </Container>

            <Footer />
        </div>

    )
}

export default Pooltypepage;