import React, { useState } from "react";
import Innerheader from "../Layouts/innerheader";
import Footer from "../Layouts/footer";
import { Col, Container, Row, Badge, Stack } from "react-bootstrap";
import "../Karthick.css";
import "../inner.css";
import { useLocation } from "react-router-dom";
import { IoMdCheckmarkCircle } from "react-icons/io";
import { HiMiniDocumentText } from "react-icons/hi2";
import { CgWebsite } from "react-icons/cg";
import Images from "../Components/Images";
import { useNavigate } from "react-router-dom";
import { MdKeyboardArrowLeft } from "react-icons/md";


const LaunchpadDetails = () => {

  const navigate = useNavigate();
  const location = useLocation();
  const datas = location.state;

  const [coinlist] = useState([
    {
      hardcap: "2,500,000 USD",
      totalsupply: "1,000,000,000",
      initialsupply: "15%",
      publicsale: "0.05 USD",
      tokensoffer: "50,000,000",
      hardcapuser: "15,000 USD",
      tokensalevesting: "No lockup",
      tokentype: "ERC20",
      tokendist: "After the end of token sale",
    },
  ]);
  // useEffect(() => {
  //   window.scrollTo({
  //     top: 0,
  //     left: 0,
  //     behavior: "smooth",
  //   });
  // }, []);

  return (
    <>
      <Innerheader />
      <div className="launchpad">
        <img src={Images.backlogo} className="backlogo farms__backlogo" />
        <div className="swap__page position-relative pb-5">
          <Container>
            <div onClick={() => navigate(-1)} className="pt-3"><button class="btn btn-brand-1 sitebtn hover-up mt-2 mb-1"><MdKeyboardArrowLeft className="set_icon mts" />Back</button></div>
            <div className="launchpaddetail pt-2 pt-sm-4">
              <Row className="align-items-center">
                <Col xs={12} sm={12} md={12} lg={3} className="mb-4 mb-lg-0">
                  <img
                    src={datas.launchimg}
                    alt={datas.cname}
                    className="launchimg"
                  />
                </Col>
                <Col xs={12} sm={12} md={12} lg={9}>
                  <div className="launchrightbox">
                    <Row>
                      <Col xs={12} sm={12} md={12} lg={8} className="mb-4 mb-lg-0">
                        <div className="d-flex align-items-center gap-3 mb-3">
                          <p className="cname mb-0">{datas.name}</p>
                          <Badge bg="secondary">
                            <IoMdCheckmarkCircle /> Finished
                          </Badge>
                        </div>
                        <p className="blkclr fw-400 mb-1">{datas.launchdesc}</p>
                      </Col>
                      <Col xs={12} sm={12} md={12} lg={4}>
                        <div className="text-lg-end">
                          <p className="lboxlabel mb-2">End Time:</p>
                          <p className="lboxlabel fw-500">{datas.endtime}</p>
                        </div>
                      </Col>
                    </Row>

                    <Stack direction="horizontal" gap={3} className="mt-2 mt-sm-4">
                      <Badge bg="secondary">
                        <CgWebsite /> Website
                      </Badge>
                      <Badge bg="secondary">
                        <HiMiniDocumentText /> Whitepaper
                      </Badge>
                    </Stack>
                  </div>
                </Col>
              </Row>
              <div className="detailsbox">
                <div>
                  <p className="lboxlabel mb-1 themeclr fw-500">Type</p>
                  <p className="lboxlabel fw-500 mb-0 text-capitalize">
                    {datas.type}
                  </p>
                </div>
                <div>
                  <p className="lboxlabel mb-1 themeclr fw-500">Sale Price</p>
                  <p className="lboxlabel fw-500 mb-0">
                    1 <span className="text-uppercase">{datas.cname}</span> ={" "}
                    {datas.saleprice}
                    <span className="text-uppercase ps-1">{datas.cname}</span>
                  </p>
                </div>
                <div>
                  <p className="lboxlabel mb-1 themeclr fw-500">Tokens Offered</p>
                  <p className="lboxlabel fw-500 mb-0">
                    {datas.tokenoffer}
                    <span className="text-uppercase ps-1">{datas.cname}</span>
                  </p>
                </div>
                <div>
                  <p className="lboxlabel mb-1 themeclr fw-500">
                    Single Initial Investment
                  </p>
                  <p className="lboxlabel mb-0 fw-500">
                    {datas.singleiin}
                    <span className="text-uppercase ps-1">{datas.cname}</span>
                  </p>
                </div>
                <div>
                  <p className="lboxlabel mb-1 themeclr fw-500">
                    Hard cap per user
                  </p>
                  <p className="lboxlabel mb-0 fw-500">
                    {datas.hardcap}
                    <span className="text-uppercase ps-1">{datas.cname}</span> =
                    {datas.hardcaptwo}
                  </p>
                </div>
              </div>
              <div className="paradetail py-4">
                {/* <p className="content chead">
                  <span className="text-uppercase">{datas.cname}</span> - Lorem
                  ipsum dolor sit amet consectetur adipisicing elit. Deleniti
                  debitis repellendus eaque iste eveniet nulla
                </p> */}
                <div className="paradetailbox">
                  <div>
                    <h6 className="themeclr mb-2 fw-500">Project Introduction</h6>
                    <p className="blkclr mb-2">
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Natus ex, quas adipisci hic perspiciatis placeat eveniet
                      odio recusandae cumque corrupti ipsum incidunt et
                      consequuntur impedit beatae officia assumenda dolor
                      perferendis.quas adipisci hic perspiciatis placeat eveniet
                      odio recusandae cumque corrupti ipsum incidunt et.cumque corrupti ipsum incidunt et
                      consequuntur impedit beatae officia assumenda dolor
                      perferendis.There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.<br></br><br></br>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Natus ex, quas adipisci hic perspiciatis placeat eveniet
                      odio recusandae cumque corrupti ipsum incidunt et
                      consequuntur impedit beatae officia assumenda dolor
                      perferendis.
                    </p>
                  </div>
                  <div>
                    <h6 className="themeclr fw-500">Key Features and Highlights</h6>
                    <p className="blkclr mb-2">
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Natus ex, quas adipisci hic perspiciatis placeat eveniet
                      odio recusandae cumque corrupti ipsum incidunt et
                      consequuntur impedit beatae officia assumenda dolor
                      perferendis.quas adipisci hic perspiciatis placeat eveniet
                      odio recusandae cumque corrupti ipsum incidunt et.cumque corrupti ipsum incidunt et
                      consequuntur impedit beatae officia assumenda dolor
                      perferendis.There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.<br></br><br></br>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Natus ex, quas adipisci hic perspiciatis placeat eveniet
                      odio recusandae cumque corrupti ipsum incidunt et
                      consequuntur impedit beatae officia assumenda dolor
                      perferendis.
                    </p>

                  </div>
                  <div>
                    <h6 className="themeclr">
                      <span className="text-uppercase fw-600">{datas.cname} Token
                        Sale and Economics</span>                      
                    </h6>
                    <div className="launchdetlist mt-4 mb-0">
                      {coinlist.map((cl) => (
                        <>
                          <Row>
                            <Col xs={12} md={6} lg={3}>
                              <span className="themeclr mb-2 mb-sm-0 d-block">Hard Cap:</span>
                            </Col>
                            <Col xs={12} md={6} lg={9}>
                              <span className="blkclr fw-500">{cl.hardcap}</span>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={12} md={6} lg={3}>
                              <span className="themeclr mb-2 mb-sm-0 d-block">
                                Total Token Supply:
                              </span>
                            </Col>
                            <Col xs={12} md={6} lg={9}>
                              <span className="blkclr fw-500">{cl.totalsupply}</span>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={12} md={6} lg={3}>
                              <span className="themeclr mb-2 mb-sm-0 d-block">
                                Initial Circulating Supply:
                              </span>
                            </Col>
                            <Col xs={12} md={6} lg={9}>
                              <span className="blkclr fw-500">
                                {cl.initialsupply} of Total Token Supply
                              </span>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={12} md={6} lg={3}>
                              <span className="themeclr mb-2 mb-sm-0 d-block">
                                Public Sale Token Price:
                              </span>
                            </Col>
                            <Col xs={12} md={6} lg={9}>
                              <span className="blkclr fw-500">
                                {cl.publicsale} (price in BNB will be determined
                                prior to the start of subscription)
                              </span>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={12} md={6} lg={3}>
                              <span className="themeclr mb-2 mb-sm-0 d-block">Tokens Offered:</span>
                            </Col>
                            <Col xs={12} md={6} lg={9}>
                              <span className="blkclr fw-500">
                                {cl.tokensoffer} {datas.cname}
                              </span>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={12} md={6} lg={3}>
                              <span className="themeclr mb-2 mb-sm-0 d-block">
                                Hard Cap Per User:
                              </span>
                            </Col>
                            <Col xs={12} md={6} lg={9}>
                              <span className="blkclr fw-500">
                                {cl.hardcap} (price in BNB will be determined
                                prior to the start of subscription)
                              </span>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={12} md={6} lg={3}>
                              <span className="themeclr mb-2 mb-sm-0 d-block">
                                Token Sale Vesting Period:
                              </span>
                            </Col>
                            <Col xs={12} md={6} lg={9}>
                              <span className="blkclr fw-500">
                                {cl.tokensalevesting}
                              </span>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={12} md={6} lg={3}>
                              <span className="themeclr mb-2 mb-sm-0 d-block">Token Type:</span>
                            </Col>
                            <Col xs={12} md={6} lg={9}>
                              <span className="blkclr fw-500">{cl.tokentype}</span>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={12} md={6} lg={3}>
                              <span className="themeclr mb-2 mb-sm-0 d-block">
                                Token Distribution:
                              </span>
                            </Col>
                            <Col xs={12} md={6} lg={9}>
                              <span className="blkclr fw-500">{cl.tokendist}</span>
                            </Col>
                          </Row>
                        </>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default LaunchpadDetails;
