import React, { useState, useEffect } from "react";
import Innerheader from "../Layouts/innerheader";
import Footer from "../Layouts/footer";
import { Accordion, Col, Table, Container, Dropdown, DropdownMenu, DropdownToggle, Row } from "react-bootstrap";
import "../Karthick.css";
import "../inner.css";
import { Link } from "react-router-dom";
import Images from "../Components/Images";
import { FiSearch } from "react-icons/fi";
import { PiWarningCircle } from "react-icons/pi";
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';

function Poolsbalance() {

    const[poolslist, setPoollist] = useState([
        {id:1},{id:2},{id:3},{id:4}
    ]);
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    }, []);

    return (

        <div>

            <Innerheader />

            <Container fluid className="innerpage px-0">
                <div className="poolspage py-5 px-3">
                    <div className="row mx-1">
                        <div className="col-lg-6 mx-auto">
                            <div className="poolshead py-4 px-4 my-5">
                                <PiWarningCircle className="warning" />
                                <h4 className="text-center blkclr mb-3 fw-300">My EDA Balance :</h4>
                                <h1 className="text-center themeclr fw-600">$0.000000000</h1>
                                <div className="text-end"><button className="btn btn-brand-1 sitebtn hover-up rounded-2 mt-2"><FiSearch />  Search</button></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="pools_sec py-5">
                    <Container>
                        <h2 className="blkclr pb-3">My liquidity in Balancer pools</h2>

                        <p className="themeclr fw-500 mt-4">Unstaked pools</p>
                        <div className="pools_table py-4 px-4 mt-2">
                            <Table responsive>
                                <thead>
                                    <tr>
                                        <th><img src={Images.doubcoin} className="img-fluid" /></th>
                                        <th>Composition</th>
                                        <th>My Balance</th>
                                        <th>Poor Value</th>
                                        <th>My APR</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody className="t_body">
                                {poolslist.map((e,i)=>
                                    <tr>
                                        <td>   <AvatarGroup max={3}>
                                            <Avatar alt="Remy Sharp" src={Images.eth} />
                                            <Avatar alt="Travis Howard" src={Images.dai} />
                                            <Avatar alt="Cindy Baker" src={Images.usdc} />
                                            <Avatar alt="Agnes Walker" src={Images.bal} />
                                            <Avatar alt="Trevor Henderson" src={Images.snx} />
                                        </AvatarGroup></td>
                                        <td>
                                            <button className="btn per_btn ms-2 mt-3">BAL 80%</button>
                                            <button className="btn per_btn ms-2 mt-3">WETH 80%</button>
                                        </td>
                                        <td><p className="themeclr mb-0 fw-500">$1,12,412</p></td>
                                        <td><p className="themeclr mb-0 fw-500">$108,90</p></td>
                                        <td><p className="themeclr mb-0 fw-500">0.96% <img src={Images.apr} className="img-fluid" /></p></td>
                                        <td><p className="themeclr mb-0 fw-500">View</p></td>
                                    </tr>)}
                                </tbody>
                            </Table>





                            
                        </div>

                        <p className="themeclr fw-500 mt-4">Staked pools</p>
                        <div className="pools_table py-4 px-4 mt-2">
                            <Table responsive>
                                <thead>
                                    <tr>
                                        <th><img src={Images.doubcoin} className="img-fluid" /></th>
                                        <th>Composition</th>
                                        <th>My Balance</th>
                                        <th>Poor Value</th>
                                        <th>My APR</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody className="t_body">
                                {poolslist.map((e,i)=>
                                    <tr>
                                        <td>   <AvatarGroup max={3}>
                                            <Avatar alt="Remy Sharp" src={Images.eth} />
                                            <Avatar alt="Travis Howard" src={Images.dai} />
                                            <Avatar alt="Cindy Baker" src={Images.usdc} />
                                            <Avatar alt="Agnes Walker" src={Images.bal} />
                                            <Avatar alt="Trevor Henderson" src={Images.snx} />
                                        </AvatarGroup></td>
                                        <td>
                                            <button className="btn per_btn ms-2 mt-3">BAL 80%</button>
                                            <button className="btn per_btn ms-2 mt-3">WETH 80%</button>
                                        </td>
                                        <td><p className="themeclr mb-0 fw-500">$1,12,412</p></td>
                                        <td><p className="themeclr mb-0 fw-500">$108,90</p></td>
                                        <td><p className="themeclr mb-0 fw-500">0.96% <img src={Images.apr} className="img-fluid" /></p></td>
                                        <td><p className="themeclr mb-0 fw-500">View</p></td>
                                    </tr>)}
                                </tbody>
                            </Table>                     
                        </div>

                    </Container>
                </div>


            </Container>

            <Footer />
        </div>

    )
}

export default Poolsbalance