import React, {useState} from 'react';
import Marquee from "react-fast-marquee";
import Images from './Images';

export default function Announcement() {
    const [parnetcard, setpartnercard] = useState([
        { "id": 1 }, { "id": 2 }, { "id": 3 }, { "id": 4 }, { "id": 5 }, { "id": 6 }, { "id": 7 }
    ]);
    return (
        <div className='silderss slidepad mb-2 mb-sm-4'>
            <Marquee speed={80} direction='left'>
                <div className="partnermarquee">
                    {parnetcard.map((e, i) =>
                        <div className="partnerwidth">
                            <p className='mb-0'>Lorem Ipsum</p>
                        </div>
                    )}
                </div>
            </Marquee>
            <img src={Images.backlogo} className='backlogo' />
            <img src={Images.elogo} className='elogo' />
        </div>
    )
}
