import React, { useState, useEffect } from "react";
import Innerheader from "../Layouts/innerheader";
import Footer from "../Layouts/footer";
import { Col, Table, Container, Dropdown, DropdownMenu, DropdownToggle, Row, DropdownItem } from "react-bootstrap";
import "../Karthick.css";
import "../inner.css";
import { Link } from "react-router-dom";
import Images from "../Components/Images";
import { FaSort } from "react-icons/fa";
import { BsThreeDots } from "react-icons/bs";
import { BsInfoCircleFill } from "react-icons/bs";
import Borrowmodals from "./Modals/borrowmodal";

function Borrowtable(props) {

    const [borrowmodal, setBorrowmodal] = useState(false);
    const [coinlists, setCoinlists] = useState([
        {
            id: 1, image: Images.eth, names: "ETH", balance: "0", apy: "0.01", tick: Images.tick,
        },
        {
            id: 2, image: Images.dai, names: "DAI", balance: "1", apy: "0.01", tick: Images.tick,
        },
        {
            id: 3, image: Images.usdc, names: "USDC", balance: "0", apy: "0.01", tick: Images.tick,
        },
        {
            id: 4, image: Images.bal, names: "BAL", balance: "0", apy: "0.01", tick: Images.tick,
        },
        {
            id: 5, image: Images.snx, names: "SNX", balance: "0", apy: "0.01", tick: Images.tick,
        },
    ]);
    return (
        <>
        {borrowmodal && <Borrowmodals onDismiss={()=>setBorrowmodal(false)} setIsborrowapplied={(e)=>props.setIsborrowapplied(e)}/>}
        <div className="supplytable mt-3">
            <div className="allgrids pt-1 mb-3">
                <div className="d-flex align-items-center mt-3">
                 <img src={Images.dexlogo} className="img-fluid me-2" /><p className="blkclr mb-0 fw-500">DEX</p>
                </div>
                <div className="mt-3">
                <p className="themeclr mb-0">Available <BsInfoCircleFill /></p> 
                <p className="themeclr mb-0">0</p> 
                </div>
                <div className="mt-3">
                <p className="themeclr mb-0">APY, borrow rate <BsInfoCircleFill /></p> 
                <p className="themeclr mb-0">3.75 - 5.36%</p> 
                </div>
                <div className="d-flex align-items-center mt-3">
                    <button className="btn btn-brand-1 sitebtn hover-up me-2" onClick={()=>setBorrowmodal(true)}>Borrow</button>
                    <button className="supplybtn me-2">Details</button>
                </div>
              
            </div>
            <Table responsive>
                <thead>
                    <tr>
                        <th>Assets <FaSort /></th>
                        <th>Available <BsInfoCircleFill /> <FaSort /></th>
                        <th>APY, Variable <BsInfoCircleFill /> <FaSort /></th>
                        <th></th>
                    </tr>
                </thead>
                <tbody className="t_body">
                    {coinlists.map((e, i) =>
                        <tr>
                            <td className="pos"><img src={e.image} className="img-fluid wbox" /><span className="themeclr ms-2">{e.names}</span></td>
                            <td><p className="themeclr mb-0">{e.balance}</p></td>
                            <td><p className="themeclr mb-0">{e.apy}</p></td>
                            <td>
                                <div className="d-flex align-items-center">
                                    <button className="btn btn-brand-1 sitebtn hover-up me-2" onClick={()=>setBorrowmodal(true)}>Borrow</button>
                                    <button className="supplybtn me-2">Details</button>
                                </div> </td>
                        </tr>
                    )}
                </tbody>
            </Table>
        </div>
        </>
    )
}

export default Borrowtable;