import React, { useState, useEffect } from "react";
import Innerheader from "../Layouts/innerheader";
import Footer from "../Layouts/footer";
import {
    Container,
    Dropdown,
    InputGroup,
    Form,
    Table,
    Row,
    Col,
} from "react-bootstrap";
import "../Karthick.css";
import "../inner.css";
import Images from "../Components/Images";
import { MdKeyboardArrowLeft } from "react-icons/md";
import { useNavigate } from "react-router-dom";


function P2padvertiserdetail() {
    
    const navigate = useNavigate();
    const [feedstab, setFeedstab] = useState("online")
    const [adstab, setAdstab] = useState("normal");
    useEffect(() => {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      }, []);

    return (

        <>
            <Innerheader />

            <Container fluid className="innerpage pools_sec assetpage px-0 pos">
                <Container className="pt-1 container_max px-xxl-3">
                    <img src={Images.backlogo} className="backlogo farms__backlogo" />
                    <div className="p2ptrade pt-2 pt-sm-5">
                        <div className="container px-0 px-sm-3">
                            <div onClick={() => navigate(-1)}><button class="btn btn-brand-1 sitebtn hover-up mt-2 mb-4"><MdKeyboardArrowLeft className="set_icon mts"/>Back</button></div>
                            <div className="d-block d-md-flex justify-content-between align-items-center">
                                <div className="d-flex mt-3 mt-md-0">
                                    <div className="pos wd-ad me-2"><img src={Images.eimg} className="img-fluid adimg" alt="eimg" /><span className="onlineround"></span></div>
                                    <div>
                                        <p className="mb-1 blkclr fw-600">Lorem_Ipsum</p>
                                        <span className="blkclr">Online | Joined on 2024-5-24</span>
                                        <div className="d-flex gap-2 mt-2">
                                            <span className="themeclr f-15">Email <img src={Images.tickimg} className="img-fluid mts" /></span>
                                            <span className="themeclr f-15">SMS  <img src={Images.tickimg} className="img-fluid mts" /></span>
                                            <span className="themeclr f-15">ID Verification <img src={Images.tickimg} className="img-fluid mts" /></span>
                                        </div>
                                    </div>
                                </div>

                                <div className="swap__blueBox rounded-3 py-2 px-3 mt-3 mt-md-0">
                                    <p className="mb-1 themeclr fw-500">Positive Feedback</p>
                                    <h5 className="mb-0">98.5%</h5>
                                </div>
                            </div>


                            <div className="gridlist mt-3 mb-3">
                                <div className="chartcard px-3 py-3 rounded-3 mt-3 text-center">
                                    <p className="mb-3 themeclr fw-500">All Trades</p>
                                    <h5>373 Time(s)</h5>
                                    <p className="mb-0 f-15"><span className="grnclr">Buy 54</span> | <span className="redclr">Sell 13</span></p>
                                </div>
                                <div className="chartcard px-3 py-3 rounded-3 mt-3 text-center">
                                    <p className="mb-3 themeclr fw-500">30d Trades</p>
                                    <h5>111 Time(s)</h5>
                                </div>
                                <div className="chartcard px-3 py-3 rounded-3 mt-3 text-center">
                                    <p className="mb-3 themeclr fw-500">30d Completion Rate</p>
                                    <h5>92.2%</h5>
                                </div>
                                <div className="chartcard px-3 py-3 rounded-3 mt-3 text-center">
                                    <p className="mb-3 themeclr fw-500">Avg. Release Time</p>
                                    <h5>2.07 Minute(s)</h5>
                                </div>
                                <div className="chartcard px-3 py-3 rounded-3 mt-3 text-center">
                                    <p className="mb-3 themeclr fw-500">Avg. Pay Time</p>
                                    <h5>3.85 Minute(s)</h5>
                                </div>
                            </div>


                            <div className="mt-5">
                                <ul className="table_tabs overauto mb-0 pb-0">
                                    <li className={feedstab === "online" ? "active" : ""} onClick={() => setFeedstab("online")}>Online Ads</li>
                                    {/* <li className={feedstab === "feedback" ? "active" : ""} onClick={() => setFeedstab("feedback")}>Feedback(0)</li> */}
                                </ul>

                                {feedstab === "online" &&
                                    <div className="mt-3">
                                        <ul class="listtab modes d-flex wrapflex gap-1 pt-3 mb-0">
                                            <li className={adstab === "normal" ? "active ms-0" : "ms-0"} onClick={() => setAdstab("normal")}>Normal (2)</li>
                                            <li className={adstab === "cash" ? "active" : ""} onClick={() => setAdstab("cash")}>Cash (0)</li>
                                            <li className={adstab === "block" ? "active" : ""} onClick={() => setAdstab("block")}>Block (0)</li>
                                            <li className={adstab === "fiat" ? "active" : ""} onClick={() => setAdstab("fiat")}>Fiat (0)</li>
                                        </ul>

                                        {adstab === "normal" &&
                                            <>
                                                <div className="p2ptable">
                                                    <p className="orgclr fw-500 mb-1">Online Buy Ads</p>
                                                    <Table responsive>
                                                        <thead>
                                                            <tr>
                                                                <th>Coin</th>
                                                                <th>Price</th>
                                                                <th>Limit/Available</th>
                                                                <th>Payment</th>
                                                                <th>Trade</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>
                                                                    <div className="flexdata">
                                                                        <img src={Images.usdc} className="img-fluid" />
                                                                        <span>USDC</span>
                                                                    </div>
                                                                </td>
                                                                <td className="fw-500">389.98 DEX</td>
                                                                <td>
                                                                    <div className="availimit">
                                                                        <span className="mb-2 fw-500">387 <small className="text-uppercase ps-1"></small>USDT</span>
                                                                        <span className="fw-500">$20,000 - $34,000</span>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className="availimit">
                                                                        <span className="mb-2 fw-500">Axis Bank</span>
                                                                    </div>
                                                                </td>
                                                                <td>     <button
                                                                    type="button"
                                                                    className="btn buybtn fw-500"
                                                                >
                                                                    Buy
                                                                    <span className="text-uppercase ps-1">
                                                                        USDT
                                                                    </span>
                                                                </button></td>
                                                            </tr>
                                                        </tbody>
                                                    </Table>
                                                    <p className="orgclr fw-500 mb-1 mt-3">Online Sell Ads</p>
                                                    <Table responsive>
                                                        <thead>
                                                            <tr>
                                                                <th>Coin</th>
                                                                <th>Price</th>
                                                                <th>Limit/Available</th>
                                                                <th>Payment</th>
                                                                <th>Trade</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>
                                                                    <div className="flexdata">
                                                                        <img src={Images.usdc} className="img-fluid" />
                                                                        <span>USDC</span>
                                                                    </div>
                                                                </td>
                                                                <td className="fw-500">389.98 DEX</td>
                                                                <td>
                                                                    <div className="availimit">
                                                                        <span className="mb-2 fw-500">387 <small className="text-uppercase ps-1"></small>USDT</span>
                                                                        <span className="fw-500">$20,000 - $34,000</span>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className="availimit">
                                                                        <span className="mb-2 fw-500">Axis Bank</span>
                                                                    </div>
                                                                </td>
                                                                <td>     <button
                                                                    type="button"
                                                                    className="btn sellbtn fw-500"
                                                                >
                                                                    Sell
                                                                    <span className="text-uppercase ps-1">
                                                                        USDT
                                                                    </span>
                                                                </button></td>
                                                            </tr>
                                                        </tbody>
                                                    </Table>
                                                </div>
                                            </>
                                        }

                                        {adstab === "cash" &&
                                            <>
                                                <div className="p2ptable">
                                                    <p className="orgclr fw-500 mb-1">Online Buy Ads</p>
                                                    <Table responsive>
                                                        <thead>
                                                            <tr>
                                                                <th>Coin</th>
                                                                <th>Price</th>
                                                                <th>Limit/Available</th>
                                                                <th>Payment</th>
                                                                <th>Trade</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>

                                                        </tbody>
                                                    </Table>
                                                    <p className="orgclr fw-500 mb-1 mt-3">Online Sell Ads</p>
                                                    <Table responsive>
                                                        <thead>
                                                            <tr>
                                                                <th>Coin</th>
                                                                <th>Price</th>
                                                                <th>Limit/Available</th>
                                                                <th>Payment</th>
                                                                <th>Trade</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>

                                                            </tr>
                                                        </tbody>
                                                    </Table>
                                                </div>
                                            </>
                                        }

                                        {adstab === "block" &&
                                            <>
                                                <div className="p2ptable">
                                                    <p className="orgclr fw-500 mb-1">Online Buy Ads</p>
                                                    <Table responsive>
                                                        <thead>
                                                            <tr>
                                                                <th>Coin</th>
                                                                <th>Price</th>
                                                                <th>Limit/Available</th>
                                                                <th>Payment</th>
                                                                <th>Trade</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>

                                                        </tbody>
                                                    </Table>
                                                    <p className="orgclr fw-500 mb-1 mt-3">Online Sell Ads</p>
                                                    <Table responsive>
                                                        <thead>
                                                            <tr>
                                                                <th>Coin</th>
                                                                <th>Price</th>
                                                                <th>Limit/Available</th>
                                                                <th>Payment</th>
                                                                <th>Trade</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>

                                                            </tr>
                                                        </tbody>
                                                    </Table>
                                                </div>
                                            </>
                                        }

                                        {adstab === "fiat" &&
                                            <>
                                                <div className="p2ptable">
                                                    <p className="orgclr fw-500 mb-1">Online deposit Ads</p>
                                                    <Table responsive>
                                                        <thead>
                                                            <tr>
                                                                <th>Coin</th>
                                                                <th>Price</th>
                                                                <th>Limit/Available</th>
                                                                <th>Payment</th>
                                                                <th>Trade</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>

                                                        </tbody>
                                                    </Table>
                                                    <p className="orgclr fw-500 mb-1 mt-3">Online withdraw Ads</p>
                                                    <Table responsive>
                                                        <thead>
                                                            <tr>
                                                                <th>Coin</th>
                                                                <th>Price</th>
                                                                <th>Limit/Available</th>
                                                                <th>Payment</th>
                                                                <th>Trade</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>

                                                            </tr>
                                                        </tbody>
                                                    </Table>
                                                </div>
                                            </>
                                        }



                                    </div>}

                                {feedstab === "feedback" &&
                                    <>
                                        <div className="pt-3"><p className="text-center mb-0 pb-5 fw-600">No Feedback</p></div>
                                    </>
                                }


                            </div>




                        </div>
                    </div>

                </Container>
            </Container>

            <Footer />
        </>

    )
}

export default P2padvertiserdetail;