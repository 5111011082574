import React, { useState, useEffect } from "react";
import { Modal, Col, Container, InputGroup, DropdownButton, Form, Dropdown, Row, DropdownToggle, DropdownMenu, DropdownItem } from "react-bootstrap";
import Images from "../Components/Images";
import Switch from "react-switch";
import { Slider } from 'rsuite';
// import "rsuite/dist/rsuite.min.css";
import 'rsuite/dist/rsuite-no-reset.min.css';
import { MdKeyboardArrowDown } from "react-icons/md";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { MdOutlineKeyboardArrowRight, MdInfo } from "react-icons/md";
import { FaArrowRightArrowLeft } from "react-icons/fa6";
import { BsFillInfoCircleFill } from "react-icons/bs";
import Chartcard from "./chartcard";


function Gridtab(props) {
    const [gridlistTab, setGridlistTab] = useState('ai');
    const handlegridTabClick = (tab) => {
        setGridlistTab(tab);
    };
    const [topTab, setTopTab] = useState('toproi');
    const handletopTabClick = (tab) => {
        setTopTab(tab);
    };
    const [value1, setValue1] = useState(1);
    const handleStyle1 = {
        color: '#fff',
        fontSize: 12,
        width: 32,
        height: 22
    };
    const labels1 = ['1x', '5x', '10x', '15x', '20x']; // Example labels
    const [chartlist, setChartlist] = useState([
        { id: 1 }, { id: 2 }, { id: 3 }
    ])
    return (
        <>

            <div>
                <ul className="listtab modes d-flex gap-1 mt-2">
                    <li className={gridlistTab === "ai" ? "active fw-600 ms-0" : "ms-0"} onClick={() => handlegridTabClick('ai')}>AI</li>
                    <li className={gridlistTab === "popular" ? "active fw-600" : ""} onClick={() => handlegridTabClick('popular')}>Popular</li>
                    <li className={gridlistTab === "manual" ? "active fw-600" : ""} onClick={() => handlegridTabClick('manual')}>Manual</li>
                </ul>

                <div>

                    {gridlistTab === "ai" &&
                        <>
                            <div className="row">
                                <div className="col-xl-6 mt-4 mt-xl-0">
                                    <OverlayTrigger
                                        placement="top"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={<Tooltip id="button-tooltip">
                                            <div className="py-2 px-1">
                                                Recommended parameters are automatically generated based on technical analysis of the symbol price.
                                            </div>
                                        </Tooltip>}>
                                        <h5 className="themeclr mb-3">1. Parameters <MdInfo /></h5>
                                    </OverlayTrigger>
                                    <div className="d-flex justify-content-between">
                                        <p className="fw-500 mb-2">Time Period</p>
                                        <ul className="d-flex dayslist gap-2 mb-2">
                                            <li className="active">3D</li>
                                            <li>7D</li>
                                            <li>30D</li>
                                            <li>180D</li>
                                        </ul>
                                    </div>
                                    <div className="d-flex justify-content-between"><p className="fw-500 mb-2">Price Range</p><p className="themeclr fw-500 mb-2">5.5335 - 5.7655 USDT</p></div>
                                    <div className="d-flex justify-content-between"><p className="fw-500 mb-2">Grid Number</p><p className="themeclr fw-500 mb-2">27</p></div>
                                    <div className="d-flex justify-content-between"><p className="fw-500 mb-2">Profit/grid(fees deducted)</p><p className="themeclr fw-500 mb-2">0.11% - 0.32%</p></div>
                                    <p className="themeclr fw-500">Copy parameters to Manual settings <MdOutlineKeyboardArrowRight /></p>
                                </div>
                                <div className="col-xl-6 mt-4 mt-xl-0">
                                    <div className="d-flex justify-content-between">
                                        <h5 className="themeclr mb-3">2. Investment <MdInfo /></h5>
                                        <p className="mb-0 fw-500">USDT</p>
                                    </div>
                                    <p className="fw-500">Avbl: <span className="themeclr">0.00 USDT <FaArrowRightArrowLeft /></span></p>
                                    <div className="pos">
                                        <input type="text" className="form-control input_spot text-end" value="0.00" />
                                        <p className="p_pos blkclr mb-0 fw-500">Total</p>
                                        <p className="net_pos themeclr mb-0 fw-500 px-2">USDT</p>
                                    </div>
                                    <div className="py-3 mx-2">
                                        <Slider
                                            min={2}
                                            max={labels1.length + 1}
                                            value={value1}
                                            className="custom-slider"
                                            handleStyle={handleStyle1}
                                            graduated
                                            progress
                                            onChange={(v) => setValue1(v)}
                                            renderMark={(mark) => {
                                                return mark + "x";
                                            }}
                                        /></div>
                                    <button className="btn btn-brand-1 sitebtn w-100 hover-up me-2">trd-sign-disclaimer</button>
                                    <div className="marketbor w-100 py-2 rounded-3 px-2 mt-3 mb-2">
                                        <p className="themeclr mb-0"><BsFillInfoCircleFill /> You are required to sign the Risk Disclaimer Statements before you set up a strategy.</p>
                                    </div>
                                </div>
                            </div>
                        </>
                    }

                    {gridlistTab === "popular" &&
                        <>
                            <ul className="listtab toptab d-flex gap-2 mt-3 mb-0">
                                <li className={topTab === "toproi" ? "active fw-600 ms-0" : "ms-0"} onClick={() => handletopTabClick('toproi')}>Top ROI</li>
                                <li className={topTab === "toppnl" ? "active fw-600" : ""} onClick={() => handletopTabClick('toppnl')}>Top PNL</li>
                                <li className={topTab === "copied" ? "active fw-600" : ""} onClick={() => handletopTabClick('copied')}>Top Copied</li>
                                <li className={topTab === "watched" ? "active fw-600" : ""} onClick={() => handletopTabClick('watched')}>Most Matched</li>
                            </ul>

                            <div>
                                {topTab === "toproi" &&
                                    <>
                                        <div className="row">
                                            {chartlist.map((e, i) =>
                                                <div className="col-xl-6 col-xxl-4 mt-4" onClick={() => handlegridTabClick('manual')}>
                                                    <Chartcard tab={"roi"} />
                                                </div>)}
                                        </div>
                                    </>
                                }
                                {topTab === "toppnl" &&
                                    <>
                                        <div className="row">
                                            {chartlist.map((e, i) =>
                                                <div className="col-xl-6 col-xxl-4 mt-4" onClick={() => handlegridTabClick('manual')}>
                                                    <Chartcard tab={"pnl"} />
                                                </div>)}
                                        </div>
                                    </>
                                }
                                {topTab === "copied" &&
                                    <>
                                        <div className="row">
                                            {chartlist.map((e, i) =>
                                                <div className="col-xl-6 col-xxl-4 mt-4" onClick={() => handlegridTabClick('manual')}>
                                                    <Chartcard tab={"copied"} />
                                                </div>)}
                                        </div>
                                    </>
                                }
                                {topTab === "watched" &&
                                    <>
                                        <div className="row">
                                            {chartlist.map((e, i) =>
                                                <div className="col-xl-6 col-xxl-4 mt-4" onClick={() => handlegridTabClick('manual')}>
                                                    <Chartcard tab={"watched"} />
                                                </div>)}
                                        </div>
                                    </>
                                }
                            </div>
                        </>
                    }


                    {gridlistTab === "manual" &&
                        <>
                            <div className="row">
                                <div className="col-xl-6 mt-4 mt-xl-0">
                                    <h5 className="themeclr">1. Price Range</h5>
                                    <div className="d-flex align-items-center pt-2 pb-2">
                                        <input type="text" className="form-control input_spot" placeholder="Lower" />
                                        <span className="mx-2"> - </span>
                                        <input type="text" className="form-control input_spot" placeholder="Upper" />
                                    </div>
                                    <h5 className="themeclr mt-2">2. Grid</h5>
                                    <InputGroup className="mb-2 mt-3 inputall pos">
                                        <Form.Control className="form-control input_spot text-end" placeholder="2-170" aria-label="Text input with dropdown button" />
                                        <DropdownButton
                                            title="Arithmetic"
                                            id="input-group-dropdown-1">
                                            <Dropdown.Item>Arithmetic</Dropdown.Item>
                                            <Dropdown.Item>Geometic</Dropdown.Item>
                                        </DropdownButton>
                                        <p className="grid_pos themeclr mb-0 fw-500 px-2">Grids</p>
                                    </InputGroup>
                                    <div className="d-flex align-items-center justify-content-between"><p className="themeclr mb-0">Profit/grid(fees deducted)</p>
                                        <p className="themeclr mb-0">--</p></div>
                                </div>
                                <div className="col-xl-6 mt-4 mt-xl-0">
                                    <div className="d-flex justify-content-between">
                                        <h5 className="themeclr mb-3">3. Investment <MdInfo /></h5>
                                        <p className="mb-0 fw-500">USDT</p>
                                    </div>
                                    <p className="fw-500">Avbl: <span className="themeclr">0.00 USDT <FaArrowRightArrowLeft /></span></p>
                                    <div className="pos">
                                        <input type="text" className="form-control input_spot text-end" value="0.00" />
                                        <p className="p_pos blkclr mb-0 fw-500">Total</p>
                                        <p className="net_pos themeclr mb-0 fw-500 px-2">USDT</p>
                                    </div>
                                    <div className="py-3 mx-2">
                                        <Slider
                                            min={2}
                                            max={labels1.length + 1}
                                            value={value1}
                                            className="custom-slider"
                                            handleStyle={handleStyle1}
                                            graduated
                                            progress
                                            onChange={(v) => setValue1(v)}
                                            renderMark={(mark) => {
                                                return mark + "x";
                                            }}
                                        /></div>
                                    <button className="btn btn-brand-1 sitebtn w-100 hover-up me-2">trd-sign-disclaimer</button>
                                    <div className="marketbor w-100 py-2 rounded-3 px-2 mt-3 mb-2">
                                        <p className="themeclr mb-0"><BsFillInfoCircleFill /> You are required to sign the Risk Disclaimer Statements before you set up a strategy.</p>
                                    </div>
                                </div>
                            </div>
                        </>
                    }

                </div>





            </div>


        </>
    )
}

export default Gridtab;