import React, { useState, useEffect } from 'react';
import edafacelogo from "../Assets/edaface-logo1.png";
import { Link, NavLink } from "react-router-dom";
import { FaArrowRight } from "react-icons/fa6";
import Images from '../Components/Images';

const Footer = () => {


  return (
    <>

      <div className='footerss pt-4 pb-4'>
        <div className='container'>
          <div className='text-center'>
            <Link to="https://edaface.com/"><img src={edafacelogo} className="logos" alt="logos" /></Link>
          </div>

          <div className='row mt-4'>
            <div className='col-sm-4 col-md-3 col-xl-2 mt-3'>
              <h5 className='mb-3'>Company</h5>
              <ul>
                <li><Link to="/">Vision</Link></li>
                <li><Link to="/">Mission </Link></li>
                <li><Link to="/">Core Values</Link></li>
                <li><Link to="/">Branding </Link></li>
                <li><Link to="/">Team </Link></li>
                <li><Link to="/">Careers </Link></li>
                <li><Link to="/">FAQ</Link></li>
              </ul>
            </div>
            <div className='col-sm-4 col-md-3 col-xl-2 mt-3'>
              <h5 className='mb-3'>Products</h5>
              <ul>
                <li><Link to="/">Eda Token</Link></li>
                <li><Link to="/">Digital Clinic </Link></li>
                <li><Link to="/">School of Cryptos</Link></li>
                <li><Link to="/">Litepaper </Link></li>
                <li><Link to="/">Whitepaper  </Link></li>
                <li><Link to="/">Welfare Donations </Link></li>
              </ul>
            </div>
            <div className='col-sm-4 col-md-3 col-xl-2 mt-3'>
              <h5 className='mb-3'>Legal</h5>
              <ul>
                <li><Link to="/">Terms of Use</Link></li>
                <li><Link to="/">Privacy Policy </Link></li>
                <li><Link to="/">Disclaimer</Link></li>
                <li><Link to="/">Listing T&C </Link></li>
                <li><Link to="/">Eda Token Policy </Link></li>
              </ul>
            </div>
            <div className='col-sm-6 col-md-3  col-xl-3 mt-3'>
            {/* <h6 className='mt-3 mb-3 themeclr'>EdaFace Communities</h6> */}
            <h5 className='mb-3'>EdaFace Communities</h5>
             <div className='d-flex gap-2 sociallinkss'>
                <Link to="" target='_blank'><img src={Images.chatimg} className='img-fluid simg'/></Link>
                <Link to="https://www.facebook.com/" target='_blank'><img src={Images.facebookimg} className='img-fluid simg'/></Link>
                <Link to="https://www.instagram.com/" target='_blank'><img src={Images.instaimg} className='img-fluid simg'/></Link>
                <Link to="https://telegram.org/" target='_blank'><img src={Images.teleimg} className='img-fluid simg'/></Link>
                <Link to="https://twitter.com/" target='_blank'><img src={Images.twitterimg} className='img-fluid simg'/></Link>
                <Link to="https://www.youtube.com/" target='_blank'><img src={Images.youtubeimg} className='img-fluid simg'/></Link>
             </div>
            </div>
            <div className='col-sm-6 col-md-6 col-lg-6 col-xl-3 mt-3 mx-auto'>
              <h5 className='mb-2'>Follow Us</h5>
              <h6 className='mb-3 themeclr'>Subscribe to our newsletter</h6>
              <div className='position-relative allarrow'><input class="form-control enquire" type="text" placeholder="Your Email" aria-label="default input example" /><FaArrowRight /></div>         
            </div>
          </div>

          <hr></hr>

          {/* <p className='copyright mb-0 pt-2'>Copyright © 2024. EdaFace is a product of Emerging Digital Age (EDA) Pty Ltd. All Rights Reserved.</p> */}
          <p className='copyright mb-0 pt-2'>Copyright © 2024. EdaFace Dex. All Rights Reserved.</p>

        </div>
      </div>

    </>
  )
}

export default Footer;

