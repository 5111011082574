import React, { useState, useEffect } from "react";
import Innerheader from "../Layouts/innerheader";
import Footer from "../Layouts/footer";
import { Col, Row, Container, Table } from "react-bootstrap";
import "../Karthick.css";
import "../inner.css";
import Images from "../Components/Images";

const ViewPools = () => {

  const [coins] = useState([
    {
      cimg: Images.eth,
      cname: "ETH",
      percent: "50%"
    },
    {
      cimg: Images.dai,
      cname: "DAI",
      percent: "15%"
    },
    {
      cimg: Images.bal,
      cname: "BAL",
      percent: "50%"
    },
    {
      cimg: Images.snx,
      cname: "SNX",
      percent: "15%"
    },
    {
      cimg: Images.usdc,
      cname: "usdc",
      percent: "50%"
    }
  ]);

  const tokenheaddata = ["Token", "Weight", "Balance", "Value"];
  const [tokenbodydata] = useState([
    {
      timg: Images.eth,
      tokenname: "Eth",
      weight: "50.00%",
      balance: "4,803.074",
      value: "$99,952"
    },
    {
      timg: Images.dai,
      tokenname: "dai",
      weight: "50.00%",
      balance: "4,803.074",
      value: "$99,952"
    },
    {
      timg: Images.bal,
      tokenname: "bal",
      weight: "50.00%",
      balance: "4,803.074",
      value: "$99,952"
    },
    {
      timg: Images.snx,
      tokenname: "snx",
      weight: "50.00%",
      balance: "4,803.074",
      value: "$99,952"
    },
    {
      timg: Images.usdc,
      tokenname: "usdc",
      weight: "50.00%",
      balance: "4,803.074",
      value: "$99,952"
    }
  ]);

  const [transacbtns, setTransacbtns] = useState("investments");
  const transachead = ["Action", "Details", "Value", "Time"];
  
  const [transaccoins] = useState([
    {
      timg: Images.eth,
      tokenname: "Eth",
      value: "0.529"
    },
    {
      timg: Images.dai,
      tokenname: "dai",
      value: "4,803.074"
    },
    {
      timg: Images.bal,
      tokenname: "bal",
      value: "10"
    },
    {
      timg: Images.snx,
      tokenname: "snx",
      value: "0.529"
    },
    {
      timg: Images.usdc,
      tokenname: "usdc",
      value: "7.0674"
    }
  ]);

  const [transacbody] = useState([
    {
      action: "invest",
      details: transaccoins,
      value: "$199.70K",
      time: "Less than a minute ago"
    },
  ]);

  const [balancecoins] = useState([
    {      
      cimg: Images.bal,
      cname: "BAL",
      percent: "50%",
      iweight: "15:02%",
      balanceone: "0.529",
      balancetwo: "$30,000"
    },
    {
      cimg: Images.dai,
      cname: "DAI",
      percent: "15.00%",
      iweight: "15:02%",
      balanceone: "4,803.074",
      balancetwo: "$99,952"
    },
    {
      cimg: Images.eth,
      cname: "ETH",
      percent: "50%",
      iweight: "15:02%",
      balanceone: "10",
      balancetwo: "$29,785"
    },
    {
      cimg: Images.usdc,
      cname: "usdc",
      percent: "15.00%",
      iweight: "15:02%",
      balanceone: "10",
      balancetwo: "$10,000"
    },
    {      
      cimg: Images.snx,
      cname: "SNX",
      percent: "5.00%",
      iweight: "15:02%",
      balanceone: "0.529",
      balancetwo: "$30,000"
    }
  ]);

  useEffect(() => {
    window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
    });
  }, []);

  return (
    <>
      <Innerheader />
      <Container fluid className="innerpage pools_sec assetpage px-0 pos">
        <Container className="pt-5">
          <img src={Images.backlogo} className="backlogo farms__backlogo" />
          <div className="viewpools">
            <Row>
              <Col xs={12} md={12} lg={7} xl={8} className="mb-4 mb-lg-0">
                <h5 class="tabbtntxt">Weighted Pool</h5>
                <p className="content">
                  Fixed swap fees:0.01%
                </p>
                <div className="coins_list">
                  { coins.map((co) => (
                    <div>
                      <div className="coinbg">
                        <div className="d-flex align-items-center gap-1">
                          <img src={co.cimg} alt="" className="img-fluid"/>
                          <span className="coname">{co.cname}</span>
                        </div>
                        <div>
                          { co.percent }
                        </div>
                      </div>
                    </div>
                  )) }
                </div>
                <div className="option_border p-5">
                  <p className="content fw-medium text-center">Not Enough Data</p>
                </div>
                <div className="valuesdata">
                  <div className="valuesbg">
                    <p className="valueshead">Pool Value</p>
                    <span className="valuesnum">$199,696</span>
                  </div>
                  <div className="valuesbg">
                    <p className="valueshead">Volume (24h)</p>
                    <span className="valuesnum">$0.00</span>
                  </div>
                  <div className="valuesbg">
                    <p className="valueshead">Fees (24h)</p>
                    <span className="valuesnum">$0.00</span>
                  </div>
                  <div className="valuesbg">
                    <p className="valueshead">APR</p>
                    <span className="valuesnum">$0.00</span>
                  </div>
                </div>
                <div className="tokentable">
                  <Table responsive>
                    <thead>
                      <tr>
                        { tokenheaddata.map((th) => (
                          <th>{th}</th>
                        )) }
                      </tr>
                    </thead>
                    <tbody>
                      { tokenbodydata.map((td) => (
                        <tr>
                          <td>
                            <div className="d-flex align-items-center gap-2">
                              <img src={td.timg} alt={td.tokenname} className="cimg" />
                              <span>{td.tokenname}</span>
                            </div>
                          </td>
                          <td>{td.weight}</td>
                          <td>{td.balance}</td>
                          <td>{td.value}</td>
                        </tr>
                      )) }
                    </tbody>
                  </Table>
                </div>                
              </Col>
              <Col xs={12} md={12} lg={5} xl={4}>
                <div className="card balancebox option_border">
                  <div className="card-header">
                    <h5 class="tabbtntxt mb-0">My pool balance</h5>                    
                    <h5 class="tabbtntxt mb-0">$199.696</h5>                    
                  </div>
                  <div className="card-body">
                    { balancecoins.map((bc) => (
                      <div className="flexbox">
                        <div>
                          <div className="d-flex align-items-center gap-2">
                            <div>
                              <img src={bc.cimg} alt={bc.cname} />
                            </div>
                            <div>
                              <p className="balancecoin">
                                { bc.percent } <b>{ bc.cname }</b>
                              </p>
                              <p className="inweight">
                                Initial weight: { bc.iweight }
                              </p>
                            </div>
                          </div>
                        </div>
                        <div>
                          <p className="balancecoin text-end">{bc.balanceone}</p>
                          <p className="inweight text-end">
                            { bc.balancetwo }
                          </p>
                        </div>
                      </div>
                    ))
                    }
                  </div>
                </div>
                <div className="balanceboxbottom option_border mt-4">
                  <p>Based on pool tokens in your wallet</p>
                  <div className="flexbox">
                    <h5 class="tabbtntxt mb-0">You can invest</h5>                    
                    <h5 class="tabbtntxt mb-0">$534,768</h5>                    
                  </div>
                  <div className="mt-3 d-flex align-items-center justify-content-center">
                    <button class="btn btn-brand-1 sitebtn hover-up me-3 w-100">Invest</button>
                    <button class="btn btn-brand-1 sitebtn hover-up w-100">Withdraw</button>
                  </div>
                </div>
              </Col>
            </Row>
            <div className="pooltransaction my-4">
              <h5 class="tabbtntxt mb-0">Pool transaction</h5>
              <div className="transactiontab">
                <button type="button" className={transacbtns == "investments" ? "btn transacbtn active" : "btn transacbtn"} onClick={() => setTransacbtns("investments")}>Investments</button>
                <button type="button" className={transacbtns == "swaps" ? "btn transacbtn active" : "btn transacbtn"} onClick={() => setTransacbtns("swaps")}>Swaps</button>
                <button type="button" className={transacbtns == "myinvestments" ? "btn transacbtn active" : "btn transacbtn"} onClick={() => setTransacbtns("myinvestments")}>My investments</button>
                <div className="transaction-content">
                  { transacbtns == "investments" &&
                    <div className="transac-table">
                      <Table responsive>
                        <thead>
                          <tr>
                            { transachead.map((th) => (
                              <th>{th}</th>
                            )) }
                          </tr>
                        </thead>
                        <tbody>
                          { transacbody.map((td) => (
                            <tr>
                              <td>{td.action}</td>
                              <td>
                                <div className="coins_list">
                                  {td.details.map((det) => (
                                      <div className="coinbg">
                                        <div className="d-flex align-items-center gap-2">
                                          <img src={det.timg} alt={det.tokenname} />
                                          <div>{det.value}</div>
                                        </div>
                                      </div>
                                  ))}
                                </div>
                              </td>
                              <td>{td.value}</td>
                              <td>{td.time}</td>
                            </tr>
                          )) }
                        </tbody>
                      </Table>
                    </div>
                  }
                  { (transacbtns == "swaps" || transacbtns == "myinvestments") &&
                    <p className="content p-3">No data</p>
                  }
                </div>
              </div>
            </div> 
          </div>
        </Container>
      </Container>
      <Footer />
    </>
  );
};

export default ViewPools;
