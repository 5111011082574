import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { IoClose } from "react-icons/io5";
import { Link } from "react-router-dom";

const createp2pmodal = (props) => {
  return (
    <>
      <Modal
        show={props.show}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter walletlist"
        centered
        className="createp2p"
      >
        <Modal.Body>
          <div className="text-end mb-3">
            <button
              className="btn close me-0"
              onClick={() => props.onDismiss()}
            >
              <IoClose />
            </button>
          </div>
          <div className="createp2pbox">
            <h4 className="h4 themeclr">Edaface Dex Terms & Conditions</h4>
            <p className="content mb-3">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Nisi
              voluptatibus ipsum quisquam consectetur dignissimos obcaecati
              neque, eos, eaque excepturi cupiditate earum rem blanditiis
              recusandae officia quia, non odio quidem illum. Lorem ipsum dolor
              sit amet consectetur adipisicing elit. Aliquam accusamus nobis non
              enim ex, aperiam ratione adipisci repellendus quas asperiores
              dolorum perspiciatis in beatae fugiat maiores eos eveniet fugit
              quae!
            </p>
            <p className="content">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Nisi
              voluptatibus ipsum quisquam consectetur dignissimos obcaecati
              neque, eos, eaque excepturi cupiditate earum rem blanditiis
              recusandae officia quia, non odio quidem illum. Lorem ipsum dolor
              sit amet consectetur adipisicing elit. Aliquam accusamus nobis non
              enim ex, aperiam ratione adipisci repellendus quas asperiores
              dolorum perspiciatis in beatae fugiat maiores eos eveniet fugit
              quae!
            </p>
            <div className="mt-5 d-flex align-items-center justify-content-between">
              <button
                type="button"
                class="btn btn-brand-1 sitebtn hover-up formbtn me-3"
                onClick={() => props.onDismiss()}
              >
                Decline
              </button>
              <Link
                to="/advertisedetail"
                class="btn btn-brand-1 sitebtn hover-up formbtn"
              >
                Accept
              </Link>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default createp2pmodal;
