import React, { useState, useEffect } from "react";
import Images from "../../Components/Images";
import { Table } from "react-bootstrap";

function Positiontable() {

    const [positiontable, setPositiontable] = useState([
        { id: 1 }, { id: 2 }, { id: 3 }, { id: 4 },
    ])

    return (

        <div className="order_table">
            <Table responsive>
                <thead>
                    <tr>
                        <th>Position</th>
                        <th>Net Value</th>
                        <th>Size</th>
                        <th>Collateral</th>
                        <th>Entry Price</th>
                        <th>Mark Price</th>
                        <th>Liq. Price</th>
                    </tr>
                </thead>
                <tbody>
                    {!positiontable.length[0] ?
                    <>
                    {positiontable.map((e, i) =>
                        <tr>
                            <td>Lorem</td>
                            <td>Lorem</td>
                            <td>3</td>
                            <td>WETH</td>
                            <td>$765.00</td>
                            <td>$12,345</td>
                            <td>$273.87</td>
                        </tr>
                    )}</>
                    :
                    <h6 className="themeclr ms-2 fw-600 my-4">No Open positions</h6>}
                </tbody>
            </Table>
        </div>

    )
}

export default Positiontable;