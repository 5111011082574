import React, { useState, useEffect } from "react";
import { Modal, Col, Container, Dropdown, Row, DropdownToggle, DropdownMenu, DropdownItem } from "react-bootstrap";
import Images from "../Images";
import { IoClose } from "react-icons/io5";
import { IoWalletOutline } from "react-icons/io5";
import { IoArrowForwardOutline } from "react-icons/io5";
import { BsFillInfoCircleFill } from "react-icons/bs";
import { MdLocalGasStation } from "react-icons/md";

function Repaymodals(props) {

    const [repaymodal, setRepaymodal] = useState(true);
    const [repaystep, setRepaystep] = useState(1);
    const [borrowactive, setBorrowactive] = useState("variable");
    const borrowtabclick = (tab) => {
        setBorrowactive(tab)
    }
    return (

        <div className='walletmodal'>

            <Modal show={repaymodal} size="md" aria-labelledby="contained-modal-title-vcenter" centered >
                <Modal.Body className="py-4 px-3">
                    <div className="text-end mb-2"><button className='btn close me-0' onClick={() => props.onDismiss()}><IoClose /></button></div>
                    {repaystep === 1 &&
                        <>
                            <h5 className="themeclr fw-600 mb-4">Repay ETH</h5>
                            <h6 className="fw-500 themeclr mt-2">Repay with</h6>
                            <ul className="table_tabs borrows">
                                <li className={borrowactive === "variable" ? "active ms-0" : "ms-0"} onClick={() => borrowtabclick("variable")}>Wallet Balance</li>
                                <li className={borrowactive === "stable" ? "active" : ""} onClick={() => borrowtabclick("stable")}>Collateral</li>
                            </ul>

                            {borrowactive === "variable" &&
                                <>
                                    <h6 className="fw-500 themeclr mt-2">Amount</h6>
                                    <div className="swap__blueBox px-3 d-flex pt-2 pb-2 justify-content-between rounded-4">
                                        <div>
                                            <input type="text" className="form-control ps-0 pt-0 swap_input" placeholder="0.00" />
                                            <p className="mb-0">$2.88</p>
                                        </div>
                                        <div>
                                            <p className="mb-2 themeclr text-end"><img src={Images.eth} className="img-fluid ethimg" /> ETH</p>
                                            <p className="mb-0">Bal 0.236 Max</p>
                                        </div>
                                    </div>
                                    <h6 className="fw-500 themeclr mt-3">Transaction Overview</h6>
                                    <div className="swap__blueBox px-3 pt-2 pb-2 rounded-4">
                                        <div className="d-flex justify-content-between"><p className="mb-0 fw-500 themeclr">Remaining Debt</p>
                                            <div>
                                                <p className="mb-0 themeclr fw-500 text-end"> 0.044 WETH  <IoArrowForwardOutline className="mts" /> 0 WETH</p>
                                                <p className="mb-0 themeclr fw-500 text-end"> $0.50  <IoArrowForwardOutline className="mts" /> $0</p>
                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-between mt-3"><p className="mb-0 fw-500 themeclr">Health Factor</p>
                                            <div><p className="mb-0 grnclr text-end"> <span className="redclr">1.08 </span> <IoArrowForwardOutline className="mts" /> 3.78</p><p className="mb-0 fw-400 themeclr">Liquidation at 1.10</p></div>
                                        </div>
                                    </div>
                                    <p className="mt-3 themeclr fw-600 mb-0"><MdLocalGasStation className="mts" /> {"<"}0.98</p>

                                </>
                            }
                            {borrowactive === "stable" &&
                                <>
                                    <h6 className="fw-500 themeclr mt-2">Amount</h6>
                                    <div className="swap__blueBox px-3 d-flex pt-2 pb-2 justify-content-between rounded-4">
                                        <div>
                                            <input type="text" className="form-control ps-0 pt-0 swap_input" placeholder="0.00" />
                                            <p className="mb-0">$2.88</p>
                                        </div>
                                        <div>
                                            <p className="mb-2 themeclr text-end"><img src={Images.eth} className="img-fluid ethimg" /> ETH</p>
                                            <p className="mb-0">Bal 0.236 Max</p>
                                        </div>
                                    </div>
                                    <h6 className="fw-500 themeclr mt-3">Transaction Overview</h6>
                                    <div className="swap__blueBox px-3 pt-2 pb-2 rounded-4">
                                        <div className="d-flex justify-content-between"><p className="mb-0 fw-500 themeclr">Remaining Debt</p>
                                            <div>
                                                <p className="mb-0 themeclr fw-500 text-end"> 0.044 WETH  <IoArrowForwardOutline className="mts" /> 0 WETH</p>
                                                <p className="mb-0 themeclr fw-500 text-end"> $0.50  <IoArrowForwardOutline className="mts" /> $0</p>
                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-between mt-3"><p className="mb-0 fw-500 themeclr">Health Factor</p>
                                            <div><p className="mb-0 grnclr text-end"> <span className="redclr">1.08 </span> <IoArrowForwardOutline className="mts" /> 3.78</p><p className="mb-0 fw-400 themeclr">Liquidation at 1.10</p></div>
                                        </div>
                                    </div>
                                    <p className="mt-3 themeclr fw-600 mb-0"><MdLocalGasStation className="mts" /> {"<"}0.98</p>

                                </>
                            }
                            <button class="btn btn-brand-1 sitebtn hover-up mt-3 mb-3 w-100" onClick={() => setRepaystep(2)}>Repay ETH</button>
                        </>
                    }

                    {repaystep === 2 &&
                        <>
                            <div className="success_tick">
                                <svg viewBox="0 0 26 26" xmlns="http://www.w3.org/2000/svg">
                                    <g stroke="currentColor" stroke-width="2" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
                                        <path class="circle" d="M13 1C6.372583 1 1 6.372583 1 13s5.372583 12 12 12 12-5.372583 12-12S19.627417 1 13 1z" />
                                        <path class="tick" d="M6.5 13.5L10 17 l8.808621-8.308621" />
                                    </g>
                                </svg>
                                <h5 className="themeclr text-center mt-4 mb-2">All done</h5>
                                <p className="themeclr text-center">you repaid 2.076 DEX</p>
                            </div>

                            <button class="btn btn-brand-1 sitebtn hover-up mt-3 mb-3 w-100" onClick={() => props.onDismiss()}>Ok, Close</button>
                        </>
                    }
                </Modal.Body>
            </Modal>
        </div>

    )
}

export default Repaymodals;