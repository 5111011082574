import React, { useState, useEffect } from "react";
import Images from "../../Components/Images";
import { Table } from "react-bootstrap";

function Ordertable() {

    const [ordertable, setOrdertable] = useState([
        { id: 1 }, { id: 2 }, { id: 3 }
    ])

    return (

        <div className="order_table">
            <Table responsive>
                <thead>
                    <tr>
                        <th>Type</th>
                        <th>Order</th>
                        <th>Trigger Price</th>
                        <th>Mark Price</th>
                    </tr>
                </thead>
                <tbody>
                {!ordertable.length[0] ?
                <>
                    {ordertable.map((e, i) =>
                        <tr>
                            <td>Lorem</td>
                            <td>Lorem</td>
                            <td>$75.00</td>
                            <td>$23.87</td>
                        </tr>
                    )}</>
                    :
                    <h6 className="themeclr ms-2 fw-600 my-4">No Open positions</h6>}
                </tbody>
            </Table>
        </div>

    )
}

export default Ordertable;