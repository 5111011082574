import React, { useState, useEffect } from "react";
import Innerheader from "../Layouts/innerheader";
import Footer from "../Layouts/footer";
import { Col, Container, Row, InputGroup, Form, Accordion, Table } from "react-bootstrap";
import "../Karthick.css";
import "../inner.css";
import Images from "../Components/Images";
import Select from "react-select";
import { BiSearch } from "react-icons/bi";
import { FaChevronDown } from "react-icons/fa";

const Earn = () => {
  const [earnselectbox, setEarnselectbox] = useState([
    {
      value: "all duration",
      label: "All Duration",
    },
    {
      value: "flexible",
      label: "Flexible",
    },
    {
      value: "fixed",
      label: "Fixed",
    },
  ]);

  const [earnselectboxtwo, setEarnselectboxtwo] = useState([
    {
      value: "all products",
      label: "All Products",
    },
    {
      value: "Principal-protected",
      label: "Principal-Protected",
    },
    {
      value: "High Yield",
      label: "High Yield",
    },
  ]);

  const coinstablehead = ["Coins", "Est. APR", "Duration"];
  const [coinstablebody] = useState([
    {
      img: Images.btc,
      cname: "BTC",
      est: "7.46%",
      flexible: "Flexible",
      fixed: "Fixed",
      text: "green",
      dual: "3.65%~338.21%"
    },
    {
      img: Images.usdc,
      cname: "usdc",
      est: "7.46%",
      flexible: "Flexible",
      fixed: "Fixed",
      text: "green",
      dual: "3.65%~338.21%"
    },
    {
      img: Images.dai,
      cname: "dai",
      est: "0.26%~159.58%",
      flexible: "Flexible",
      fixed: "Fixed",
      text: "green",
      dual: "3.8%~303.11%"
    },
    {
      img: Images.bnb,
      cname: "bnb",
      est: "0.26%~6.8%",
      flexible: "Flexible",
      fixed: "Fixed",
      text: "green",
      dual: "3.8%~303.11%"
    },
    {
      img: Images.btc,
      cname: "BTC",
      est: "7.46%",
      flexible: "Flexible",
      fixed: "Fixed",
      text: "green",
      dual: "3.65%~338.21%"
    },
    {
      img: Images.usdc,
      cname: "usdc",
      est: "10.91%",
      flexible: "Flexible",
      fixed: "Fixed",
      text: "green",
      dual: "3.65%~338.21%"
    },
    {
      img: Images.dai,
      cname: "dai",
      est: "7.46%",
      flexible: "Flexible",
      fixed: "Fixed",
      text: "green",
      dual: "3.65%~338.21%"
    },
    {
      img: Images.bnb,
      cname: "bnb",
      est: "3.02%",
      flexible: "Flexible",
      fixed: "Fixed",
      text: "green",
      dual: "3.65%~338.21%"
    }
  ]);
  const colourStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      // const color = chroma(data.color);
      console.log({ data, isDisabled, isFocused, isSelected });
      return {
        ...styles,
        backgroundColor: isFocused ? "rgb(151 243 255 / 49%)" : null,
        color: "#2D699B",
        fontWeight: "500"
      };
    }
  };

  const [changecoin, setChangecoin] = useState("");

  const coinchange = (e) => {
    var lowercase = e.target.value.toLowerCase();
    setChangecoin(lowercase);
  }

  const coinsdatabox = coinstablebody.filter((ct) => {
    if(changecoin === "") {
        return ct;
    } else {
        return ct.cname.toLowerCase().includes(changecoin);
    }
  });

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <>
      <Innerheader />
      <div className="earnpage">
        <img src={Images.backlogo} className="backlogo farms__backlogo" />
        <div className="swap__page position-relative pb-5">

        <Container>
          
            <Row>
              <Col xs={12} sm={12} md={12} lg={7}>
                <h1 className="themeclr fw-500">Earn</h1>
                <p className="bannercontent">
                  Simple & Secure. Search popular coins and start earning.
                </p>
              </Col>
            </Row>
          </Container>
          <div className="earntable py-5">
            <div className="p2ptrade cointoken">
                <Container>
                    <div className="flexbox secondbox">
                        <div>
                            <InputGroup className="inputall pos">
                                <InputGroup.Text><BiSearch /></InputGroup.Text>
                            <Form.Control
                                className="form-control input_spot text-end"
                                aria-label="Text input with dropdown button"
                                placeholder="Search Coins"
                                onChange={coinchange}
                            />
                            </InputGroup>
                        </div>
                        <div>
                            <div className="multiselectbox">
                            <Select
                                options={earnselectbox}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                placeholder="All Durations"
                                styles={colourStyles}
                            />
                            </div>
                        </div>
                        <div>
                            <div className="multiselectbox">
                            <Select
                                options={earnselectboxtwo}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                placeholder="All Products"
                                styles={colourStyles}
                            />
                            </div>
                        </div>
                        <div>
                            <Form.Check label="Match My Assets" className="ps-0"></Form.Check>
                        </div>
                    </div>
                    <div className="earntablebox">
                        <h4 className="h4">All Products</h4>
                        <div className="mt-4">
                            <div className="tableboxhead flext">
                                { coinstablehead.map((cth) => (
                                    <div>
                                        <span>{cth.charAt(0).toUpperCase() + cth.slice(1)}</span>
                                    </div>
                                )) }                                
                            </div>
                            <div className="tableboxbody">
                                <Accordion>
                                    { coinsdatabox.map((ctb, i) => (
                                        <Accordion.Item eventKey={i + 1}>
                                            <Accordion.Button className="flext">
                                                <div>
                                                    <div className="d-flex align-items-center gap-2">
                                                        <img src={ctb.img} alt={ctb.cname} className="cimg" />
                                                        <span className="cname">{ctb.cname}</span>
                                                    </div>
                                                </div>
                                                <div>
                                                    <span className={ctb.text == "green" ? "estval t-green" : "estval t-red"}>
                                                        {ctb.est}
                                                    </span>
                                                </div>
                                                <div>
                                                    <div className="d-flex align-items-center justify-content-between">
                                                        <span className="duration">{ctb.flexible}/{ctb.fixed}</span>
                                                        <span className="downarrow"><FaChevronDown /></span>
                                                    </div>
                                                </div>
                                            </Accordion.Button>   
                                            <Accordion.Body>
                                                <div className="flext pt-0">
                                                    <div>
                                                        <span className="fw-500">Simple Earn</span>
                                                    </div>
                                                    <div>
                                                        <span className={ctb.text == "green" ? "estval t-green" : "estval t-red"}>
                                                            {ctb.est}
                                                        </span>
                                                    </div>
                                                    <div>
                                                        <div className="d-flex align-items-center justify-content-between gap-5 gap-sm-0">
                                                            <span className="duration">{ctb.flexible}</span>
                                                            <div className="subbtn">
                                                                <button class="btn btn-brand-1 sitebtn hover-up">
                                                                    Lorem    
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="flext">
                                                    <div>
                                                        <span className="fw-500">Dual Investment</span>
                                                    </div>
                                                    <div>
                                                        <span className={ctb.text == "green" ? "estval t-green" : "estval t-red"}>
                                                            {ctb.dual}
                                                        </span>
                                                    </div>
                                                    <div>
                                                        <div className="d-flex align-items-center justify-content-between gap-5 gap-sm-0">
                                                            <span className="duration">{ctb.fixed}</span>
                                                            <div className="subbtn">
                                                                <button class="btn btn-brand-1 sitebtn hover-up">
                                                                    Lorem    
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Accordion.Body> 
                                        </Accordion.Item>
                                    )) }                                    
                                </Accordion>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>          
        </div>
        </div>
        {/* <div className="bannersection">
          <Container>
            <Row>
              <Col xs={12} sm={12} md={12} lg={7}>
                <h1 className="h1">Earn</h1>
                <p className="bannercontent">
                  Simple & Secure. Search popular coins and start earning.
                </p>
              </Col>
            </Row>
          </Container>
        </div> */}
      
      </div>
      <Footer />
    </>
  );
};

export default Earn;
