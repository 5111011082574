import React, { useState } from "react";
import Innerheader from "../Layouts/innerheader";
import Footer from "../Layouts/footer";
import { Col, Container, Row, Badge } from "react-bootstrap";
import "../Karthick.css";
import "../inner.css";
import Images from "../Components/Images";
import { IoMdCheckmarkCircle } from "react-icons/io";
import { Link } from "react-router-dom";

const Launchpad = () => {

    const bannerdata = ["$ 764,416,658", "$ 190,192,687", "92", "5,449,975"];
    const bannerdatacontent = ["Currently Staked", "Funds Raised", "project Launched", "All-time Unique participants"];

    const [launchpad] = useState([
        {
            name: "Lorem",
            cname: "bnb",
            launchimg: require("../Assets/loginbg.png"),
            launchdesc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Laudantium commodi a cumque sed quaerat dolor nostrum aut porro?",
            tokenoffer: "500,000,000.00",
            participants: "114,454",
            saleprice: "0.00027564",
            totalcommit: "10,182,983.2625",
            endtime: "2023-07-18",
            type: "subscription",
            singleiin: "0.1",
            hardcap: "300000",
            hardcaptwo: "61.7280 (≈ 38969 USD)"
        },
        {
            name: "Lorem",
            cname: "bnb",
            launchimg: require("../Assets/loginbg.png"),
            launchdesc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Laudantium commodi a cumque sed quaerat dolor nostrum aut porro?",
            tokenoffer: "500,000,000.00",
            participants: "114,454",
            saleprice: "0.00027564",
            totalcommit: "10,182,983.2625",
            endtime: "2023-07-18",
            type: "subscription",
            singleiin: "0.1",
            hardcap: "300000",
            hardcaptwo: "61.7280 (≈ 38969 USD)"
        },
        {
            name: "Lorem",
            cname: "bnb",
            launchimg: require("../Assets/loginbg.png"),
            launchdesc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Laudantium commodi a cumque sed quaerat dolor nostrum aut porro?",
            tokenoffer: "500,000,000.00",
            participants: "114,454",
            saleprice: "0.00027564",
            totalcommit: "10,182,983.2625",
            endtime: "2023-07-18",
            type: "subscription",
            singleiin: "0.1",
            hardcap: "300000",
            hardcaptwo: "61.7280 (≈ 38969 USD)"
        },
        {
            name: "Lorem",
            cname: "bnb",
            launchimg: require("../Assets/loginbg.png"),
            launchdesc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Laudantium commodi a cumque sed quaerat dolor nostrum aut porro?",
            tokenoffer: "500,000,000.00",
            participants: "114,454",
            saleprice: "0.00027564",
            totalcommit: "10,182,983.2625",
            endtime: "2023-07-18",
            type: "subscription",
            singleiin: "0.1",
            hardcap: "300000",
            hardcaptwo: "61.7280 (≈ 38969 USD)"
        },
        {
            name: "Lorem",
            cname: "bnb",
            launchimg: require("../Assets/loginbg.png"),
            launchdesc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Laudantium commodi a cumque sed quaerat dolor nostrum aut porro?",
            tokenoffer: "500,000,000.00",
            participants: "114,454",
            saleprice: "0.00027564",
            totalcommit: "10,182,983.2625",
            endtime: "2023-07-18",
            type: "subscription",
            singleiin: "0.1",
            hardcap: "300000",
            hardcaptwo: "61.7280 (≈ 38969 USD)"
        },
    ]);
    // useEffect(() => {
    //     window.scrollTo({
    //         top: 0,
    //         left: 0,
    //         behavior: "smooth",
    //     });
    // }, []);


  return (
    <>
        <Innerheader />
            <div className="launchpad">
                <img src={Images.backlogo} className="backlogo farms__backlogo" />
                <div className="swap__page position-relative pb-5">
                    <Container>          
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={10}>
                                <h1 className="themeclr fw-500 pt-2 pt-sm-4">Edaface's Token Launch Platform</h1>
                                <p className="bannercontent">
                                    Buy or earn new tokens directly on Edaface
                                </p>
                            </Col>
                        </Row>
                        <div className="bannercount">
                            <Row>
                                { bannerdata.map((bc, i) => (
                                    <Col xs={12} sm={12} md={6} lg={3} className="mb-4">
                                        <p className="values">{bc}</p>
                                        <p className="blkclr">{bannerdatacontent[i]}</p>
                                    </Col>                                    
                                )) }                                
                            </Row>
                        </div>           
                        <div className="launchsection">
                            <h4 className="themeclr">Launchpad</h4>
                            <div className="launchpadbox">                                
                                { launchpad.map((ld, i) => (
                                    <Link to="/launchpad-detail" state={launchpad[i]}>
                                        <div className="launchpadcard chartcard">
                                            <div className="launchpadcardhead">
                                                <img src={ld.launchimg} alt="Launchpad image" className="launchimg" />
                                                <Badge bg="secondary"><IoMdCheckmarkCircle /> Finished</Badge>
                                            </div>
                                            <div className="launchpadcardbody">
                                                <> 
                                                    <p className="cname">{ld.name}</p>
                                                    <p className="blkclr f-14 fw-400">{ld.launchdesc}</p>
                                                    <div className="lbox">
                                                        <div className="d-flex align-items-center justify-content-between mb-3">
                                                            <p className="themeclr mb-0 fw-500">Tokens Offered</p>
                                                            <p className="lboxlabel fw-500">
                                                                {ld.tokenoffer}
                                                                <span className="text-uppercase ps-1">{ld.cname}</span>
                                                            </p>
                                                        </div>
                                                        <div className="d-flex align-items-center justify-content-between mb-3">
                                                            <p className="themeclr mb-0 fw-500">Participants</p>
                                                            <p className="lboxlabel fw-500">{ld.participants}</p>
                                                        </div>
                                                        <div className="d-flex align-items-center justify-content-between mb-3">
                                                            <p className="themeclr mb-0 fw-500">Sale price</p>
                                                            <p className="lboxlabel fw-500">
                                                                1 <span className="text-uppercase">{ld.cname}</span> = {ld.saleprice}
                                                                <span className="text-uppercase ps-1">{ld.cname}</span>
                                                            </p>
                                                        </div>
                                                        <div className="d-flex align-items-center justify-content-between mb-3">
                                                            <p className="themeclr mb-0 fw-500">Total Committed</p>
                                                            <p className="lboxlabel fw-500">
                                                                {ld.totalcommit}
                                                                <span className="text-uppercase ps-1">bnb</span>
                                                            </p>
                                                        </div>
                                                        <hr />
                                                        <div className="d-flex align-items-center justify-content-between">
                                                            <p className="lboxlabel">End Time</p>
                                                            <p className="lboxlabel fw-500">
                                                                {ld.endtime}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </>                                            
                                            </div>
                                        </div>
                                    </Link>   
                                )) }                             
                            </div>
                        </div>             
                    </Container>
                </div>
            </div>
        <Footer />
    </>
  )
}

export default Launchpad;