import React, { useState, useEffect } from "react";
import { Modal, Col, Container, Dropdown, Row, DropdownToggle, DropdownMenu, DropdownItem } from "react-bootstrap";
import Images from "../Images";
import { IoClose } from "react-icons/io5";
import { MdOutlineKeyboardArrowDown, MdSwapHoriz, MdOutlineKeyboardArrowUp } from "react-icons/md";
import { BsQuestionCircle } from "react-icons/bs";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { FaArrowDown } from "react-icons/fa6";
import { FaEdit } from "react-icons/fa";
import { TiTick } from "react-icons/ti";

function Roicalculator(props) {

    const [roical, setRoical] = useState(true);
    const [detaillist, setDetaillist] = useState(false);
    const [Checked, setChecked] = useState(true);
    const handleOnChange = (event) => {
        setChecked(event.target.checked);
    };
    const [einput, setEinput] = useState(false);

    return (
        <>
            <div className='walletmodal'>
                <Modal show={roical} size="md" aria-labelledby="contained-modal-title-vcenter" centered >
                    <Modal.Body className="py-4 px-3">
                        <div className="text-end mb-4"><button className='btn close me-0' onClick={() => props.onDismiss()}><IoClose /></button></div>
                        <h5 className="themeclr fw-600 mb-4">ROI Calculator</h5>

                        <div className='row'>
                            <div className='col-lg-12'>
                                {/* <p className='themeclr  fw-500'>DEPOSIT AMOUNT</p> */}
                                {/* <div className="pos"><input type="text" value="100" className="form-control input_spot text-end"/><p className="net_pos themeclr mb-0 fw-500 px-2">USD</p></div>  */}
                                <p className='themeclr fw-500 mb-2'>Staked</p>
                                <p className="orgclr fw-500">view price in <button className='btn tbtn rounded-2 px-2 ms-2' onClick={() => props.onswap()}><MdSwapHoriz className='set_icon' /> {props.changefirst.coinname}</button></p>
                                <div className="swap__blueBox rounded-3 py-3 px-3">
                                    <div className="pos mb-3"><input type="text" placeholder="0.00" className="form-control input_spot text-end" /><p className="p_pos blkclr mb-0 fw-500"><img src={props.changefirst.image} className="img-fluid ethimg me-2" />{props.changefirst.coinname}</p></div>
                                    <div className="pos"><input type="text" placeholder="0.00" className="form-control input_spot text-end" /><p className="p_pos blkclr mb-0 fw-500"><img src={props.changesecond.image} className="img-fluid ethimg me-2" />{props.changesecond.coinname}</p></div>
                                </div>
                                <div className="d-flex align-items-center gap-3 mt-3">
                                    <button className="btn bbtn rounded-3 f-14">$100</button>
                                    <button className="btn bbtn rounded-3 f-14">$1000</button>
                                    <button className="btn bbtn rounded-3 f-14">My Balance</button>
                                    <BsQuestionCircle />
                                </div>

                                <p className='themeclr mt-3 fw-600 mb-2'>STAKED FOR</p>
                                <div className="d-flex py-1 px-1 justify-content-between rounded-4 stake_border">
                                    <p className="mb-0">1D</p>
                                    <p className="mb-0">7D</p>
                                    <p className="mb-0">30D</p>
                                    <p className="mb-0">1Y</p>
                                    <p className="mb-0">5Y</p>
                                </div>

                                <p className='themeclr mt-3 fw-600 mb-2'>COMPOUNDING EVERY</p>
                                <div className="d-flex align-items-center">
                                    <div class="form-check me-2">
                                        <input class="form-check-input" type="checkbox" onChange={handleOnChange} checked={Checked} value="" id="flexCheckDefault" />
                                    </div>
                                    <div className={Checked ? "d-flex w-100 py-1 px-1 justify-content-between rounded-4 stake_border" : "d-flex pointers w-100 py-1 px-1 justify-content-between rounded-4 stake_border"}>
                                        <p className="mb-0">1D</p>
                                        <p className="mb-0">7D</p>
                                        <p className="mb-0">14D</p>
                                        <p className="mb-0">30D</p>
                                    </div>
                                </div>

                                <div className="text-center py-3"><FaArrowDown /></div>

                                <div className="option_border rounded-4 py-3 px-3 mb-3">
                                    <p className="themeclr fw-500">ROI AT CURRENT RATES</p>
                                    <div className="d-flex justify-content-between align-items-center">
                                        {einput ? <input type="text" className="form-control me-3 mb-2" placeholder="0.00" /> : <h5>$11649412.348881593</h5>}
                                        {einput ? <TiTick className="set_icon grnclr cursor" onClick={() => setEinput(!einput)} /> : <FaEdit className="orgclr cursor" onClick={() => setEinput(!einput)} />}
                                    </div>
                                    <p className="mb-0 f-14">- 131.324 USDC (0%)</p>
                                </div>

                                {detaillist == false ?
                                    <p className='themeclr text-center mb-0 fw-600' style={{ cursor: "pointer" }} onClick={() => setDetaillist(true)}>Details <MdOutlineKeyboardArrowDown /></p> :
                                    <p className='themeclr text-center mb-0 fw-600' style={{ cursor: "pointer" }} onClick={() => setDetaillist(false)}>Hide <MdOutlineKeyboardArrowUp /></p>}
                                {detaillist ? <div>
                                  <div className="d-flex justify-content-between mb-2"><p className="fw-500 mb-0">Yield</p><p className="themeclr mb-0 fw-600">$0.00</p></div>
                                  <div className="d-flex justify-content-between ms-3 mb-2"><p className="fw-500 mb-0">LP Fee Yield</p><p className="themeclr mb-0 fw-600">$0.00</p></div>
                                  <div className="d-flex justify-content-between mb-2"><p className="fw-500 mb-0">APR</p><p className="themeclr mb-0 fw-600">$0.00</p></div>
                                  <div className="d-flex justify-content-between ms-3  mb-2"><p className="fw-500 mb-0">LP Fee APR</p><p className="themeclr mb-0 fw-600">$0.00</p></div>
                                  <ul className="instruction mt-3">
                                    <li>Yields and rewards are calculated at the current rates and subject to change based on various external variables.</li>
                                    <li>LP Fee Rewards: 0.01% ~ 1% per trade according to the specific fee tier of the trading pair, claimed and compounded manually.</li>
                                    <li>LP Fee APR figures are calculated using Subgraph and may subject to indexing delays. For more accurate LP Fee APR, please visit the Info Page.</li>
                                    <li>All figures are estimates provided for your convenience only, and by no means represent guaranteed returns.</li>
                                  </ul>
                                </div> : <></>}

                            </div>
                        </div>

                    </Modal.Body>
                </Modal>
            </div>

        </>
    )
}

export default Roicalculator