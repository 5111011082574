import React, { useState, useRef } from "react";
import Innerheader from "../Layouts/innerheader";
import Footer from "../Layouts/footer";
import { Container, Row, Col, Form, InputGroup } from "react-bootstrap";
import "../Karthick.css";
import "../inner.css";
import Images from "../Components/Images";
import { TbUserEdit } from "react-icons/tb";
import { GoMail } from "react-icons/go";
import { MdOutlineLock } from "react-icons/md";
import { TbLockCheck } from "react-icons/tb";
import { BsArrowRight } from "react-icons/bs"
import { Link } from "react-router-dom";
import mathwallet from "../Assets/mathwallet.svg";
import tokenpocket from "../Assets/tokenpocket.svg";
import walletcon from "../Assets/walletconnect.svg";
import ellipse from "../Assets/Ellipse.svg";


const Login = () => {

    const [loginsteps, setLoginsteps] = useState(1);
    const [walletnames, setWalletnames] = useState([
        { "id": 1, "image": Images.meta, "name":"Metamask" }, { "id": 2,"image": Images.coin, "name":"Coinbase Wallet" }, { "id": 3,"image": Images.trust, "name":"TrustWallet" }, { "id": 4,"image": mathwallet, "name":"MathWallet" }, { "id": 5,"image": tokenpocket, "name":"TokenPocket" }, { "id": 6, "image": walletcon, "name":"WalletConnect" }
    ]);

  return (
    <>
        <Innerheader />
            <Container fluid className="innerpage pools_sec assetpage px-0 pos">
                <Container className="pt-5">
                    <img src={Images.backlogo} className='backlogo farms__backlogo' />
                    <div className="cointoken">
                        <div className="formbox loginbox">
                            <Row>
                                <Col xs={12} sm={12} md={12} lg={6}>
                                    <div className="loginleftbox">
                                        <div className="blurbox">
                                            <h5 className="h5">Connect Your Wallet</h5>
                                            <ul className="logtab mb-0">
                                                <li onClick={() => setLoginsteps(1)}>
                                                    <div className="flex">
                                                        <div>
                                                            <span className={`tabnum ${loginsteps == 1 && "active" }`}>1</span>
                                                        </div>
                                                        <div>
                                                            <span className="tabname">Register</span>
                                                            <small className="tabopt">(If you are a new user)</small>
                                                        </div>
                                                        <div className="rightarrowbox">
                                                            <div className="rightarrow">
                                                                <BsArrowRight />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li onClick={() => setLoginsteps(2)}>
                                                    <div className="flex">
                                                        <div>
                                                            <span className={`tabnum ${loginsteps == 2 && "active"}`}>2</span>
                                                        </div>
                                                        <div>
                                                            <span className="tabname">Login</span>
                                                            <small className="tabopt">(If Already have an account)</small>
                                                        </div>
                                                        <div className="rightarrowbox">
                                                            <div className="rightarrow">
                                                                <BsArrowRight />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li onClick={() => setLoginsteps(3)}>
                                                    <div className="flex">
                                                        <div>
                                                            <span className={`tabnum ${loginsteps == 3 && "active"}`}>3</span>
                                                        </div>
                                                        <div>
                                                            <span className="tabname">Connect Wallet</span>
                                                            <small className="tabopt">(Only after Register or Login)</small>
                                                        </div>
                                                        <div className="rightarrowbox">
                                                            <div className="rightarrow">
                                                                <BsArrowRight />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={6} className="ps-lg-0">                                    
                                    <div className="loginrightbox">
                                        { loginsteps == 1 && 
                                            <>
                                                <div className="text-center">
                                                    <h6 class="formhead mb-2">Create an account</h6>
                                                    <p className="content">
                                                        Create an account today and start using our platform
                                                    </p>
                                                </div>
                                                <Form className="mt-4">
                                                    <InputGroup className="mb-4">
                                                        <InputGroup.Text><TbUserEdit /></InputGroup.Text>
                                                        <Form.Control type="text" placeholder="Your name"></Form.Control>
                                                    </InputGroup>
                                                    <InputGroup className="mb-4">
                                                        <InputGroup.Text><GoMail /></InputGroup.Text>
                                                        <Form.Control type="email" placeholder="Your email"></Form.Control>
                                                    </InputGroup>
                                                    <InputGroup className="mb-4">
                                                        <InputGroup.Text><MdOutlineLock /></InputGroup.Text>
                                                        <Form.Control type="password" placeholder="Password"></Form.Control>
                                                    </InputGroup>                        
                                                    <InputGroup className="mb-3">
                                                        <InputGroup.Text><TbLockCheck /></InputGroup.Text>
                                                        <Form.Control type="password" placeholder="Password confirmation"></Form.Control>
                                                    </InputGroup>           
                                                    <div className="text-center cracc">
                                                        <p className="content noteblue mb-3">
                                                            Your personal data will be used to support your experience throughout this website, to manage access to your account, and for other purposes described in our privacy policy.
                                                        </p>     
                                                        <Form.Check type="checkbox" label="I agree to terms & policy."></Form.Check>
                                                    </div>       
                                                    <div className="mt-4 text-center">
                                                        <button type="button" className="btn btn-brand-1 sitebtn hover-up formbtn w-100">Sign Up Now</button>
                                                    </div> 
                                                    <p className="content noteblue text-center mt-3">
                                                        Already have an account ? 
                                                        <button type="button" className="btn t-orange" onClick={() => setLoginsteps(2)}>Sign in</button>
                                                    </p>
                                                </Form>
                                            </>
                                        }
                                        { loginsteps == 2 && 
                                            <>
                                                <div className="text-center">
                                                    <h6 class="formhead mb-2">Welcome Back</h6>
                                                    <p className="content">
                                                        Fill your email address and password to sign in.
                                                    </p>
                                                </div>
                                                <Form className="mt-4">
                                                    <InputGroup className="mb-3">
                                                        <InputGroup.Text><TbUserEdit /></InputGroup.Text>
                                                        <Form.Control type="text" placeholder="Your name"></Form.Control>
                                                    </InputGroup>
                                                    <span className="logor mb-3 text-uppercase">(or)</span>
                                                    <InputGroup className="mb-4">
                                                        <InputGroup.Text><GoMail /></InputGroup.Text>
                                                        <Form.Control type="email" placeholder="Your email"></Form.Control>
                                                    </InputGroup>
                                                    <InputGroup className="mb-4">
                                                        <InputGroup.Text><MdOutlineLock /></InputGroup.Text>
                                                        <Form.Control type="password" placeholder="Password"></Form.Control>
                                                    </InputGroup>          
                                                    <div className="flexbox justify-content-between">
                                                        <div>
                                                        <Form.Check type="checkbox" label="Remember me"></Form.Check>
                                                        </div>
                                                        <div>
                                                        <Link to="/" className="logor">Forgot Password?</Link>
                                                        </div>
                                                    </div>       
                                                    <div className="mt-4 text-center">
                                                        <button type="button" className="btn btn-brand-1 sitebtn hover-up formbtn w-100">Sign In</button>
                                                    </div> 
                                                    <p className="content noteblue text-center mt-3">
                                                        Don’t have an account ?
                                                        <button type="button" className="btn t-orange" onClick={() => setLoginsteps(1)}>Sign up now</button>
                                                    </p>
                                                </Form>
                                            </>
                                        }
                                        { loginsteps == 3 && 
                                            <>
                                                <div className="text-center">
                                                    <h6 class="formhead mb-2">Choose your Wallet</h6>
                                                </div>
                                                {walletnames.map((e,i)=>
                                                    <div className="row hoverwallet align-items-center mt-4" style={{ cursor:"pointer" }} onClick={()=>{localStorage.setItem("metamask",true)}}>
                                                        <div className="col-12 col-sm-12 col-md-7 col-lg-7 mb-4 mb-md-0">
                                                            <div className="d-flex justify-content-center justify-content-md-start">
                                                                <div className="position-relative">
                                                                    <img src={e.image} className="img-fluid metaimg"/>
                                                                    <img src={ellipse} className='ellipseeimg' />
                                                                </div>   
                                                                <p className="mb-0 walletname mt-2 ms-3">{e.name}</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-sm-12 col-md-5 col-lg-5">
                                                            <div className="text-center text-md-end">
                                                                <Link className="btn outlines_btn">Connect Wallet</Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </>
                                        }
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Container>
            </Container>
        <Footer />
    </>
  )
}

export default Login;