const Images = {

// landing
backlogo :require("./../Assets/backlogo.png"),
elogo :require("./../Assets/elogo.png"),
e2logo :require("./../Assets/e2logo.png"),
bluellipse :require("./../Assets/bluellipse.png"),
derivative :require("./../Assets/derivative.png"),
fb :require("../Assets/fbs.png"),
tele :require("../Assets/teles.png"),
gits :require("../Assets/gits.png"),
partner1 :require("../Assets/parner1.png"),
partner2 :require("../Assets/partner2.svg"),
partner3 :require("../Assets/partner3.svg"),
partner4 :require("../Assets/partner4.png"),
partner5 :require("../Assets/partner5.png"),
safebox :require("../Assets/safebox.png"),
profit :require("../Assets/profit.png"),
edafacelogo :require("../Assets/datass/edafacelogoss.png"),
edafacelogo3 :require("../Assets/datass/edafacelogo 3.png"),

// subheader
trading1 :require("../Assets/datass/trading1.png"),
trading2 :require("../Assets/datass/trading2.png"),
trading3 :require("../Assets/datass/trading3.png"),
trading4 :require("../Assets/datass/trading4.png"),
trading5 :require("../Assets/datass/trading5.png"),
trading6 :require("../Assets/datass/trading6.png"),
trading7 :require("../Assets/datass/trading7.png"),
docss :require("../Assets/navicon/docss.png"),
square :require("../Assets/navicon/square.png"),
login :require("../Assets/navicon/login.png"),
registration :require("../Assets/navicon/registration.png"),
wallet :require("../Assets/navicon/wallet.png"),


// wallet
meta: require( "../Assets/metamask.png"),
coin: require( "../Assets/coinbase.png"),
trust: require( "../Assets/trust.png"),


// header

eng : require('../Assets/navicon/eng.png'),
jap : require('../Assets/navicon/jap.png'),
presale : require('../Assets/navicon/presale.png'),
cointoken : require('../Assets/navicon/cointoken.png'),
exchange : require('../Assets/navicon/exchange.png'),
buyicon : require('../Assets/navicon/buy-icon.png'),
avalan : require('../Assets/navicon/avalan.png'),
docss : require('../Assets/navicon/docss.png'),
square : require('../Assets/navicon/square.png'),

// farms

busd : require('../Assets/subpages/busd.svg').default,
edaface : require('../Assets/subpages/edaface_logo.svg').default,
rocket : require('../Assets/subpages/rocket.svg').default,
calculator : require('../Assets/subpages/calculator.svg').default,

// swap

file : require('../Assets/subpages/file.svg').default,
swapimg : require('../Assets//swapicon/swapimg.png'),
eth : require('../Assets/swapicon/eth.png'),
dai : require('../Assets/swapicon/dai.png'),
bal : require('../Assets/swapicon/Bal.svg').default,
snx : require('../Assets/swapicon/snx.svg').default,
usdc : require('../Assets/swapicon/usdc.svg').default,
tab1 : require('../Assets/colortab.svg').default,
tab2 : require('../Assets/redtab.svg').default,
tab3 : require('../Assets/greentab.svg').default,
swaplr : require('../Assets/swapicon/swaplr.png'),
searchicon : require('../Assets/searchicon.png'),
leaderbrd : require('../Assets/leaderbrd.png'),
liq : require('../Assets/liq.png'),
doubcoin : require('../Assets/doubcoin.png'),
apr : require('../Assets/apr.png'),
tick : require('../Assets/tick.png'),
dexlogo : require('../Assets/dexlogo.png'),
candle: require('../Assets/candlestick.png'),
stone: require('../Assets/stone.png'),
btc: require('../Assets/bitcoin.png'),
bnb: require('../Assets/bnb.png'),
settings: require('../Assets/settings.png'),
ticksss: require('../Assets/ticksss.png'),
ticksss1: require('../Assets/ticksss1.png'),
walletplus: require('../Assets/walletplus.png'),
walletminus: require('../Assets/walletminus.png'),
setimg: require('../Assets/setimg.png'),
eimg: require('../Assets/eimg1.png'),
tickimg: require('../Assets/ticksimgg.png'),
// congrats

congratsimg: require('../Assets/congrats.png'),
star: require('../Assets/star.png'),

// sociallinks
chatimg: require('../Assets/socials/chat.png'),
facebookimg: require('../Assets/socials/facebook.png'),
instaimg: require('../Assets/socials/instagram.png'),
teleimg: require('../Assets/socials/telegram.png'),
twitterimg: require('../Assets/socials/twitter.png'),
youtubeimg: require('../Assets/socials/youtube.png'),
}

export default Images   