import React from "react";
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Landingpage from './Components/Landingpage/landing';
import Farmspage from './Pages/farms';
import "./App.css";
import Swap from "./Pages/Swap";
import Farms from "./Pages/farms";
import Pools from "./Pages/pools";
import Poolsbalance from "./Pages/poolsbalance";
import Assets from "./Pages/assets";
import Trade from "./Pages/trade";
import Optionpage from "./Pages/optionpage";
import Pooltypepage from "./Pages/pooltypepage";
import Margin from "./Pages/margin";

import CoinTokenSupply from "./Pages/CoinTokenSupply";
import SubmitRequest from "./Pages/SubmitRequest";
import SubmissionForm from "./Pages/SubmissionForm";
import WebsiteUrl from "./Pages/WebsiteUrl";
import Login from "./Layouts/Login";
import CoinPairPrice from "./Pages/CoinPairPrice";
import ViewPools from "./Pages/ViewPools";
import P2pTrade from "./Pages/P2pTrade";
import P2pcreate from "./Pages/p2pcreate";
import Bridges from "./Pages/bridges";
import Earn from "./Pages/Earn";
import Advertisedetail from "./Pages/p2padvertiserdetail";
import Ponds from "./Pages/ponds";
import Launchpad from "./Pages/Launchpad";
import LaunchpadDetails from "./Pages/LaunchpadDetails";
import Chartpage from "./Pages/chartpage";

function App() {
    
    return (
        <div className="App">

            <BrowserRouter >
            <Routes>
              <Route path="/" element={<Landingpage />} />
              <Route path="/farms" element={<Farms />} />
              <Route path="/swap" element={<Swap />} />
              <Route path="/pools" element={<Pools />} />
              <Route path="/lendandborrow" element={<Assets />} />
              <Route path="/poolsbalance" element={<Poolsbalance />} />
              <Route path="/spot" element={<Trade />} />
              <Route path="/option" element={<Optionpage />} />
              <Route path="/pooltype" element={<Pooltypepage />} />
              <Route path="/future" element={<Margin />} />
              <Route path="/cointoken" element={<CoinTokenSupply />} />
              <Route path="/submit-request" element={<SubmitRequest />} />
              <Route path="/submission-form" element={<SubmissionForm />} />
              <Route path="/websiteurl" element={<WebsiteUrl />} />
              <Route path="/login" element={<Login />} />
              <Route path="/coinpairprice" element={<CoinPairPrice />} />
              <Route path="/view-pools" element={<ViewPools />} />
              <Route path="/p2ptrade" element={<P2pTrade />} />
              <Route path="/p2pcreate" element={<P2pcreate />} />
              <Route path="/bridge" element={<Bridges />} />
              <Route path="/earn" element={<Earn />} />
              <Route path="/advertisedetail" element={<Advertisedetail />} />
              <Route path="/pond" element={<Ponds />} />
              <Route path="/launchpad" element={<Launchpad />} />
              <Route path="/launchpad-detail" element={<LaunchpadDetails />} />
              <Route path="/chartpage" element={<Chartpage />} />
            </Routes>
            </BrowserRouter>

        </div>
    );
}

export default App;