import React, { useState, useEffect } from "react";
import { Modal, Col, Container, Dropdown, Row, DropdownToggle, DropdownMenu, DropdownItem } from "react-bootstrap";
import Images from "../Components/Images";
import Networkmodals from "./networkmodal";
import Switch from "react-switch";
import { Slider } from 'rsuite';
// import "rsuite/dist/rsuite.min.css";
import 'rsuite/dist/rsuite-no-reset.min.css';
import { MdKeyboardArrowDown } from "react-icons/md";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Poolnetmodals from "./poolnetmodal";

function Longtab(props) {


    const [networkmodal, setNetworkmodal] = useState(false)
    const [types, setTypes] = useState(1)

    const [interchange, setInterchange] = useState(
        {
            id: 1, image: Images.eth, coinname: "WETH",
        }
    );
    const [intertwochange, setIntertwochange] = useState(
        {
            id: 2, image: Images.dai, coinname: "DAI"
        },
    );
    const onswap = () => {
        var changea = interchange
        var changeb = intertwochange
        setInterchange(changeb)
        setIntertwochange(changea)
    };

    const [termsTab, setTermsTab] = useState('market');
    const handleTabClick = (tab) => {
        setTermsTab(tab);
    };
    const [checked, setChecked] = useState(true);
    const handleChange = nextChecked => {
        setChecked(nextChecked);
    };

    const [value1, setValue1] = useState(4);
    const handleStyle1 = {
        color: '#fff',
        fontSize: 12,
        width: 32,
        height: 22
    };
    const labels1 = ['1x', '5x', '10x', '15x', '20x', '25x', '30x', '35x', '40x']; // Example labels
  
    const [poolnetmodal, setPoolnetmodal] = useState(false)

    return (
        <>
            {networkmodal && <Networkmodals onDismiss={() => setNetworkmodal(false)} types={types} setInterchange={(e) => setInterchange(e)} setIntertwochange={(e) => setIntertwochange(e)} interchange={interchange} />}
            {poolnetmodal && <Poolnetmodals onDismiss={() => setPoolnetmodal(false)} />}
            <div>

                <ul className="listtab d-flex gap-4 mt-4">
                    <li className={termsTab === "market" ? "active fw-600" : ""} onClick={() => handleTabClick('market')}>Market</li>
                    <li className={termsTab === "limit" ? "active fw-600" : ""} onClick={() => handleTabClick('limit')}>Limit</li>
                    <li className={termsTab === "tp" ? "active fw-600" : ""} onClick={() => handleTabClick('tp')}>TP/SL</li>
                </ul>

                <div>

                    {termsTab != "tp" &&
                        <>
                            <div className="swap__blueBox p-2 p-sm-3 rounded-4">
                                <div className="d-flex align-items-center justify-content-between">
                                    <div><h6 className="themeclr fw-600">Pay</h6><input type="text" className="form-control swap_input ps-0" value="0.00" /></div>
                                    <div>
                                        <h6 className="themeclr fw-600 text-end mb-2">Balance</h6>
                                        <Dropdown className="networkdrop text-end">
                                            <Dropdown.Toggle variant="success" id="dropdown-basic" onClick={() => { setTypes(2); setNetworkmodal(true) }}>
                                                <img src={intertwochange.image} className="imf-fluid ethimg" /> {intertwochange.coinname}
                                            </Dropdown.Toggle>
                                        </Dropdown>
                                    </div>

                                </div>
                            </div>
                            <div className="text-center swapclick minus" onClick={() => onswap()}><img src={Images.swapimg} className="imf-fluid ehtimg" /></div>
                            <div className="swap__blueBox p-2 p-sm-3 rounded-4">
                                <div className="d-flex align-items-center justify-content-between">
                                    <div><h6 className="themeclr fw-600">{props.tab}</h6><input type="text" className="form-control swap_input ps-0" value="0.00" /></div>
                                    <div>
                                        <h6 className="themeclr fw-600 text-end mb-2">Leverage: 0.50x</h6>
                                        <Dropdown className="networkdrop text-end">
                                            <Dropdown.Toggle variant="success" id="dropdown-basic" onClick={() => { setTypes(2); setNetworkmodal(true) }}>
                                                <img src={intertwochange.image} className="imf-fluid ethimg" /> {intertwochange.coinname}
                                            </Dropdown.Toggle>
                                        </Dropdown>
                                    </div>
                                </div>
                            </div></>}


                    {termsTab === "limit" &&
                        <div className="swap__blueBox p-2 p-sm-3 rounded-4 mt-3">
                            <div className="d-flex align-items-center justify-content-between">
                                <div><h6 className="themeclr fw-600">Price</h6><input type="text" className="form-control swap_input ps-0" value="0.00" /></div>
                                <div>
                                    <h6 className="themeclr fw-600 text-end mb-2">Mark: $12,543</h6>
                                    <h5 className="themeclr fw-600 text-end mb-2">USD</h5>
                                </div>
                            </div>
                        </div>}

                    {termsTab === "tp" &&
                        <>
                            <div className="swap__blueBox p-2 p-sm-3 rounded-4 mt-3">
                                <div className="d-flex align-items-center justify-content-between">
                                    <div><h6 className="themeclr fw-600">Close</h6><input type="text" className="form-control swap_input ps-0" value="0.00" /></div>
                                    <div>
                                        <h5 className="themeclr fw-600 text-end mb-0">USD</h5>
                                    </div>

                                </div>
                            </div>
                            <div className="swap__blueBox p-2 p-sm-3 rounded-4 mt-3">
                                <div className="d-flex align-items-center justify-content-between">
                                    <div><h6 className="themeclr fw-600">Price</h6><input type="text" className="form-control swap_input ps-0" value="0.00" /></div>
                                    <div>
                                        <h6 className="themeclr fw-600 text-end mb-2">Mark: $12,543</h6>
                                        <h5 className="themeclr fw-600 text-end mb-2">USD</h5>
                                    </div>
                                </div>
                            </div>
                        </>
                    }

                    {termsTab != "tp" &&
                        <>
                            <div className="d-flex mt-3 align-items-center justify-content-between">
                                <p className="themeclr mb-0">Leverage slider</p>
                                <Switch
                                    onChange={handleChange}
                                    checked={checked}
                                    offColor="#162019"
                                    onColor="#1888b7"
                                    offHandleColor="#ffff"
                                    onHandleColor="#00e7fa"
                                    height={20}
                                    width={36}
                                    handleDiameter={18}
                                    uncheckedIcon={false}
                                    checkedIcon={false}
                                    className="react-switch"
                                    id="material-switch"
                                />
                            </div>
                            {checked ?
                                <div className="mt-3 mx-2">
                                    <Slider
                                        min={2}
                                        max={labels1.length + 1}
                                        value={value1}
                                        className="custom-slider"
                                        handleStyle={handleStyle1}
                                        graduated
                                        progress
                                        onChange={(v) => setValue1(v)}
                                        renderMark={(mark) => {
                                            return mark + "x";
                                        }}
                                    />
                                </div> : <></>} </>}
                    {termsTab === "tp" &&
                        <div className="d-flex align-items-center justify-content-between pt-3"><h6 className="themeclr fw-500 mb-0">Market</h6><h6 className="themeclr fw-500 mb-0" onClick={() => setPoolnetmodal(true)} style={{ cursor: "pointer" }}> ETH-USD <MdKeyboardArrowDown /></h6></div>}
                    <div className="d-flex align-items-center justify-content-between pt-3"><h6 className="themeclr fw-500 mb-2">Pool</h6><h6 className="themeclr fw-500 mb-2" onClick={() => setPoolnetmodal(true)} style={{ cursor: "pointer" }}>EDA-USD <MdKeyboardArrowDown /></h6></div>
                    <div className="d-flex align-items-center bottom justify-content-between"><h6 className="themeclr fw-500">Collateral In</h6><h6 className="themeclr fw-500" onClick={() => setPoolnetmodal(true)} style={{ cursor: "pointer" }}>EDA-USD <MdKeyboardArrowDown /></h6></div>

                    {termsTab != "tp" &&
                        <>
                            <div className="d-flex align-items-center justify-content-between pt-3"><h6 className="themeclr fw-500 mb-2">Leverage</h6><h6 className="themeclr fw-500 mb-2">2.00x</h6></div>
                            <div className="d-flex align-items-center justify-content-between"><h6 className="themeclr fw-500 mb-2">Entry Price</h6><h6 className="themeclr fw-500 mb-2">$1.896</h6></div>
                            <div className="d-flex align-items-center bottom justify-content-between"><h6 className="themeclr fw-500">Liq. Price</h6><h6 className="themeclr fw-500">_</h6></div>
                            <div className="d-flex align-items-center justify-content-between pt-3"><h6 className="themeclr fw-500">Fees (Rebated) and Price Impact</h6><h6 className="themeclr fw-500">-$2.75</h6></div>
                        </>
                    }
                    {termsTab === "tp" &&
                        <>
                            <div className="d-flex align-items-center justify-content-between pt-3"><h6 className="themeclr fw-500 mb-2">Trigger Price</h6><h6 className="themeclr fw-500 mb-2">$0.00</h6></div>
                            <div className="d-flex align-items-center bottom justify-content-between"><h6 className="themeclr fw-500">Execution Price</h6><h6 className="themeclr fw-500">_</h6></div>
                            <div className="d-flex align-items-center justify-content-between pt-3"><h6 className="themeclr fw-500 mb-2">Fees and Price Impact</h6><h6 className="themeclr fw-500 mb-2">-</h6></div>
                            <div className="d-flex align-items-center justify-content-between">
                                <OverlayTrigger
                                    placement="top"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={<Tooltip id="button-tooltip">
                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s
                                    </Tooltip>}
                                >
                                    <h6 className="themeclr fw-500 underline">Execution Price</h6></OverlayTrigger>
                                <OverlayTrigger
                                    placement="top"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={<Tooltip id="button-tooltip">
                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s
                                    </Tooltip>}
                                >
                                    <h6 className="themeclr fw-500 underline">-$0.20</h6></OverlayTrigger></div>
                        </>

                    }


                    <button className="btn btn-brand-1 sitebtn hover-up w-100 mt-3">Connect Wallet</button>

                    <div className="fundclr rounded-4 mt-3">
                        <h5 className="blkclr bottom fw-600 py-3 px-3">{props.tab} EDA</h5>
                        <div className="px-3 py-2">
                            <div className="d-flex align-items-center justify-content-between"><h6 className="themeclr fw-500 mb-3">Market</h6><h6 className="themeclr fw-500 mb-3">EDA/USD [EDA-USD]</h6></div>
                            <div className="d-flex align-items-center justify-content-between"><h6 className="themeclr fw-500 mb-3">Entry Price</h6>
                                <OverlayTrigger
                                    placement="left"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={<Tooltip id="button-tooltip">
                                        <div className="py-2 px-2">
                                            The position will be opened at a reference price of $2,982.63, not accounting for price impact, with a max slippage of -0.30%.
                                            <br /><br />
                                            The slippage amount can be configured under Settings, found by clicking on your address at the top right of the page after connecting your wallet.
                                        </div>
                                    </Tooltip>}
                                >
                                    <h6 className="themeclr fw-500 underline mb-3">-2.75</h6>
                                </OverlayTrigger>
                            </div>
                            <div className="d-flex align-items-center justify-content-between"><h6 className="themeclr fw-500 mb-3">Exit Price</h6>
                                <OverlayTrigger
                                    placement="left"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={<Tooltip id="button-tooltip">
                                        Simple tooltip
                                    </Tooltip>}
                                >
                                    <h6 className="themeclr fw-500 underline mb-3">-2.75</h6>
                                </OverlayTrigger>
                            </div>
                            <div className="d-flex align-items-center justify-content-between"><h6 className="themeclr fw-500 mb-3">Borrow Fee</h6>
                                <OverlayTrigger
                                    placement="left"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={<Tooltip id="button-tooltip">
                                        Simple tooltip
                                    </Tooltip>}
                                >
                                    <h6 className="themeclr fw-500 underline mb-3">-0.0052% / 1h</h6>
                                </OverlayTrigger>
                            </div>
                            <div className="d-flex align-items-center justify-content-between"><h6 className="themeclr fw-500 mb-3">{props.tab == "Long" ? "Funding Fee" : "Funding Debate"}</h6>
                                <OverlayTrigger
                                    placement="left"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={<Tooltip id="button-tooltip">
                                        Simple tooltip
                                    </Tooltip>}
                                >
                                    <h6 className="themeclr fw-500 underline mb-3">-0.0052% / 1h</h6>
                                </OverlayTrigger>
                            </div>
                            <div className="d-flex align-items-center justify-content-between"><h6 className="themeclr fw-500 mb-3">Available Liquidity</h6>
                                <OverlayTrigger
                                    placement="left"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={<Tooltip id="button-tooltip">
                                        Simple tooltip
                                    </Tooltip>}
                                >
                                    <h6 className="themeclr fw-500 underline mb-3">-0.0052% / 1h</h6>
                                </OverlayTrigger>
                            </div>
                            <div className="d-flex align-items-center justify-content-between"><h6 className="themeclr fw-500 mb-3">Open Interest Balance</h6>
                                <OverlayTrigger
                                    placement="left"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={<Tooltip id="button-tooltip">
                                        <div className="px-0 py-2">
                                            <div className="d-flex mb-3">
                                                <p className="mb-0">Long Open Interest</p>
                                                <div>
                                                    <p className="mb-0">$14,356</p>
                                                    <p className="mb-0">(23.3%)</p>
                                                </div>
                                            </div>
                                            <div className="d-flex">
                                                <p className="mb-0">Short Open Interest</p>
                                                <div>
                                                    <p className="mb-0">$14,356</p>
                                                    <p className="mb-0">(23.3%)</p>
                                                </div>
                                            </div></div>
                                    </Tooltip>}
                                >
                                    <p className="underline"><span className="redclr fw-500 me-2">3.87</span><span className="grnclr fw-500">3.87</span></p>
                                </OverlayTrigger>
                            </div>

                        </div>
                    </div>

                </div>



            </div >
        </>
    )
}

export default Longtab;