import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';

// Example data, make sure to replace this with your actual data
const series = {
  monthDataSeries1: {
    prices: [8107.85, 8128.0, 8122.9, 8165.5, 8340.7, 8423.7, 8423.5, 8514.3, 8481.85, 8487.7, 8506.9, 8626.2, 8668.95, 8602.3, 8607.55, 8512.9, 8496.25, 8600.65, 8881.1, 9340.85],
    dates: ['13 Nov 2017', '14 Nov 2017', '15 Nov 2017', '16 Nov 2017', '17 Nov 2017', '20 Nov 2017', '21 Nov 2017', '22 Nov 2017', '23 Nov 2017', '24 Nov 2017', '27 Nov 2017', '28 Nov 2017', '29 Nov 2017', '30 Nov 2017', '01 Dec 2017', '04 Dec 2017', '05 Dec 2017', '06 Dec 2017', '07 Dec 2017', '08 Dec 2017']
  }
};

const ApexChart = () => {
  const [chartState, setChartState] = useState({
    series: [{
      name: "ROI",
      data: series.monthDataSeries1.prices
    }],
    options: {
      chart: {
        type: 'area',
        height: 150,
        zoom: {
          enabled: false
        },
        toolbar: { show:false },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'straight'
      },
      labels: series.monthDataSeries1.dates,
      plugins: {
        tooltip: {
          enabled: false
        }
      },
      xaxis: {
        type: 'datetime',
        labels: {
            show: false,
          },
          tooltip: {
            enabled: false
          }
      },
      yaxis: {
        opposite: true,
        labels: {
            show: false,
          },
          tooltip: {
            enabled: false,
          }
      },
    //   legend: {
    //     horizontalAlign: 'left'
    //   }
    }
  });

  return (
    <div>
      <div id="chart">
        <ReactApexChart options={chartState.options} series={chartState.series} type="area" width={180} height={90} />
      </div>
      <div id="html-dist"></div>
    </div>
  );
};

export default ApexChart;
