import React, { useState, useEffect } from 'react'
import Innerheader from "../Layouts/innerheader";
import Footer from "../Layouts/footer";
import {
    Container, Dropdown, InputGroup,
    Table,
    Row,
    Col,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from "react-bootstrap";
import "../Karthick.css";
import "../inner.css";
import Images from "../Components/Images";
import Selecttokenmodal from "../Components/selecttokenmodal";
import Select from "react-select";
import { LuMinusCircle } from "react-icons/lu";
import { GoPlusCircle } from "react-icons/go";
import Createp2pmodal from '../Components/Modals/createp2pmodal';
import { MdKeyboardArrowLeft } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

function P2pcreate() {

    const [createstep, setCreatestep] = useState(1);
    const [tokennetmodal, setTokennetmodal] = useState(false);
    const [tokenchanged, setTokenchanged] = useState({});
    const [initialchange, setInitialchange] = useState(
        {
            id: 1, image: Images.eth, coinname: "WETH",
        }
    );
    const [selectbox, setSelectbox] = useState([
        {
            value: "Upi",
            label: "UPI",
        },
        {
            value: "paytm",
            label: "Paytm",
        },
        {
            value: "Google pay",
            label: "Google pay (GPay)",
        },
    ]);
    const colourStyles = {
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            // const color = chroma(data.color);
            console.log({ data, isDisabled, isFocused, isSelected });
            return {
                ...styles,
                backgroundColor: isFocused ? "rgb(151 243 255 / 49%)" : null,
                color: "#2D699B",
                fontWeight: "500"
            };
        }
    };
    const [count, setCount] = useState(0);
    const [count1, setCount1] = useState(0);
    function increment() {
        //setCount(prevCount => prevCount+=1);
        setCount(function (prevCount) {
            return (prevCount += 1);
        });
    }
    function increment1() {
        //setCount(prevCount => prevCount+=1);
        setCount1(function (prevCount) {
            return (prevCount += 1);
        });
    }
    function decrement() {
        setCount(function (prevCount) {
            if (prevCount > 0) {
                return (prevCount -= 1);
            } else {
                return (prevCount = 0);
            }
        });
    }
    function decrement1() {
        setCount1(function (prevCount) {
            if (prevCount > 0) {
                return (prevCount -= 1);
            } else {
                return (prevCount = 0);
            }
        });
    };
    const navigate = useNavigate();
    const [create, setCreate] = useState(false);

    return (
        <>
            {tokennetmodal && <Selecttokenmodal onDismiss={() => setTokennetmodal(false)} setInitialliq={(e) => setInitialchange(e)} setTokenchenge={(e) => setTokenchanged(e)} />}

            <Innerheader />

            <Container fluid className="innerpage pools_sec assetpage px-0 pos">
                <Container className="pt-1  px-xxl-3">
                    <img src={Images.backlogo} className="backlogo farms__backlogo" />
                    <div className="p2ptrade pt-2 pt-sm-4">
                    <div onClick={() => navigate(-1)}><button class="btn btn-brand-1 sitebtn hover-up mt-2 mb-4"><MdKeyboardArrowLeft className="set_icon mts"/>Back</button></div>
                        <h3 className='themeclr text-center mb-4'>Create</h3>
                        <div className='row'>
                            <div className='col-xl-6 mx-auto'>
                                <div className='netborder px-3 py-4'>
                                    {createstep === 1 &&
                                        <>
                                            <div className='mb-3'>
                                                <p className='themeclr fw-500'>Choose your currency</p>
                                                <Dropdown>
                                                    <DropdownToggle className='w-100 text-start fw-500' onClick={() => setTokennetmodal(true)}><img src={initialchange?.image} className='img-fluid ethimg me-1' /> {initialchange?.coinname}</DropdownToggle>
                                                </Dropdown>
                                            </div>
                                            <div className='row'>
                                                <div className='col-xl-6'>
                                                    <p className='themeclr fw-500'>What would you like to do?</p>
                                                    <Dropdown>
                                                        <DropdownToggle className='w-100 text-start fw-500'>Buy</DropdownToggle>
                                                        <DropdownMenu>
                                                            <DropdownItem>Buy</DropdownItem>
                                                            <DropdownItem>Sell</DropdownItem>
                                                        </DropdownMenu>
                                                    </Dropdown>
                                                </div>
                                                <div className='col-xl-6'>
                                                    <p className='themeclr fw-500'>Preferred currency</p>
                                                    <Dropdown>
                                                        <DropdownToggle className='w-100 text-start fw-500' onClick={() => setTokennetmodal(true)}><img src={initialchange?.image} className='img-fluid ethimg me-1' /> {initialchange?.coinname}</DropdownToggle>
                                                    </Dropdown>
                                                </div>
                                            </div>
                                            <div className='mb-3 mt-4'>
                                                <div className="multiselectbox">
                                                    <Select
                                                        isMulti
                                                        options={selectbox}
                                                        className="basic-multi-select"
                                                        classNamePrefix="select"
                                                        placeholder="Select Payments"
                                                        styles={colourStyles}
                                                    />
                                                </div>
                                            </div>
                                            <div className='text-center'><button class="btn btn-brand-1 sitebtn hover-up mt-3 mb-3" onClick={() => setCreatestep(2)}>Next</button></div>
                                        </>}

                                    {createstep === 2 &&
                                        <>
                                            <h5>Trade Price</h5>
                                            <p className='f-14'>Choose BNB rate you want to use</p>
                                            <div className='d-flex flex-wrap flex-sm-nowrap gap-3'>
                                                <div className='swap__blueBox rounded-3 px-3 py-3'>
                                                    <h6>Market Price</h6>
                                                    <p className='themeclr f-14 mb-0'>Your offer’s selling price will change according to the market price of BNB.</p>
                                                </div>
                                                <div className='swap__blueBox rounded-3 px-3 py-3'>
                                                    <h6>Fixed Price</h6>
                                                    <p className='themeclr f-14 mb-0'>Your offer’s selling price is locked when you create it, and won’t change with the market price.</p>
                                                </div>
                                            </div>
                                            <p className='themeclr fw-600 mt-3 mb-2'>Trade Limits</p>
                                            <div className='d-flex gap-3'>
                                                <div className='w-100'>
                                                    <p className='orgclr mb-1 fw-500'>Min</p>
                                                    <input type="text" className='form-control input_spot' placeholder='0.00' />
                                                </div>
                                                <div className='w-100'>
                                                    <p className='orgclr mb-1 fw-500'>Max</p>
                                                    <input type="text" className='form-control input_spot' placeholder='0.00' />
                                                </div>
                                            </div>
                                            <div className='d-flex justify-content-between mt-4'>
                                                <div className='option_border rounded-3 px-3 py-3'>
                                                    <p className='blkclr fw-500'>Margin (per %)</p>
                                                    <h5 className='themeclr fw-600 mb-2'><LuMinusCircle style={{ cursor: "pointer" }} onClick={decrement} /><span className='mx-3'>{count}</span><GoPlusCircle style={{ cursor: "pointer" }} onClick={increment} /></h5>
                                                </div>
                                                <div className='option_border rounded-3 px-3 py-3'>
                                                    <p className='blkclr fw-500'>Time Limits (Min)</p>
                                                    <h5 className='themeclr fw-600 mb-2'><LuMinusCircle style={{ cursor: "pointer" }} onClick={decrement1} /><span className='mx-3'>{count1}</span><GoPlusCircle style={{ cursor: "pointer" }} onClick={increment1} /></h5>
                                                </div>
                                            </div>

                                            <div className='d-flex gap-3 justify-content-center'>
                                                <button class="btn btn-brand-1 sitebtn hover-up mt-3 mb-3" onClick={() => setCreatestep(1)}>Previous</button>
                                                <button class="btn btn-brand-1 sitebtn hover-up mt-3 mb-3" onClick={() => setCreatestep(3)}>Next</button>
                                            </div>
                                        </>
                                    }

                                    {createstep === 3 &&
                                        <>
                                            <h5 className='mb-4'>Trade instruction</h5>
                                            <div className='mb-3'>
                                                <p className='themeclr mb-1 fw-500'>Label</p>
                                                <input className='form-control input_spot' type='text' />
                                            </div>
                                            <div className='mb-3'>
                                                <p className='themeclr mb-1 fw-500'>Terms</p>
                                                <input className='form-control input_spot' type='text' />
                                            </div>
                                            <div className='mb-3'>
                                                <p className='themeclr mb-1 fw-500'>Verification</p>
                                                <div class="form-check hidecheck mt-1"><input type="checkbox" class="form-check-input" id="exampleCheck1"/><label class="form-check-label" for="exampleCheck1">Require your trade partner to have verified their ID</label></div>
                                                <div class="form-check hidecheck mt-1"><input type="checkbox" class="form-check-input" id="exampleCheck1"/><label class="form-check-label" for="exampleCheck1">Require your trade partner to show their full name</label></div>
                                            </div>
                                            <div className='text-center'>
                                            <button class="btn btn-brand-1 sitebtn hover-up mt-3 mb-3" onClick={() => setCreate(true)}>Create</button>
                                            </div>
                                        </>
                                    }

                                </div>
                            </div>
                            {/* <div className='col-xl-6'>
                                <div className='netborder px-3 py-4'>
                                   <div>
                                    <h5>About this step</h5>
                                    <hr></hr>
                                    <p className='themeclr'>Start creating your offer by selecting the cryptocurrency you want to trade, whether or not you want to buy or sell, and the payment method you want to use.</p>
                                   <ul>
                                    <li className='themeclr mb-2 fw-500'>- You want to sell USDT </li>
                                    <li className='themeclr fw-500'>- You want to sell ETH </li>
                                   </ul>
                                   </div>
                                   <button class="btn btn-brand-1 sitebtn hover-up mt-3 mb-3">Next</button>

                                </div>
                            </div> */}
                        </div>

                    </div>
                    <Createp2pmodal show={create} onDismiss={() => setCreate(false)} />
                </Container>
            </Container>

            <Footer />
        </>

    )
}

export default P2pcreate;