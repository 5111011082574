import React, { useEffect, useState } from "react";
import { Button, Container, Modal, Dropdown } from 'react-bootstrap';
import { IoClose } from "react-icons/io5";
import walletbanner from "../../Assets/walletbanner.png"
import { Link } from "react-router-dom";
import Images from '../Images';
import { FaArrowRight } from "react-icons/fa6";
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import Switch from "react-switch";
import { Slider } from 'rsuite';
import 'rsuite/dist/rsuite-no-reset.min.css';

const Leveragemodals = (props) => {

    const [leveragemodal, setLeveragemodal] = useState(true);
    const [checked, setChecked] = useState(false);

    const handleChange = nextChecked => {
        setChecked(nextChecked);
    };
    const [value1, setValue1] = useState(0);
    const handleStyle1 = {
        color: '#fff',
        fontSize: 12,
        width: 32,
        height: 22
    };
    const labels1 = ['1x', '2x', '3x', '4x', '5x', '6x', '7x', '8x', '9x', '10x']; // Example labels

    return (
        <>
            <div className='walletmodal'>

                <Modal show={leveragemodal} size="md" aria-labelledby="contained-modal-title-vcenter" centered >
                    <Modal.Body className="py-4 px-3">
                        <div className="text-end mb-2"><button className='btn close me-0' onClick={() => props.onDismiss()}><IoClose /></button></div>
                        <h5 className="themeclr fw-600 mb-4">Adjust Max Leverage</h5>

                        <div className="d-flex justify-content-between">
                            <div className="d-flex">
                                <div>
                                    <img src={Images.eth} className="ethimg" />
                                    <img src={Images.usdc} className="ethimg" />
                                </div>
                                <h5 className="mb-0 thinblue ms-2">ETH/USDC</h5>
                            </div>
                            <Switch
                                onChange={handleChange}
                                checked={checked}
                                offColor="#162019"
                                onColor="#1888b7"
                                offHandleColor="#ffff"
                                onHandleColor="#00e7fa"
                                height={20}
                                width={36}
                                handleDiameter={18}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                className="react-switch"
                                id="material-switch"
                            />
                        </div>
                        <p className="themeclr f-15 fw-400 mb-0 mt-3">Once turning on the leverage ratio adjustment switch, the borrow limit will be based on the upper limit corresponding to the leverage you choose.</p>

                        {checked == true ?
                            <div className="mt-3">
                                <div className="d-flex justify-content-between mb-1"><p className="fw-600 mb-0 themeclr">Max Leverage</p><p className="fw-400 mb-0 orgclr">Currrent 10x</p></div>
                                <input type="text" className="form-control input_spot" value={value1} />
                                <div className="py-3 mx-2">
                                    <Slider
                                        min={1}
                                        max={labels1.length}
                                        value={value1}
                                        className="custom-slider"
                                        handleStyle={handleStyle1}
                                        graduated
                                        progress
                                        onChange={(v) => setValue1(v)}
                                        renderMark={(mark) => {
                                            return mark + "x";
                                        }}
                                    /></div>
                                <p className="mb-1 mt-1">Max borrowing at current leverage:</p>
                                <p className="themeclr fw-500">0.00000000 BTC or 0.00000000 USDT</p>
                                <p className="f-15 fw-500">Lowering leverage does not necessarily mean a decrease in borrow limit</p>
                            </div>
                            : <></>
                        }


                        <div className="d-flex justify-content-end pb-3">
                            <button class="btn sellbtn mt-3 py-2 me-2" onClick={() => props.onDismiss()}>Cancel</button>
                            <button class="btn btn-brand-1 sitebtn rounded-2 hover-up mt-3">Confirm</button>
                        </div>


                    </Modal.Body>
                </Modal>
            </div>
        </>
    )
}

export default Leveragemodals;