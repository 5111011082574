import React, { useState, useEffect } from "react";
import { Modal, Col, Container, Dropdown, Row, DropdownToggle, DropdownMenu, DropdownItem } from "react-bootstrap";
import Images from "../Components/Images";
import Networkmodals from "./networkmodal";

function Swaptab() {

    const [networkmodal, setNetworkmodal] = useState(false)
    const [types, setTypes] = useState(1)

    const [interchange, setInterchange] = useState(
        {
            id: 1, image: Images.eth, coinname: "WETH",
        }
    );
    const [intertwochange, setIntertwochange] = useState(
        {
            id: 2, image: Images.dai, coinname: "DAI"
        },
    );
    const onswap = () => {
        var changea = interchange
        var changeb = intertwochange
        setInterchange(changeb)
        setIntertwochange(changea)
    };

    const [termssTab, setTermssTab] = useState('market');
    const handleTabClick = (tab) => {
        setTermssTab(tab);
    };

    return (

        <div>
            {networkmodal && <Networkmodals onDismiss={() => setNetworkmodal(false)} types={types} setInterchange={(e) => setInterchange(e)} setIntertwochange={(e) => setIntertwochange(e)} interchange={interchange} />}

            <ul className="listtab d-flex gap-4 mt-4">
                <li className={termssTab === "market" ? "active fw-600" : ""} onClick={() => handleTabClick('market')}>Market</li>
                <li className={termssTab === "limit" ? "active fw-600" : ""} onClick={() => handleTabClick('limit')}>Limit</li>
            </ul>

            <div className="swap__blueBox p-2 p-sm-3 rounded-4">
                <div className="d-flex align-items-center justify-content-between">
                    <div><h6 className="themeclr fw-600">Pay</h6><input type="text" className="form-control swap_input ps-0" value="0.00" /></div>
                    <div>
                        <h6 className="themeclr fw-600 text-end mb-2">Balance</h6>
                        <Dropdown className="networkdrop text-end">
                            <Dropdown.Toggle variant="success" id="dropdown-basic" onClick={() => { setTypes(2); setNetworkmodal(true) }}>
                                <img src={intertwochange.image} className="imf-fluid ethimg" /> {intertwochange.coinname}
                            </Dropdown.Toggle>
                        </Dropdown>
                    </div>

                </div>
            </div>
            <div className="text-center swapclick minus" onClick={() => onswap()}><img src={Images.swapimg} className="imf-fluid ehtimg" /></div>
            <div className="swap__blueBox p-2 p-sm-3 rounded-4">
                <div className="d-flex align-items-center justify-content-between">
                    <div><h6 className="themeclr fw-600">Receive</h6><input type="text" className="form-control swap_input ps-0" value="0.00" /></div>
                    <div>
                        <h6 className="themeclr fw-600 text-end mb-2">Balance</h6>
                        <Dropdown className="networkdrop text-end">
                            <Dropdown.Toggle variant="success" id="dropdown-basic" onClick={() => { setTypes(2); setNetworkmodal(true) }}>
                                <img src={intertwochange.image} className="imf-fluid ethimg" /> {intertwochange.coinname}
                            </Dropdown.Toggle>
                        </Dropdown>
                    </div>

                </div>
            </div>

            {termssTab === "limit" &&
                <>
                    <div className="swap__blueBox p-2 p-sm-3 rounded-4 mt-3">
                        <div className="d-flex align-items-center justify-content-between">
                            <div><h6 className="themeclr fw-600">Price</h6><input type="text" className="form-control swap_input ps-0" value="0.00" /></div>
                            <div>
                                <h6 className="themeclr fw-600 text-end mb-2">Mark: 1000</h6>
                                <h5 className="themeclr fw-600 text-end mb-2">USD</h5>
                            </div>

                        </div>
                    </div>
                </>

            }

            <button className="btn btn-brand-1 sitebtn hover-up w-100 mt-3">Connect Wallet</button>

            <div className="fundclr rounded-4 mt-3">
                <h5 className="blkclr bottom fw-600 py-3 px-3">Swap</h5>
                <div className="px-3 py-2">
                    <div className="d-flex align-items-center justify-content-between"><h6 className="themeclr fw-500 mb-3">ETH Price</h6><h6 className="themeclr fw-500 mb-3">$2,967.26</h6></div>
                    <div className="d-flex align-items-center justify-content-between"><h6 className="themeclr fw-500 mb-3">ETH Price</h6><h6 className="themeclr fw-500 mb-3">$2,968.05</h6></div>
                    <div className="d-flex align-items-center justify-content-between"><h6 className="themeclr fw-500 mb-3">Available Liquidity</h6><h6 className="themeclr fw-500 mb-3">$0.02</h6></div>
                    <div className="d-flex align-items-center justify-content-between"><h6 className="themeclr fw-500 mb-3">Price</h6><h6 className="themeclr fw-500 mb-3">1.001 ETH/ETH</h6></div>
                </div>
            </div>


        </div>

    )
}

export default Swaptab;