import React, { useState, useEffect } from "react";
import Innerheader from "../Layouts/innerheader";
import { Col, Container, Row, Dropdown } from "react-bootstrap";
import Images from "../Components/Images";
import Footer from "../Layouts/footer";
import Networkmodals from "../Components/networkmodal";
import { IoMdSettings } from "react-icons/io";
import Settingsmodal from "../Components/Modals/settingsmodal";
import { MdOutlineAccessTime } from "react-icons/md";
import Transactionmodal from "../Components/Modals/transactionmodal";

const Swap = () => {
  const [settingmodals, setSettingmodals] = useState(false);
  const [transactionmodals, setTransactionmodals] = useState(false);
  const [networkmodal, setNetworkmodal] = useState(false)
  const [selectnetwork, setSelectnetwork] = useState({ name: "BNB Smart chain" });
  const [networklist, setNetworklist] = useState([
    { name: "BNB Smart chain" }, { name: "Ethereum" }, { name: "Avalanche" }
  ]);
  const [coinlist, setCoinlist] = useState([
    {
      id: 1, image: Images.eth, coinname: "WETH"
    },
    {
      id: 2, image: Images.dai, coinname: "DAI"
    },
    {
      id: 3, image: Images.usdc, coinname: "USDC"
    },
    {
      id: 4, image: Images.bal, coinname: "BAL"
    },
    {
      id: 5, image: Images.snx, coinname: "SNX"
    },
  ]);

  const [types, setTypes] = useState(1);

  const [interchange, setInterchange] = useState(
    {
      id: 1, image: Images.eth, coinname: "WETH",
    }
  );
  const [intertwochange, setIntertwochange] = useState(
    {
      id: 2, image: Images.dai, coinname: "DAI"
    },
  );
  const onswap = () => {
    var changea = interchange
    var changeb = intertwochange
    setInterchange(changeb)
    setIntertwochange(changea)
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (

    <>
      {settingmodals && <Settingsmodal onDismiss={() => setSettingmodals(false)} />}
      {networkmodal && <Networkmodals onDismiss={() => setNetworkmodal(false)} types={types} setInterchange={(e) => setInterchange(e)} setIntertwochange={(e) => setIntertwochange(e)} interchange={interchange} />}
      {transactionmodals && <Transactionmodal onDismiss={() => setTransactionmodals(false)} />}

      <div>
        <Innerheader />

        <Container fluid className="p-0 farms__whole">
          <div className="swap__page position-relative pb-5">
            <img src={Images.backlogo} className="backlogo farms__backlogo" />
            <img src={Images.elogo} className="elogo farms__elogo" />

            <Container className="">
              {/* <div className="farms__table_holder rounded-4 px-3 py-3 py-sm-5 px-sm-4 p-xxl-5 pos"> */}
             
             <div>
                <img src={Images.bluellipse} className="img-fluid rounde_circle1" />
                <Row className="justify-content-center">
                  <Col lg={6} xl={6} xxl={5} className="pos">
                    <img src={Images.bluellipse} className="img-fluid rounde_circle" />
                    <div className="farms__table_holder rounded-4 py-4 px-3 px-sm-4">

                      <div className="d-flex justify-content-between align-items-center mb-3">
                        <p className="swap__leftTitle m-0 poppins_font">Swap</p>
                        <div>
                          <IoMdSettings className="set_icon themeclr cursor" onClick={() => setSettingmodals(true)} />
                          <MdOutlineAccessTime className="set_icon themeclr cursor ms-2" onClick={() => setTransactionmodals(true)} /></div>
                        {/* <div className="d-flex justify-content-end align-items-center gap-2">
                        <p className="swap__leftTitle m-0">
                          <i class="fa-solid fa-gear" />
                        </p>
                        <p className="swap__leftTitle m-0">
                          <i class="fa-solid fa-clock-rotate-left" />
                        </p>
                      </div> */}
                      </div>

                      <div className="swap__blueBox p-2 p-sm-3 rounded-4">
                        <div className="d-block d-sm-flex align-items-center justify-content-between">
                          <div className="d-flex justify-content-center justify-sm-content-left mb-2 mb-sm-0">
                            <img src={Images.file} className="imf-fluid me-2" />
                            <p className="m-0 poppins_font swap__fileValue">0.006895</p>
                          </div>
                          <Dropdown className="chaindrop text-center text-sm-left">
                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                              From <span>{selectnetwork.name}</span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              {networklist.map((e, i) =>
                                <Dropdown.Item onClick={() => setSelectnetwork(e)}>{e.name}</Dropdown.Item>
                              )}
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                        <div className="d-flex align-items-center justify-content-between mt-3 mb-2 mb-sm-0">
                          <input type="text" className="form-control swap_input" value="0.00" placeholder="" />
                          <Dropdown className="networkdrop">
                            <Dropdown.Toggle variant="success" id="dropdown-basic"
                              onClick={() => { setTypes(1); setNetworkmodal(true) }}>
                              <img src={interchange.image} className="imf-fluid ethimg" /> {interchange.coinname}
                            </Dropdown.Toggle>
                            {/* <Dropdown.Menu>
                            {coinlist.map((e, i) =>
                              <Dropdown.Item className={e.id == interchange.id ? "hideclass" : ""} onClick={() => { setInterchange(e) }}> <img src={e.image} className="imf-fluid ethimg" /> {e.coinname}</Dropdown.Item>
                            )}
                          </Dropdown.Menu> */}
                          </Dropdown>
                        </div>
                      </div>

                      <div className="text-center my-3 swapclick" onClick={() => onswap()}><img src={Images.swapimg} className="imf-fluid ehtimg" /></div>

                      <div className="swap__blueBox p-2 p-sm-3 rounded-4">
                        <div className="d-block d-sm-flex align-items-center justify-content-between">
                          <div className="d-flex justify-content-center justify-sm-content-left mb-2 mb-sm-0">
                            <img src={Images.file} className="imf-fluid me-2" />
                            <p className="m-0 poppins_font swap__fileValue">0.006895</p>
                          </div>
                          <Dropdown className="chaindrop text-center text-sm-left">
                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                              From  <span>{selectnetwork.name}</span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              {networklist.map((e, i) =>
                                <Dropdown.Item onClick={() => setSelectnetwork(e)}>{e.name}</Dropdown.Item>
                              )}
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                        <div className="d-flex align-items-center justify-content-between mt-3 mb-2 mb-sm-0">
                          <input type="text" className="form-control swap_input" value="0.00" />
                          <Dropdown className="networkdrop">
                            <Dropdown.Toggle variant="success" id="dropdown-basic" onClick={() => { setTypes(2); setNetworkmodal(true) }}>
                              <img src={intertwochange.image} className="imf-fluid ethimg" /> {intertwochange.coinname}
                            </Dropdown.Toggle>
                            {/* <Dropdown.Menu>
                            {coinlist.map((e, i) =>
                              <Dropdown.Item disabled={e.id == interchange.id} onClick={() => setIntertwochange(e)}><img src={e.image} className="imf-fluid ethimg" /> {e.coinname}</Dropdown.Item>
                            )}
                          </Dropdown.Menu> */}
                          </Dropdown>
                        </div>
                      </div>

                      <div className="swap__blueBox p-3 rounded-4 mt-3">
                        <p className="mb-0 fw-500">1 BNB = 267.58794  TWT <img src={Images.swaplr} className="img-fluid" /></p>
                      </div>

                      <button class="btn btn-brand-1 sitebtn hover-up mt-3 mb-3 w-100">Continue</button>

                      <div className="d-flex justify-content-between"><p className="themeclr">Provider</p><p className="themeclr fw-600">1 inch</p></div>
                      <div className="d-flex justify-content-between"><p className="themeclr">Minimum received</p><p className="themeclr fw-600">0TWT</p></div>
                      <div className="d-flex justify-content-between"><p className="themeclr">Provider Fee</p><p className="themeclr fw-600">1.985245TWT (=$2.5)</p></div>
                      <div className="d-flex justify-content-between"><p className="themeclr">Slippage tolerance</p><p className="themeclr fw-600">1.0%</p></div>

                    </div>
                  </Col>
                </Row>
              </div>
            </Container>
          </div>
        </Container>




        <Footer />
      </div>
    </>
  );
};

export default Swap;
