import React, { useState, useEffect } from "react";
import Innerheader from "../Layouts/innerheader";
import Footer from "../Layouts/footer";
import { Col, Container, Row, Form, Table } from "react-bootstrap";
import "../Karthick.css";
import "../inner.css";
import Images from "../Components/Images";
import { GoPlus } from "react-icons/go";
import { Link } from "react-router-dom";

const CoinTokenSupply = () => {

    const [steps, setSteps] = useState(1);
    const [yes, setYes] = useState(false);
    const [yestwo, setYestwo] = useState(true);
    const [yesthree, setYesthree] = useState(true);
    const [yesfour, setYesfour] = useState(false);
    const [yesfive, setYesfive] = useState(true);
    const [yessix, setYessix] = useState(true);
    const [addimg, setAddimg] = useState("");
    const [addimgtwo, setAddimgtwo] = useState("");

    const yesfourfunc = () => {
        setYesfour(!yesfour);
        setYesthree(!yesthree);
    }

    const imgchange = (e) => {
        setAddimg(URL.createObjectURL(e.target.files[0]));
    }

    const imgchangetwo = (e) => {
        setAddimgtwo(URL.createObjectURL(e.target.files[0]));
    }

    const scoretablehead = ["Total Score", "Percentage", "Investment Interpretation"];
    const [scoretable, setScoretable] = useState([
        {
            tscore: "0 – 25.0",
            percent: "0 – 20",
            invest: "Bad Investment"
        },
        {
            tscore: "25.1 - 50.0",
            percent: "21 – 40",
            invest: "Cautious Investment"
        },
        {
            tscore: "50.1 – 75.0",
            percent: "41 – 60",
            invest: "Good Investment"
        },
        {
            tscore: "75.1 – 100.0",
            percent: "61 – 80",
            invest: "Very Good Investment"
        },
        {
            tscore: "100.1 – 125.0",
            percent: "81 – 100",
            invest: "Awesome Investment"
        }
    ]);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    }, [steps]);

  return (
    <>
        <Innerheader />
            <Container fluid className="innerpage pools_sec assetpage px-0 pos">
                <Container className="pt-5">
                    <img src={Images.backlogo} className='backlogo farms__backlogo' />
                    <div className="cointoken">
                        { steps == 1 &&
                            <div className="formbox">
                                <div className="text-center">
                                    <h6 className="formhead">Coin/Token Supply</h6>
                                    <p className="content">
                                        Lorem ipsum dolor sit amet consectetur. Amet placerat varius sit aliquet a pellentesque posuere. Sed pulvinar molestie in purus sit congue. Massa nulla condimentum enim turpis viverra sit at.
                                    </p>
                                </div>
                                <Form>
                                    <Row className="justify-content-between">
                                        <Col xs={12} md={12} lg={6} xl={5}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Maximum Supply</Form.Label>
                                                <Form.Control type="text" placeholder="Maximum Supply of your coin/token"></Form.Control>
                                            </Form.Group>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Burnt Amount</Form.Label>
                                                <Form.Control type="text" placeholder="How much coin/token has been burnt"></Form.Control>
                                            </Form.Group>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Total Supply <span className="text-danger">*</span></Form.Label>
                                                <Form.Control type="text" placeholder="Lorem ipsum"></Form.Control>
                                            </Form.Group>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Locked Amount <span className="text-danger">*</span></Form.Label>
                                                <Form.Control type="text" placeholder="Lorem ipsum"></Form.Control>
                                            </Form.Group>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Amount of coins/tokens <span className="text-danger">*</span></Form.Label>
                                                <Form.Control type="text" placeholder="Lorem ipsum"></Form.Control>
                                            </Form.Group>
                                            <Form.Group className="mb-3">
                                                <Form.Label>The amount of Team allocation</Form.Label>
                                                <Form.Control type="text" placeholder="Lorem ipsum"></Form.Control>
                                            </Form.Group>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Amount of any other coins/tokens</Form.Label>
                                                <Form.Control type="text" placeholder="Lorem ipsum"></Form.Control>
                                            </Form.Group>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Total Locked Amount</Form.Label>
                                                <Form.Control type="text" placeholder="Lorem ipsum"></Form.Control>
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} md={12} lg={6} xl={5}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>blockchain URL</Form.Label>
                                                <Form.Control type="text" placeholder="Supply the blockchain URL"></Form.Control>
                                            </Form.Group>
                                            <Form.Group className="mb-3">
                                                <Form.Label>blockchain URL</Form.Label>
                                                <Form.Control type="text" placeholder="Supply the blockchain URL"></Form.Control>
                                            </Form.Group>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Unknown Maximum Coin Supply</Form.Label>
                                                <Form.Control type="text" placeholder="Lorem ipsum"></Form.Control>
                                            </Form.Group>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Unknown Maximum Coin Supply</Form.Label>
                                                <Form.Control type="text" placeholder="Lorem ipsum"></Form.Control>
                                            </Form.Group>
                                            <Form.Group className="mb-3">
                                                <Form.Label>URL Link</Form.Label>
                                                <Form.Control type="text" placeholder="Lorem ipsum"></Form.Control>
                                                <span className="add"><GoPlus /></span>
                                            </Form.Group>
                                            <Form.Group className="mb-3">
                                                <Form.Label>URL link or wallet address</Form.Label>
                                                <Form.Control type="text" placeholder="Lorem ipsum"></Form.Control>
                                            </Form.Group>
                                        </Col>
                                    </Row>       
                                    <div className="mt-4 text-center">
                                        <button type="button" className="btn btn-brand-1 sitebtn hover-up formbtn" onClick={() => setSteps(2)}>Next</button>
                                    </div>            
                                </Form>                            
                            </div>
                        }
                        { steps == 2 &&
                            <div className="formbox formboxcenter">
                                <div className="backbtn">
                                    <button type="button" className="btn btn-brand-1 sitebtn hover-up formbtn" onClick={() => setSteps(1)}>Back</button>
                                </div>
                                <div className="text-center">
                                    <h6 className="formhead">Website URL</h6>
                                    <p className="content">
                                        Please, indicate your project’s website. If you have more than one website, Website URL (1) should  be the Primary Website.
                                    </p>
                                </div>
                                <Form>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Listed Centralised Exchanges<span className="text-danger">*</span></Form.Label>
                                        <Form.Control type="text" placeholder="Provide the URL link"></Form.Control>
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Listed Decentralised Exchanges</Form.Label>
                                        <Form.Control type="text" placeholder="Provide the URL link"></Form.Control>
                                    </Form.Group>     
                                    <div className="mt-4 text-center">
                                        <button type="button" className="btn btn-brand-1 sitebtn hover-up formbtn" onClick={() => setSteps(3)}>Next</button>
                                    </div>            
                                </Form>                            
                            </div>
                        }
                        { steps == 3 && 
                            <div className="formbox formboxcenter">
                                <div className="backbtn">
                                    <button type="button" className="btn btn-brand-1 sitebtn hover-up formbtn" onClick={() => setSteps(2)}>Back</button>
                                </div>
                                <div className="text-center">
                                    <h6 className="formhead">Liquidity Supply</h6>
                                    <p className="content">
                                        Please, indicate your project’s website. If you have more than one website, Website URL (1) should  be the Primary Website.
                                    </p>
                                </div>
                                <Form>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Provide url (1)</Form.Label>
                                        <Form.Control type="text" placeholder="How much liquidity was supplied"></Form.Control>
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Provide url (2)</Form.Label>
                                        <Form.Control type="text" placeholder="How much liquidity was supplied"></Form.Control>
                                    </Form.Group>     
                                    <Form.Group className="mb-3">
                                        <Form.Label>Provide url (3)</Form.Label>
                                        <Form.Control type="text" placeholder="How much liquidity was supplied"></Form.Control>
                                    </Form.Group>     
                                    <Form.Group className="mb-3">
                                        <Form.Label>Time-Lock<span className="text-danger">*</span></Form.Label>
                                        <Form.Control type="text" placeholder="Lorem ipsum"></Form.Control>
                                    </Form.Group>     
                                    <Form.Group className="mb-3">
                                        <Form.Label className="mb-3">Any time-lock in contract<span className="text-danger">*</span></Form.Label>
                                        <div className="flexbox justify-content-between">
                                            <Form.Check type="checkbox" label="Yes" onClick={() => setYes(!yes)}></Form.Check>
                                            <Form.Check type="checkbox" label="No"></Form.Check>
                                            <Form.Check type="checkbox" label="Not Applicable"></Form.Check>
                                        </div>
                                    </Form.Group>   
                                    {
                                        yes == true && 
                                        <Form.Group className="mb-3">
                                            <Form.Label>Provide url</Form.Label>
                                            <Form.Control type="text" placeholder="Lorem ipsum"></Form.Control>
                                        </Form.Group>  
                                    } 
                                    <div className="mt-5 text-center">
                                        <button type="button" className="btn btn-brand-1 sitebtn hover-up formbtn" onClick={() => setSteps(4)}>Next</button>
                                    </div>            
                                </Form>                            
                            </div>
                        }
                        { steps == 4 &&
                            <div className="formbox formboxcenter">
                                <div className="backbtn">
                                    <button type="button" className="btn btn-brand-1 sitebtn hover-up formbtn" onClick={() => setSteps(3)}>Back</button>
                                </div>
                                <div className="text-center">
                                    <h6 className="formhead">Ownership Wallet</h6>
                                </div>
                                <Form>    
                                    <Form.Group className="mb-3">
                                        <Form.Label>Owner’s wallet url<span className="text-danger">*</span></Form.Label>
                                        <Form.Control type="text" placeholder="Insert Owner’s wallet url"></Form.Control>
                                        <span className="d-block mt-3 text-end">(Or)</span>
                                    </Form.Group>     
                                    <Form.Group className="mb-4">
                                        <Form.Label>Contract Address<span className="text-danger">*</span></Form.Label>
                                        <Form.Control type="text" placeholder="5435ghugyrtufjgvgilu897"></Form.Control>
                                    </Form.Group>     
                                    <Form.Group className="mb-4">
                                        <Form.Label className="mb-3">Coin/Token Renunciation<span className="text-danger">*</span></Form.Label>
                                        <Row>
                                            <Col xs={12} md={3} lg={3} className="mb-3 mb-sm-0">
                                                <Form.Check type="checkbox" label="Yes" onClick={() => setYestwo(!yestwo)}></Form.Check>
                                            </Col>
                                            <Col xs={12} md={3} lg={3}>
                                                <Form.Check type="checkbox" label="No"></Form.Check>
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                    { yestwo == false &&
                                        <Form.Group>
                                            <Form.Label>provide the ownership renunciation URL<span className="text-danger">*</span></Form.Label>
                                            <Form.Control type="text" placeholder="Insert Owner’s wallet url"></Form.Control>
                                        </Form.Group>  
                                    }
                                    <div className="mt-5 text-center">
                                        <button type="button" className="btn btn-brand-1 sitebtn hover-up formbtn w-100" onClick={() => setSteps(5)} disabled={yestwo}>Next</button>
                                    </div>            
                                </Form>                            
                            </div>
                        }
                        { steps == 5 &&
                            <div className="formbox formboxcenter">
                                <div className="backbtn">
                                    <button type="button" className="btn btn-brand-1 sitebtn hover-up formbtn" onClick={() => setSteps(4)}>Back</button>
                                </div>
                                <div className="text-center">
                                    <h6 className="formhead">Audit Details</h6>
                                    <p className="content">
                                        An audit of a Project establishes reputability as well as reliability, hence, determining how the  Market will react to the crypto project.
                                    </p>
                                </div>
                                <Form>    
                                    <Form.Group className="mb-4">
                                        <Form.Label className="mb-3">Has your Project been audited?</Form.Label>
                                        <Row>
                                            <Col xs={12} md={3} lg={3} className="mb-3 mb-sm-0">
                                                <Form.Check type="checkbox" label="Yes" onClick={() => setYesthree(!yesthree)}></Form.Check>
                                            </Col>
                                            <Col xs={12} md={3} lg={3}>
                                                <Form.Check type="checkbox" label="No" onClick={yesfourfunc}></Form.Check>
                                            </Col>
                                        </Row>
                                    </Form.Group>                                    
                                    { yesfour == false ?
                                        <>
                                            <Form.Group className="mb-4">
                                                <Form.Label>Project been audited by any of the followings?</Form.Label>
                                                <Form.Select className="form-control">
                                                    <option value="1">Certik</option>
                                                    <option value="2">Hacken</option>
                                                    <option value="3">ConsenSys</option>
                                                </Form.Select>
                                            </Form.Group>     
                                            <Form.Group className="mb-4">
                                                <Form.Label>If yes, supply the audit URL link</Form.Label>
                                                <Form.Control type="text" placeholder="lorem ipsum"></Form.Control>
                                                <span className="add"><GoPlus /></span>
                                            </Form.Group>                                             
                                        </>     
                                        :
                                        <>
                                            <Form.Group className="mb-4">
                                                <Form.Label>Name of the Auditor</Form.Label>
                                                <Form.Control type="text" placeholder="lorem ipsum"></Form.Control>
                                            </Form.Group> 
                                            <Form.Group className="mb-4">
                                                <Form.Label>Audit URL link</Form.Label>
                                                <Form.Control type="text" placeholder="lorem ipsum"></Form.Control>
                                            </Form.Group>
                                        </>                                   

                                    }
                                    <div className="mt-5 text-center">
                                        <button type="button" className="btn btn-brand-1 sitebtn hover-up formbtn w-100" onClick={() => setSteps(6)} disabled={yesthree}>Next</button>
                                    </div>            
                                </Form>                            
                            </div>
                        }
                        { steps == 6 && 
                            <div className="formbox formboxcenter">
                                <div className="backbtn">
                                    <button type="button" className="btn btn-brand-1 sitebtn hover-up formbtn" onClick={() => setSteps(5)}>Back</button>
                                </div>
                                <div className="text-center">
                                    <h6 className="formhead">Source Code</h6>
                                    <p className="content">
                                        It seeks to establish transparency and reliability of the crypto project.
                                    </p>
                                </div>
                                <Form>    
                                    <Form.Group className="mb-4">
                                        <Form.Label className="mb-3">
                                            Does the Crypto Project have an accessible Source Code?
                                        </Form.Label>
                                        <Row>
                                            <Col xs={12} md={3} lg={3} className="mb-3 mb-sm-0">
                                                <Form.Check type="checkbox" label="Yes" onClick={() => setYesfive(!yesfive)}></Form.Check>
                                            </Col>
                                            <Col xs={12} md={3} lg={3}>
                                                <Form.Check type="checkbox" label="No" onClick={() => setYesfive(!yesfive)}></Form.Check>
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                    <Form.Group className="mb-4">
                                        <Form.Label>If yes, supply the URL link?</Form.Label>
                                        <Form.Control type="text" placeholder="lorem ipsum"></Form.Control>
                                    </Form.Group>
                                    <div className="mt-5 text-center">
                                        <button type="button" className="btn btn-brand-1 sitebtn hover-up formbtn w-100" onClick={() => setSteps(7)} disabled={yesfive}>Next</button>
                                    </div>            
                                </Form>                            
                            </div>

                        }
                        { steps == 7 &&
                            <div className="formbox formboxcenter">
                                <div className="backbtn">
                                    <button type="button" className="btn btn-brand-1 sitebtn hover-up formbtn" onClick={() => setSteps(6)}>Back</button>
                                </div>
                                <div className="text-center">
                                    <h6 className="formhead">Project Team</h6>
                                    <Link to="/" className="anchorlink mb-3">
                                        Click here for more information.
                                    </Link>
                                    <button type="button" className="btn anchorlink text-decoration-underline" onClick={() => setYessix(false)}>
                                       <b>Click to add</b>
                                    </button>
                                </div>
                                { yessix == false &&
                                    <Form>
                                        <Form.Group className="mb-4">
                                            <Form.Label>Official Names</Form.Label>
                                            <Form.Control type="text" placeholder="How much liquidity was supplied"></Form.Control>
                                        </Form.Group> 
                                        <Form.Group className="mb-4">
                                            <Form.Label>National ID Number or Passport number</Form.Label>
                                            <Form.Control type="text" placeholder="How much liquidity was supplied"></Form.Control>
                                        </Form.Group>
                                        <Form.Group className="mb-4">
                                            <Form.Label>Project Position/Title, e.g. CEO, CTO, etc.</Form.Label>
                                            <Form.Control type="text" placeholder="How much liquidity was supplied"></Form.Control>
                                            </Form.Group>
                                        <div className="flexbox justify-content-between mb-4">
                                            <div>
                                                <Form.Check type="checkbox" label="Advisory Member"></Form.Check>
                                            </div>
                                            <div>
                                                <Form.Check type="checkbox" label="Core Team Member"></Form.Check>
                                            </div>
                                        </div>
                                        <Form.Group className="mb-4">
                                            <Form.Label>LinkedIn account</Form.Label>
                                            <Form.Control type="text" placeholder="How much liquidity was supplied"></Form.Control>
                                        </Form.Group>
                                        <Form.Group className="mb-4">
                                            <Form.Label>Country where ID was Issued</Form.Label>
                                            <Form.Control type="text" placeholder="benin"></Form.Control>
                                        </Form.Group>
                                        <Form.Group>
                                            <div className="flexbox align-items-start justify-content-between">
                                                <div>
                                                    <Form.Label>Member Image</Form.Label>
                                                    <img src={addimg} alt="Member" className="img-fluid memberimg" />
                                                </div>
                                                <div className="addimg">
                                                    <input type="file" onChange={imgchange} accept="image/*" className="form-control" />
                                                    <div className="addimgbox">
                                                        Click to add
                                                    </div>
                                                </div>
                                            </div>
                                        </Form.Group>
                                    </Form>
                                }
                                <div className="mt-5 text-center">
                                    <button type="button" className="btn btn-brand-1 sitebtn hover-up formbtn w-100" onClick={() => setSteps(8)} disabled={yessix}>Next</button>
                                </div>  
                            </div>
                        }
                        { steps == 8 && 
                            <div className="formbox companydet">
                                <div className="backbtn">
                                    <button type="button" className="btn btn-brand-1 sitebtn hover-up formbtn" onClick={() => setSteps(7)}>Back</button>
                                </div>
                                <div className="text-center">
                                    <h6 className="formhead">Company Details</h6>
                                    <p className="content">
                                        EdaFace will need to confirm which company owns the Project you are submitting.
                                    </p>
                                </div>
                                <Form>
                                    <Row className="justify-content-between">
                                        <Col xs={12} md={12} lg={4} xl={4}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Registered Company name<span className="text-danger">*</span></Form.Label>
                                                <Form.Control type="text" placeholder="Lorem ipsum"></Form.Control>
                                            </Form.Group>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Country of Registration<span className="text-danger">*</span></Form.Label>
                                                <Form.Select className="form-control">
                                                    <option value="1">Lorem ipsum</option>
                                                    <option value="2">Lorem ipsum</option>
                                                    <option value="3">Lorem ipsum</option>
                                                </Form.Select>
                                            </Form.Group>
                                            <Form.Group className="mb-3">
                                                <div className="flexbox justify-content-between">
                                                    <div>
                                                        <Form.Label className="mb-0">Registered Names of Directors<span className="text-danger">*</span></Form.Label>
                                                    </div>
                                                    <div>
                                                        <button type="button" className="btn anchorlink text-decoration-underline">
                                                            <b>Click to add</b>
                                                        </button>
                                                    </div>
                                                </div>                                                
                                            </Form.Group>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Proof of Company Registration Certificate</Form.Label>
                                                <p className="content">
                                                    For Proof of Registration, you will need to upload a copy of Company Registration Certificate. Upload a copy of Company Registration Certificate
                                                </p>
                                                <div className="text-end mt-3">
                                                    <button type="button" className="btn anchorlink text-decoration-underline">
                                                        <b>Upload a copy</b>
                                                    </button>
                                                </div>
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} md={12} lg={4} xl={4}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Company Trade name</Form.Label>
                                                <Form.Control type="text" placeholder="Lorem ipsum"></Form.Control>
                                            </Form.Group>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Website of Country's Registration body<span className="text-danger">*</span></Form.Label>
                                                <Form.Control type="text" placeholder="Lorem ipsum"></Form.Control>
                                            </Form.Group>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Company Official Email address<span className="text-danger">*</span></Form.Label>
                                                <Form.Control type="text" placeholder="Lorem ipsum"></Form.Control>
                                                <p className="content t-orange mt-2">
                                                    ** Note that only email address of the Project website is accepted, e.g. sales@company.com. No  public email such as yahoo mail, gmail, etc. will be accepted. **
                                                </p>
                                                <p className="content mt-2">
                                                    Verification of company’s email address will be done via email OTP.
                                                </p>
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} md={12} lg={4} xl={4}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Company Registration Number<span className="text-danger">*</span></Form.Label>
                                                <Form.Control type="text" placeholder="lorem ipsum"></Form.Control>
                                            </Form.Group>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Date of Registration<span className="text-danger">*</span></Form.Label>
                                                <Form.Control type="text" placeholder="lorem ipsum"></Form.Control>
                                            </Form.Group>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Company Official Phone number</Form.Label>
                                                <Form.Control type="text" placeholder="Lorem ipsum"></Form.Control>
                                                <p className="content mt-2">
                                                    Verification of company’s phone number will be done via SMS OTP.
                                                </p>
                                            </Form.Group>
                                        </Col>
                                    </Row>       
                                    <div className="mt-5 text-center">
                                        <button type="button" className="btn btn-brand-1 sitebtn hover-up formbtn w25" onClick={() => setSteps(9)}>Next</button>
                                    </div>            
                                </Form>                            
                            </div>

                        }
                        { steps == 9 &&
                            <div className="formbox">
                                <div className="backbtn">
                                    <button type="button" className="btn btn-brand-1 sitebtn hover-up formbtn" onClick={() => setSteps(8)}>Back</button>
                                </div>
                                <div className="text-center">
                                    <h6 className="formhead">Social Media Links</h6>
                                    <p className="content">
                                        Lorem ipsum dolor sit amet consectetur. Tortor id curabitur nisi a et dis a. Feugiat.
                                    </p>
                                </div>
                                <Form>
                                    <Row className="justify-content-between">
                                        <Col xs={12} md={12} lg={4} xl={4}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Bitcointalk</Form.Label>
                                                <Form.Control type="text" placeholder="Lorem ipsum"></Form.Control>
                                            </Form.Group>
                                            <Form.Group className="mb-3">
                                                <Form.Label>LinkedIn</Form.Label>
                                                <Form.Control type="text" placeholder="Lorem ipsum"></Form.Control>
                                            </Form.Group>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Twitter</Form.Label>
                                                <Form.Control type="text" placeholder="Lorem ipsum"></Form.Control>
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} md={12} lg={4} xl={4}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Facebook</Form.Label>
                                                <Form.Control type="text" placeholder="Lorem ipsum"></Form.Control>
                                            </Form.Group>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Medium</Form.Label>
                                                <Form.Control type="text" placeholder="Lorem ipsum"></Form.Control>
                                            </Form.Group>
                                            <Form.Group className="mb-3">
                                                <Form.Label>others</Form.Label>
                                                <Form.Control type="text" placeholder="Lorem ipsum"></Form.Control>
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} md={12} lg={4} xl={4}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Instagram</Form.Label>
                                                <Form.Control type="text" placeholder="lorem ipsum"></Form.Control>
                                            </Form.Group>
                                            <Form.Group className="mb-4 mb-lg-5">
                                                <Form.Label>Telegram</Form.Label>
                                                <Form.Control type="text" placeholder="lorem ipsum"></Form.Control>
                                            </Form.Group>
                                            <Form.Group className="mb-3">
                                                <div className="flexbox justify-content-between flex-row">
                                                    <div>
                                                        <button type="button" className="btn anchorlink text-decoration-underline">
                                                            <b>Click to add</b>
                                                        </button>
                                                    </div>
                                                    <div>
                                                        <span className="add mt-0"><GoPlus /></span>
                                                    </div>
                                                </div>
                                            </Form.Group>
                                        </Col>
                                    </Row>       
                                    <div className="mt-5 text-center">
                                        <button type="button" className="btn btn-brand-1 sitebtn hover-up formbtn w25" onClick={() => setSteps(10)}>Next</button>
                                    </div>            
                                </Form>                            
                            </div>

                        }
                        { steps == 10 && 
                            <div className="formbox companydet projectsubmitter">
                                <div className="backbtn">
                                    <button type="button" className="btn btn-brand-1 sitebtn hover-up formbtn" onClick={() => setSteps(9)}>Back</button>
                                </div>
                                <div className="text-center">
                                    <h6 className="formhead">Project Submitter</h6>
                                    <p className="content">
                                        The Project Submitter is Person legally authorised to submit the Project. The person submitting this Project application to EdaFace Listing Platforms for approval must be  either an Official Member of the Company or an Authorized Representative with permission to  represent the project and information given in this application. Proof of your involvement and  permission to represent the company and submit this application will be required.
                                    </p>
                                </div>
                                <Form>
                                    <Row className="justify-content-between">
                                        <Col xs={12} md={12} lg={8} xl={8}>
                                            <Row>
                                                <Col xs={12} sm={12} md={12} lg={6}>
                                                    <Form.Group className="mb-3 pe-sm-4">
                                                        <Form.Label>Name of Project Submitter<span className="text-danger">*</span></Form.Label>
                                                        <Form.Control type="text" placeholder="Lorem ipsum"></Form.Control>
                                                    </Form.Group>
                                                </Col>
                                                <Col xs={12} sm={12} md={12} lg={6}>
                                                    <Form.Group className="mb-3 pe-sm-4">
                                                        <Form.Label>Gender of Project Submitter<span className="text-danger">*</span></Form.Label>
                                                        <Form.Select className="form-control">
                                                            <option value="1">male</option>
                                                            <option value="2">female</option>
                                                        </Form.Select>
                                                    </Form.Group>
                                                </Col>
                                                <Col xs={12} sm={12} md={12} lg={6}>
                                                    <Form.Group className="mb-3 pe-sm-4">
                                                        <Form.Label>National Identification<span className="text-danger">*</span></Form.Label>
                                                        <div className="oropt">
                                                            <Form.Control type="text" placeholder="Lorem ipsum"></Form.Control>
                                                            <p className="content">(or)</p>
                                                        </div>  
                                                    </Form.Group>
                                                </Col>
                                                <Col xs={12} sm={12} md={12} lg={6}>
                                                    <Form.Group className="mb-3 pe-sm-4">
                                                        <Form.Label>Passport number of Project Submitter<span className="text-danger">*</span></Form.Label>
                                                        <Form.Control type="text" placeholder="Lorem ipsum"></Form.Control>
                                                    </Form.Group>
                                                </Col>
                                                <Col xs={12} sm={12} md={12} lg={6}>
                                                    <Form.Group className="mb-3 pe-sm-4">
                                                        <Form.Label>Upload a Proof of National Identification<span className="text-danger">*</span></Form.Label>
                                                        <div className="oropt">
                                                            <Form.Control type="text" placeholder="Lorem ipsum"></Form.Control>
                                                            <p className="content">(or)</p>
                                                        </div>  
                                                    </Form.Group>
                                                </Col>
                                                <Col xs={12} sm={12} md={12} lg={6}>
                                                    <Form.Group className="mb-3 pe-sm-4">
                                                        <Form.Label>Passport number<span className="text-danger">*</span></Form.Label>
                                                        <Form.Control type="text" placeholder="Lorem ipsum"></Form.Control>
                                                    </Form.Group>
                                                </Col>
                                                <Col xs={12} sm={12} md={12} lg={6}>
                                                    <Form.Group className="mb-3 pe-sm-4">
                                                        <Form.Label>Country where National Identification<span className="text-danger">*</span></Form.Label>
                                                        <div className="oropt">
                                                            <Form.Control type="text" placeholder="Lorem ipsum"></Form.Control>
                                                            <p className="content">(or)</p>
                                                        </div>                                                
                                                    </Form.Group>
                                                </Col>
                                                <Col xs={12} sm={12} md={12} lg={6}>
                                                    <Form.Group className="mb-3 pe-sm-4">
                                                        <Form.Label>Passport<span className="text-danger">*</span></Form.Label>
                                                        <Form.Control type="text" placeholder="Lorem ipsum"></Form.Control>
                                                    </Form.Group>
                                                </Col>
                                                <Col xs={12} sm={12} md={12} lg={6}>
                                                    <Form.Group className="mb-3 pe-sm-4">
                                                        <Form.Label>Telegram Link of Project Submitter<span className="text-danger">*</span></Form.Label>
                                                        <Form.Control type="text" placeholder="Lorem ipsum"></Form.Control>
                                                    </Form.Group>
                                                </Col>
                                                <Col xs={12} sm={12} md={12} lg={6}>
                                                    <Form.Group className="mb-3 pe-sm-4">
                                                        <Form.Label>Please describe your involvement with the Company<span className="text-danger">*</span></Form.Label>
                                                        <Form.Control type="text" placeholder="Founder/Team Member"></Form.Control>
                                                        <p className="content mt-2 text-black">
                                                            If your involvement is not listed please specify your role
                                                        </p>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col xs={12} md={12} lg={4} xl={4}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Current Physical address of Project Submitter<span className="text-danger">*</span></Form.Label>
                                                <Form.Control as="textarea" placeholder="lorem ipsum"></Form.Control>
                                            </Form.Group>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Upload a Proof of Current Physical Address<span className="text-danger">*</span></Form.Label>
                                                <Form.Control as="textarea" placeholder="(acceptable documents include: bank statement, utility bill, etc.)"></Form.Control>
                                            </Form.Group>
                                            <Form.Group className="mb-3">
                                                <Form.Label className="mb-3">
                                                    Upload a Proof of Authorisation Letter from the Company that shows you have the  authorization to submit this Project
                                                    <span className="text-danger">*</span>
                                                </Form.Label>
                                                <div className="flexbox up align-items-start">
                                                    <div className="addimg">
                                                        <input type="file" onChange={imgchangetwo} accept="image/*" className="form-control" />
                                                        <div className="addimgbox">
                                                            Upload
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <p className="content text-black">
                                                            Note your primary means of communication with EdaFace will be via the company’s email indicated
                                                        </p>
                                                    </div>                                                    
                                                </div>
                                                <img src={addimgtwo} alt="Member" className="img-fluid memberimg" />
                                            </Form.Group>
                                        </Col>
                                    </Row>       
                                    <div className="mt-5 text-center">
                                        <button type="button" className="btn btn-brand-1 sitebtn hover-up formbtn w25" onClick={() => setSteps(11)}>Next</button>
                                    </div>            
                                </Form>                            
                            </div>

                        }
                        { steps == 11 &&
                            <>
                                <h3 className="h3">Your Project scores a total of <span className="t-orange">28%</span>.</h3>
                                <div className="formbox projectscore mt-3 mt-sm-5">
                                    <div className="backbtn mb-5">
                                        <button type="button" className="btn btn-brand-1 sitebtn hover-up formbtn" onClick={() => setSteps(10)}>Back</button>
                                    </div>
                                    <h6 className="h6">EdaFace SOC Score Interpretation</h6>
                                    <div className="tablebox">
                                        <div className="table-responsive">
                                            <Table>
                                                <thead>
                                                    <tr>
                                                        { scoretablehead.map((th) => (
                                                            <th>{th}</th>
                                                        )) }
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    { scoretable.map((td) => (
                                                        <tr>
                                                            <td>{td.tscore}</td>
                                                            <td>{td.percent}</td>
                                                            <td>{td.invest}</td>
                                                        </tr>
                                                    )) }
                                                </tbody>
                                            </Table>
                                        </div>
                                        <div className="notebox">
                                            <b>Note:</b>
                                            <p className="content noteblue mt-1 mb-3">
                                                For Failed Project (SOC score less than 30%):
                                            </p>
                                            <p className="content t-orange mb-3">
                                                Sorry, you cannot list yet on EdaFace. You’ll need to improve on your score to be able to list on  EdaFace.
                                            </p>
                                            <p className="content t-orange mb-3">
                                                Your Project must score at least 30% in order to list on EdaFace.
                                            </p>

                                            <p className="content t-orange">
                                                If you want to see the full analysis of your score that will show area of improvement, <Link to="/">click  here….</Link>
                                            </p>
                                            <div className="mt-5 mb-3">
                                                <button type="button" className="btn txtbtn" onClick={() => setSteps(12)}>
                                                    (Please, Click here to improve your score and list on EdaFace)
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        }
                        { steps == 12 &&
                            <>
                                <h3 className="h3">Your Project scores a total of <span className="t-orange">40%</span>.</h3>
                                <div className="formbox congratstop mt-3 mt-sm-5">
                                    <div className="tablebox">
                                        <img src={Images.congratsimg} alt="" className="img-fluid congratsimg" />                                       
                                        <div className="notebox">
                                            <p className="content noteblue mt-1 mb-3">
                                                Your Project is a Cautious Investment 
                                            </p>
                                            <p className="content t-orange mb-3">
                                                However, there is room for improvement in order to become more attractive to users.
                                            </p>
                                            <p className="content text-black mb-3">
                                                If you want to see the full analysis of your score that will show area of improvement, click  here….(see attached score analysis)
                                            </p>
                                            <p className="content text-black">
                                                You can proceed to submit your Project for listing on EdaFace
                                            </p>
                                            <div className="mt-5 mb-3">
                                                <button type="button" className="btn btn-brand-1 sitebtn hover-up formbtn w25" onClick={() => setSteps(13)}>
                                                    Submit
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>

                        }
                        { steps == 13 &&
                            <>
                                <h3 className="h3">Project submission</h3>
                                <div className="formbox projectsubmit mt-3 mt-sm-5">
                                    <h3 className="h3">Please, tick:</h3>
                                    <Form className="mb-5 pb-5">
                                        <Form.Group className="mb-4">
                                            <Form.Check type="checkbox" label="All the information I have provided are accurate and true. Any misrepresentation may result in  delisting my Project and legal action."></Form.Check>
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Check type="checkbox" label="Since EdaFace Listing Platforms are decentralized and automated, once you successfully click  the Submit Button, your Project will automatically become live on EdaFace, and receive
                                            Prelim Verif status that will be confirmed later in accordance with EdaFace Compliance Grading."></Form.Check>
                                        </Form.Group>
                                    </Form>
                                </div>
                            </>

                        }
                    </div>
                </Container>
            </Container>
        <Footer />
    </>
  )
}

export default CoinTokenSupply;