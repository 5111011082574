import React, { useEffect, useState } from 'react';
import { Form, Table, InputGroup, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "react-bootstrap";
import "../Karthick.css";
import "../inner.css";
import Images from "../Components/Images";
import { FaSort } from "react-icons/fa";
import { HiOutlineDotsVertical } from "react-icons/hi";
import { IoArrowUp } from "react-icons/io5";


function Orderbooktable(props) {

    const [progresstabs, setProgresstabs] = useState("alltab");
    const [listnumber, setListnumber] = useState([
        { id: 1, width: "20%" }, { id: 2, width: "32%" }, { id: 3, width: "14%" }, { id: 4, width: "10%" },
        // { id: 5, width: "19%" }, { id: 6, width: "20%" }, { id: 7, width: "22%" }, { id: 8, width: "24%" },
        // { id: 9, width: "20%" }, { id: 10, width: "34%" }, { id: 11, width: "15%" }, { id: 12, width: "39%" },
    ])

    return (
        <div>

            <h6 className='mb-3 fw-600'>Order Book</h6>

            <div className="price_header mx-0">
                <div class="contents mb-3">
                    <p className='mb-0 themeclr fw-500'>Price(USDT)</p>
                    <p className='mb-0 themeclr fw-500'>Size(Cont)</p>
                    <p className='mb-0 themeclr fw-500'>Sum</p>
                </div>
            </div>

            {listnumber.map((e, i) =>
                <div className='price_change px-2'>
                    <span className='width_set' style={{ background: "#f6465d", width: e.width }}></span>
                    <p className='mb-0 redclr'>560.00</p>
                    <p className='mb-0'>2.50</p>
                    <p className='mb-0'>48.39</p>
                </div>)}

            <div className='mt-2'>
                <div className='d-flex gap-3 align-items-center px-2 mb-2'><h5 className='redclr mb-0'> 460.0 </h5> <p className='redclr fw-500 f-14 mb-0'> -5.25%</p> <p className='grayclr liness fw-500 f-14 mb-0'> 527.4</p></div>
                {listnumber.map((e, i) =>
                    <div className='price_change px-2'>
                        <span className='width_set' style={{ background: "green", width: e.width }}></span>
                        <p className='mb-0 grnclr'>505.00</p>
                        <p className='mb-0'>11.84</p>
                        <p className='mb-0'>9.60</p>
                    </div>)}
            </div>
    
        </div>
    )
}

export default Orderbooktable;