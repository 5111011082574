import React, { useState, useEffect } from "react";
import Innerheader from "../Layouts/innerheader";
import Footer from "../Layouts/footer";
import { Accordion, Col, Table, Container, Dropdown, DropdownMenu, DropdownToggle, Row, DropdownItem } from "react-bootstrap";
import "../Karthick.css";
import "../inner.css";
import { Link } from "react-router-dom";
import Images from "../Components/Images";
import { MdKeyboardArrowDown } from "react-icons/md";
import { PiWarningCircleBold } from "react-icons/pi";
import { BsFillInfoCircleFill } from "react-icons/bs";
import Supplytable from "../Components/supplytable";
import Borrowtable from "../Components/borrowtable";
import Supplylist from "../Components/supplylist";
import Borrowlist from "../Components/borrowlist";
import Announcement from "../Components/announcement";

function Assets(props) {
    const [selectnetwork, setSelectnetwork] = useState({ name: "Ethereum" });
    const [networklist, setNetworklist] = useState([
        { name: "Ethereum" }, { name: "Avalanche" }, { name: "Polygon" }
    ]);
    const[isapplied,setIsapplied] = useState(false);
    const[isborrowapplied,setIsborrowapplied] = useState(false);
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    }, []);
    return (

        <div>

            <Innerheader />

            <Container fluid className="innerpage pools_sec assetpage px-0 pos">
                {/* <Container>
                <Announcement/>
                </Container> */}
                
                <Container className="pt-5">
                <Announcement/>
                    <img src={Images.backlogo} className='backlogo farms__backlogo' />
                    {/* <img src={Images.elogo} className='elogo farms__elogo' /> */}
                    <Dropdown className="market">
                        <DropdownToggle className="ps-0"><h2 className="blkclr">{selectnetwork.name} Market <MdKeyboardArrowDown /></h2></DropdownToggle>
                        <DropdownMenu>
                            {networklist.map((e, i) =>
                                <DropdownItem onClick={() => setSelectnetwork(e)}>{e.name}</DropdownItem>
                            )}
                        </DropdownMenu>
                    </Dropdown>
                    <div className="d-block d-sm-flex">
                        <div className="marketbor py-3 px-3 me-3 mt-3">
                            <h6 className="themeclr">Net Worth</h6>
                            <h3 className="blkclr mb-0">$0</h3>
                        </div>
                        <div className="marketbor py-3 px-3 mt-3">
                            <h6 className="themeclr">Net APY <PiWarningCircleBold /></h6>
                            <h3 className="blkclr mb-0">_</h3>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-6">
                            <div className="pools_table py-4 px-4 mt-5">
                                <h4 className="blkclr fw-500">Your Supplies</h4>
                                {isapplied ?
                                    <div>
                                        <Supplylist />
                                    </div> :
                                    <p className="mt-4">Nothing Supplied yet</p>}
                            </div>
                            <div className="pools_table py-4 px-4 mt-5">
                                <div className="d-flex justify-content-between">
                                    <h5 className="blkclr fw-500">Assets to Supply</h5>
                                    <h5 className="blkclr fw-500">Hide -</h5>
                                </div>
                                <div className="marketbor w-100 py-3 px-3 mt-3">
                                    <p className="themeclr mb-0"><BsFillInfoCircleFill /> Your Ethereum Wallet is empty.Purchase or transfer assets.</p>
                                </div>
                                <Supplytable setIsapplied={(e)=>(setIsapplied(e))}/>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="pools_table py-4 px-4 mt-5">
                                <h4 className="blkclr fw-500">Your borrow</h4>
                                {isborrowapplied ?
                                <div>
                                    <Borrowlist />
                                </div> :
                                 <p className="mt-4">Nothing borrowed yet</p> }
                            </div>
                            <div className="pools_table py-4 px-4 mt-5">
                                <div className="d-flex justify-content-between">
                                    <h5 className="blkclr fw-500">Assets to borrow</h5>
                                    <h5 className="blkclr fw-500">Hide -</h5>
                                </div>
                                <div className="marketbor w-100 py-3 px-3 mt-3">
                                    <p className="themeclr mb-0"><BsFillInfoCircleFill /> To borrow you need to supply any asset to be used as collateral.</p>
                                </div>
                                <Borrowtable  setIsborrowapplied={(e)=>(setIsborrowapplied(e))}/>
                            </div>
                        </div>
                    </div>

                </Container>
            </Container>

            <Footer />
        </div>

    )
}

export default Assets