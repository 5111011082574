import React, { useState, useEffect } from "react";
import Innerheader from "../Layouts/innerheader";
import Footer from "../Layouts/footer";
import { Accordion, Col, Table, Container, Dropdown, DropdownMenu, DropdownToggle, Row } from "react-bootstrap";
import "../Karthick.css";
import "../inner.css";
import { Link } from "react-router-dom";
import Images from "../Components/Images";
import { FiFilter } from "react-icons/fi";
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import docss from "../Assets/navicon/docss.png";
import square from "../Assets/navicon/square.png";
import Announcement from "../Components/announcement";

function Pools() {

    const [poolslist, setPoollist] = useState([
        { id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }, { id: 5 }, { id: 6 },
    ]);
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    }, []);

    return (
        <div>

            <Innerheader />

            <Container fluid className="innerpage pb-0 px-0">
                <Container className="pt-3">
                    <Announcement />
                </Container>
                <div className="poolspage py-5 px-3">
                    <div className="row mx-1">
                        <div className="col-lg-6 mx-auto">
                            <div className="poolshead py-4 my-5">
                                <h1 className="text-center whtclr fw-600 mb-3">Defi Liquidity Pools</h1>
                                <h4 className="text-center whtclr">Built on Balancer protocol</h4>
                            </div>

                        </div>
                    </div>
                </div>


                <div className="pools_sec py-5">
                    <Container>
                        <h2 className="blkclr mb-3">All Pools</h2>
                        <div className="row justify-content-between">
                            <div className="col-md-8 col-lg-6 col-xl-6 col-xxl-5 d-block text-center d-sm-flex align-items-center mt-3">
                                <Link to="/poolsbalance"><img src={Images.liq} className="img-fluid me-0 me-sm-2 mt-3 mt-sm-0" /></Link>
                                <div className="pos wds  mt-3 mt-sm-0 mx-auto mx-sm-0">
                                    <input type="search" className="form-control token_input" placeholder="Filter by token" />
                                    <img src={Images.searchicon} className="img-fluid search" />
                                </div>
                                <Dropdown className="filterdrop mt-3 mt-sm-0 ms-0 ms-sm-2">
                                    <DropdownToggle><FiFilter /> More filters</DropdownToggle>
                                    <DropdownMenu className="px-3 py-3">
                                        <Link to="/" className="mb-2"><img src={docss} className="img-fluid navimg me-1" alt="images" /> Blockchains</Link>
                                        {/* <Link to="/"><img src={square} className="img-fluid navimg me-1" alt="images" /> MIM Pool Types</Link> */}
                                        <Accordion defaultActiveKey="0" className="mb-2">
                                            <Accordion.Item className="poolitem" eventKey="0">
                                                <Accordion.Header><img src={docss} className="img-fluid navimg me-1" alt="images" /> MIM Pool Type</Accordion.Header>
                                                <Accordion.Body>
                                                    <Link to="/pooltype">Heterogenized MIM Pool</Link>
                                                    <Link to="/pooltype" className="mb-0">Homogenized MIM Pool</Link>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                        <Link to="/"><img src={square} className="img-fluid navimg me-1" alt="images" /> Token </Link>
                                        <Link to="/"><img src={square} className="img-fluid navimg me-1" alt="images" /> Newly Created pools</Link>
                                    </DropdownMenu>
                                </Dropdown>
                            </div>
                            <div className="col-md-4 col-lg-5 col-xl-4 col-xxl-3 d-flex justify-content-center justify-sm-content-end mt-3">
                                <Link to="/pooltype"><button class="btn btn-brand-1 sitebtn hover-up">Create a pools</button></Link>
                                <img src={Images.leaderbrd} className="img-fluid ms-2" />
                            </div>
                        </div>


                        <div className="pools_table py-4 px-4 mt-4">
                            <Table responsive>
                                <thead>
                                    <tr>
                                        <th><img src={Images.doubcoin} className="img-fluid" /></th>
                                        <th>Composition</th>
                                        <th>Pool value</th>
                                        <th>Volume (24h)</th>
                                        <th>APY/APR</th>
                                    </tr>
                                </thead>
                                <tbody className="t_body">
                                    {poolslist.map((e, i) =>
                                        <tr>
                                            <td>   <AvatarGroup max={3}>
                                                <Avatar alt="Remy Sharp" src={Images.eth} />
                                                <Avatar alt="Travis Howard" src={Images.dai} />
                                                <Avatar alt="Cindy Baker" src={Images.usdc} />
                                                <Avatar alt="Agnes Walker" src={Images.bal} />
                                                <Avatar alt="Trevor Henderson" src={Images.snx} />
                                            </AvatarGroup></td>
                                            <td>
                                                <button className="btn per_btn ms-2 mt-3">BAL 80%</button>
                                                <button className="btn per_btn ms-2 mt-3">WETH 80%</button>
                                            </td>
                                            <td><p className="themeclr mb-0 fw-500">$127,167,635</p></td>
                                            <td><p className="themeclr mb-0 fw-500">$108,890</p></td>
                                            <td><p className="themeclr mb-0 fw-500">0.96% <img src={Images.apr} className="img-fluid" /></p></td>
                                        </tr>)}

                                </tbody>
                            </Table>

                            <div className="text-center mt-3 mb-3">
                                <button class="btn btn-brand-1 sitebtn hover-up">Load More</button>
                            </div>

                        </div>

                    </Container>

                </div>

            </Container>

            <Footer />
        </div>
    );
}

export default Pools;