import React,{useEffect,useState} from 'react';
import { Dropdown, DropdownMenu, DropdownToggle, Table, DropdownItem } from 'react-bootstrap';
import { FaSort } from "react-icons/fa";
import starimg from "../Assets/star.png"


function Symbolmodal() {

 const[datelist,setdatelist] = useState([
    {id:1},{id:2},{id:3},{id:4},{id:5},{id:6}
 ]);

  return (
   
    <div className='option_border px-2 py-3'>
    <div className='coinpairprice'>
    <div className='supplytable'>
    <div className='d-flex justify-content-between mb-3'>
     <ul className='listtab d-flex align-items-center gap-3 mb-0'>
        <li className='active fw-600'>ETH</li>
        <li>USDC</li>
        <li>BNB</li>
     </ul>
     <Dropdown>
        <DropdownToggle>All</DropdownToggle>
         <DropdownMenu>
            <DropdownItem>All</DropdownItem>
            <DropdownItem>Call</DropdownItem>
            <DropdownItem>Put</DropdownItem>
         </DropdownMenu>
     </Dropdown>
     </div>
     <div className='d-flex gap-4 mb-3'>
        <p className='orgclr mb-0 fw-600'>All</p>
        <div className='d-flex gap-3 wauto overauto'>
         {datelist.map((e,i)=> <p className='mb-0'>2024-05-24</p>)}
        </div>
     </div>

     <Table  hover className='pairpricetable'>
        <thead>
            <tr>
                <th>Symbol <FaSort/></th>
                <th>Last Price <FaSort/></th>

                <th>24h% <FaSort/></th>
                <th>Vol(USDT) <FaSort/></th>
            </tr>
        </thead>
        <tbody>
            {datelist.map((e,i)=>
            <tr>
                <td>
                    <div>
                        <p className='mb-1 blkclr fw-600'><img src={starimg} className='img-fluid'/> USDC-2456-4600-C</p>
                        <p className='mb-0 orgclr fw-600 f-13'>2.96x</p>
                    </div>
                </td>
                <td><p className='themeclr mb-0'>5.0</p></td>
                <td><p className='themeclr mb-0'>0.00%</p></td>
                <td><p className='themeclr mb-0'>128.40</p></td>

            </tr>)}
        </tbody>

     </Table>
     </div>

    </div></div>

  )
}

export default Symbolmodal