import React, { useState, useRef } from "react";
import Innerheader from "../Layouts/innerheader";
import Footer from "../Layouts/footer";
import { Container, Form, InputGroup } from "react-bootstrap";
import "../Karthick.css";
import "../inner.css";
import Images from "../Components/Images";
import { GoMail } from "react-icons/go";
import { MdOutlineLock } from "react-icons/md";
import { Link } from "react-router-dom";
import { GoPlus } from "react-icons/go";

const SubmissionForm = () => {

    const [submissionstep, setSubmissionstep] = useState(1);
    const [submissionform, setSubmissionform] = useState(false);


  return (
    <>
        <Innerheader />
            <Container fluid className="innerpage pools_sec assetpage px-0 pos">
                <Container className="pt-5">
                    <img src={Images.backlogo} className='backlogo farms__backlogo' />
                    <div className="cointoken submissionform">
                        { submissionstep == 1 && 
                            <div className="formbox formboxcenter">
                                <div className="text-center">
                                    <h6 class="formhead">Submission Form <span className="d-block mt-1">(Coin or Token)</span></h6>
                                    <p className="content">
                                        Please, fill in the details. Note that EdaFace automated system will verify and grade your Project before submission.
                                    </p>
                                </div>
                                <Form>
                                    {   submissionform == false ? 
                                        <>
                                            <Form.Label>Coin/Token Name<span className="text-danger">*</span></Form.Label>
                                            <InputGroup className="mb-4">
                                                <InputGroup.Text><GoMail /></InputGroup.Text>
                                                <Form.Control type="text" placeholder="Eda Token"></Form.Control>
                                            </InputGroup>
                                            <Form.Label>Coin/Token Symbol<span className="text-danger">*</span></Form.Label>
                                            <InputGroup className="mb-4">
                                                <InputGroup.Text><GoMail /></InputGroup.Text>
                                                <Form.Control type="text" placeholder="$EDA"></Form.Control>
                                            </InputGroup>
                                        </>
                                        :
                                        <>
                                            <Form.Label>Blockchain Deployment date<span className="text-danger">*</span></Form.Label>
                                            <InputGroup className="mb-4 align-items-start topinputgroup">
                                                <InputGroup.Text><GoMail /></InputGroup.Text>
                                                <Form.Control as="textarea" className="form-control" placeholder="Eda Token"></Form.Control>
                                            </InputGroup>
                                            <Form.Label>Purpose<span className="text-danger">*</span></Form.Label>
                                            <InputGroup className="mb-4 align-items-start topinputgroup">
                                                <InputGroup.Text><GoMail /></InputGroup.Text>
                                                <Form.Control as="textarea" placeholder="$EDA"></Form.Control>
                                            </InputGroup>
                                            <Form.Group>
                                                <Form.Label>Unique Features</Form.Label>
                                                <Form.Control as="textarea" className="borderin" placeholder="First Unique features....."></Form.Control>
                                                <span className="add"><GoPlus /></span>
                                            </Form.Group>
                                        </>
                                    }                                    
                                    <div className="mt-5 text-center">
                                        <button type="button" className="btn btn-brand-1 sitebtn hover-up formbtn w-100" onClick={() => setSubmissionform(true)} >Next</button>
                                    </div> 
                                </Form>
                            </div>
                        }
                    </div>
                </Container>
            </Container>
        <Footer />
    </>
  )
}

export default SubmissionForm;